import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Pengiriman {
  id: number;
  status: null | string;
  queue_number: string;
  document_id: null | number;
  driver_id: null | number;
  vehicle_id: null | number;
  shipment_date: string;
  destination: string;
  destination_latitude: null | number;
  destination_longitude: null | number;
  verif_documents: null | string;
  created_by: string;
  updated_by: null | string;
  upload_doc_by: null | string;
  photo_pickup: null | string;
  finish_by: null | string;
  created_at: string;
  updated_at: null | string;
  loading_at: null | string;
  loading_by: null | string;
  package_qty: null | number;
  notes: string;
  bast_code: string;
  ttb_code: string;
  nama_pejabat: string;
  nama_pendamping: string;
  bast_code_qrcode: string;
  province_id: number;
  city_id: number;
  district_id: number;
  subdistrict_id: number;
  no_so: null | string;
  no_doc_out: null | string;
  sj: any[]; // You can specify a more specific type if needed
  provinces: {
    province_id: number;
    province_name: string;
  };
  cities: {
    city_id: number;
    city_name: string;
  };
  districts: {
    district_id: number;
    district_name: string;
  };
  subdistricts: {
    subdistrict_id: number;
    subdistrict_name: string;
  };
}

interface IPengirimanData {
  list?: any;
  pageNo: number;
  pageSize: number;
  searchKey?: string;
  totalCount?: number;
  message?: string;
  loading?: boolean;
  lastPage?: number
}

const initialState: IPengirimanData = {
  pageNo: 1,
  pageSize: 20,
  searchKey: '',
  list: [],
  totalCount: 0,
  message: '',
  loading: false,
  lastPage: 0
};

const pengirimanSlice = createSlice({
  name: "pengiriman",
  initialState,
  reducers: {
    setPengirimanList: (state, _action: PayloadAction<IPengirimanData>) => {
      state.list = _action.payload.list;
      state.pageNo = _action.payload.pageNo;
      state.pageSize = _action.payload.pageSize;
      state.totalCount = _action.payload.totalCount;
      state.lastPage = _action.payload.lastPage;
    },
    resetPengirimanToInit: (state) => {
      state = initialState;
    },
    setPengirimanMessage: (state, _action: PayloadAction<string>) => {
      state.message = _action.payload;
    },
    startLoadingPengiriman: (state) => {
      state.loading = true
    },
    stopLoadingPengiriman: (state) => {
      state.loading = false
    }
  },
});

export const { setPengirimanList, resetPengirimanToInit, setPengirimanMessage, startLoadingPengiriman, stopLoadingPengiriman } = pengirimanSlice.actions;

export default pengirimanSlice.reducer;

