import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { setDistrictsMessage } from "redux/actions";
import { resetCitiesToInit, setCitiesList } from "redux/actions";

import { getCities } from "services/citiesService";

import { useAppDispatch } from "redux/store";
import { addDistricts, updateDistricts } from "services/districtsService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const DistrictsForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue={district_id:0,city_id:0,district_name:''};
    const initialValue = action === 'edit' ? row : iValue;
    const citiesData = useSelector((state: RootState) => state.cities);

    useEffect(() => {
if (citiesData && citiesData.list && citiesData.list.length === 0) {
            dispatch(resetCitiesToInit());
            getCities(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '','').then((response) => {
                if (response && response.records) {
                  dispatch(setCitiesList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
                } else {
                  dispatch(setDistrictsMessage("No Record Found For Cities"));
                }
              })
        }
})
    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            if (action === 'edit') {
                const response = await updateDistricts(values);
                if (response && response.data && response.data.code===1) {
                    dispatch(setDistrictsMessage("Updated Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setDistrictsMessage("Some error occured!"));
                }
            } else if (action === 'add') {
                const response = await addDistricts(values);
                if (response && response.data && response.data.code===1) {
                    dispatch(setDistrictsMessage("Added Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setDistrictsMessage("Some error occured!"));
                }
            }
        },
        validationSchema: yup.object({
           district_id: yup.number().required('district_id is required'),
district_name: yup.string().nullable(),
city_id: yup.string().required('city_id is required'),

        }),
    });
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-primary text-capitalize">{action} Districts
                    <Button className="btn-icon-split float-right" onClick={() => hideShowForm(false)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-list"></i>
                        </span>
                        <span className="text">View Districts</span>
                    </Button>
                </h6>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Group>
<label className="form -control-label">district_id</label>
<Form.Control type="text" name="district_id" className="form-control" value={formik.values.district_id}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.district_id && !!formik.errors.district_id}
isValid ={!!formik.touched.district_id && !formik.errors.district_id}
></Form.Control>
{
    formik.errors.district_id && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.district_id}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">district_name</label>
<Form.Control type="text" name="district_name" className="form-control" value={formik.values.district_name}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.district_name && !!formik.errors.district_name}
isValid ={!!formik.touched.district_name && !formik.errors.district_name}
></Form.Control>
{
    formik.errors.district_name && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.district_name}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">city_id</label>
<Form.Control as="select"  name="city_id" className="form-control" value={formik.values.city_id}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.city_id && !!formik.errors.city_id}
isValid ={!!formik.touched.city_id && !formik.errors.city_id}
>
<option value={0}>Select Cities </option> 
{
citiesData.list.map((item, i) => {
return <option value={item.city_id} key={`cities-${i}`}>{item.city_name}</option>
})}
</Form.Control>
{
    formik.errors.city_id && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.city_id}
    </Form.Control.Feedback>
)}
</Form.Group>

                    <Form.Group>
                        <Button type="submit" className="float-right" variant="primary">Save</Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

