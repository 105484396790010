import React from "react";
interface Props {
  rowData: any;
  bastData: any;
}
export const ComponentPengiriman = React.forwardRef<HTMLInputElement, Props>(
  (props, ref) => {
    const { bastData } = props;
    return (
      <>
        {/* {bastData && ( */}
        <div ref={ref} style={{ padding: 50 }}>
          <div
            className=""
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <img
              src="../logos/logo-bulog.jpeg"
              alt=""
              width={350}
              height={110}
            />
            <img src="../logos/logo-sbl-logistic.png" alt="" width={250} />
          </div>
          <div
            className=""
            style={{
              border: "1px solid black",
              marginBottom: 50,
              marginTop: 10,
            }}
          ></div>
          <div className="" style={{ textAlign: "center", marginBottom: 30 }}>
            <h2 style={{ fontWeight: 700, lineHeight: 1 }}>
              BERITA ACARA SERAH TERIMA
            </h2>
            <h2 style={{ fontWeight: 700, lineHeight: 1 }}>
              BANTUAN PANGAN CADANGAN BERAS PEMERINTAH
            </h2>
            <h2 style={{ fontWeight: 700, lineHeight: 1 }}>
              (BANTUAN PANGAN CBP) TAHAP {bastData?.periods?.id}{" "}
            </h2>

            <h2 style={{ fontWeight: 700, lineHeight: 1 }}>
              <span style={{ backgroundColor: "#F2BE22" }}>
                ALOKASI : {bastData?.periods?.month?.toUpperCase()}{" "}
                {bastData?.periods?.year}
              </span>
            </h2>
          </div>
          <div className="" style={{ display: "flex" }}>
            <table className="table table-bordered" style={{ width: "50%" }}>
              <tbody>
                <tr style={{ backgroundColor: "#F2BE22" }}>
                  <td>
                    <h4 style={{ fontWeight: 700 }}>BAST GUDANG</h4>
                  </td>
                </tr>
                <tr>
                  <td style={{ fontWeight: 700 }}>
                    PROVINSI :{" "}
                    {bastData?.provinces?.province_name?.toUpperCase()}
                  </td>
                </tr>
                <tr>
                  <td style={{ fontWeight: 700 }}>
                    KEC: {bastData?.districts?.district_name?.toUpperCase()}
                  </td>
                </tr>
                <tr>
                  <td style={{ fontWeight: 700 }}>
                    NO. SO : {bastData?.no_so}
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="table table-bordered" style={{ width: "50%" }}>
              <tbody>
                <tr style={{ backgroundColor: "#F2BE22" }}>
                  <td style={{ fontWeight: 700, textTransform: "uppercase" }}>
                    <h4 style={{ fontWeight: 700, textTransform: "uppercase" }}>
                      NO. DOKUMEN
                    </h4>
                  </td>
                </tr>
                <tr>
                  <td style={{ fontWeight: 700, textTransform: "uppercase" }}>
                    KOTA/KAB : {bastData?.cities?.city_name?.toUpperCase()}
                  </td>
                </tr>
                <tr>
                  <td style={{ fontWeight: 700, textTransform: "uppercase" }}>
                    KEL :{" "}
                    {bastData?.subdistricts?.subdistrict_name?.toUpperCase()}
                  </td>
                </tr>
                <tr>
                  <td style={{ fontWeight: 700, textTransform: "uppercase" }}>
                    NO. DOK OUT : {bastData?.no_doc_out}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="" style={{ display: "flex", marginTop: 40 }}>
            <img
              src={bastData?.bast_code_qrcode}
              alt=""
              style={{ objectFit: "contain", width: "250px" }}
            />
            <table className="table table-bordered" style={{ height: 80 }}>
              <tbody>
                {/* <tr>
                                        <td>No. Antrian</td>
                                        <td>{bastData?.queue_number}</td>
                                    </tr> */}
                {/* <tr>
                  <td>Periode</td>
                  <td>{bastData?.kpms[0]?.periods?.tahap_name}</td>
                </tr> */}
                <tr>
                  <td>Dibuat Oleh</td>
                  <td>{bastData?.created_by}</td>
                </tr>
                <tr>
                  <td>Tanggal Loading</td>
                  <td>{bastData?.shipment_date}</td>
                </tr>
                <tr>
                  <td>No. Pol. Kendaraan</td>
                  <td>{bastData?.plate_number}</td>
                </tr>
                <tr>
                  <td>Nama Supir</td>
                  <td>{bastData?.driver_name}</td>
                </tr>
                <tr>
                  <td>No. HP Supir</td>
                  <td>{bastData?.phone_number}</td>
                </tr>
                <tr>
                  <td>Jumlah Paket</td>
                  <td>{bastData?.package_qty}</td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* <div className="">
            <p>Catatan:</p>
            <div className="" style={{ border: "1px solid black" }}>
              <p style={{ margin: "0px 10px 0px 10px" }}>{bastData?.notes}</p>
            </div>
          </div> */}
          <div className="" style={{ marginTop: 50 }}>
            <table
              className="table table-bordered"
              style={{ height: 200, marginBottom: 0 }}
            >
              <tr style={{ textAlign: "center" }}>
                <td style={{ width: 300 }}>PIC Gudang Bulog</td>
                <td style={{ width: 300 }}>Admin Gudang</td>
                <td style={{ width: 300 }}>Supir</td>
              </tr>
              <tr style={{ textAlign: "center", borderTopStyle: "hidden" }}>
                <td style={{ height: 10 }}>
                  <br />
                  <p>(...........................)</p>
                </td>
                <td style={{ height: 10 }}>
                  <br />
                  <p>(...........................)</p>
                </td>
                <td style={{ height: 10 }}>
                  <br />
                  <p>(...........................)</p>
                </td>
              </tr>
            </table>
            {/* <p style={{ marginLeft: 20, marginBottom: 50 }}>
              Keterangan:*) atau pengurus yang mewakili akan ditunjuk
            </p>
            <p>
              Note:{" "}
              <span style={{ marginLeft: 70 }}>
                {bastData?.notes}
              </span>
            </p> */}
          </div>
          <div className="pagebreak"></div>
          <div
            className=""
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <img
              src="../logos/logo-bulog.jpeg"
              alt=""
              width={350}
              height={110}
            />
            <img src="../logos/logo-sbl-logistic.png" alt="" width={250} />
          </div>
          <div
            className=""
            style={{
              border: "1px solid black",
              marginBottom: 50,
              marginTop: 10,
            }}
          ></div>
          <div className="" style={{ textAlign: "center", marginBottom: 30 }}>
            <h2 style={{ fontWeight: 700, lineHeight: 1 }}>
              BERITA ACARA SERAH TERIMA (BAST)
            </h2>
            <h2 style={{ fontWeight: 700, lineHeight: 1 }}>
              PENERIMA BANTUAN PANGAN (PBP) PENGGANTI
            </h2>
            {/* <h2>
                ........................................................................
              </h2> */}
          </div>
          <div className="">
            <div className="" style={{ display: "flex" }}>
              <div className="">
                <h5>Provinsi</h5>
                <h5>Kabupaten/Kota</h5>
                <h5>Kecamatan</h5>
                <h5>Desa/Kelurahan</h5>
              </div>
              <div className="" style={{ marginLeft: 50 }}>
                <h5>: ...............................</h5>
                <h5>: ...............................</h5>
                <h5>: ...............................</h5>
                <h5>: ...............................</h5>
              </div>
            </div>
            <h5 style={{ marginTop: 40 }}>
              Kami yg bertanda tangan di bawah ini adalah sebagai PBP Pengganti
              yang ditunjuk oleh aparat setempat menyatakan dengan
              sebenar-benarnya nya bahwa kami telah menerima beras sejumlah 10
              kg dengan kondisi kualitas baik sebagai berikut:
            </h5>
            <table
              className="table table-bordered"
              style={{ textAlign: "center" }}
            >
              <tr>
                <th style={{ width: 10 }} rowSpan={2}>
                  No
                </th>
                <th style={{ width: 150 }} rowSpan={2}>
                  Nama PBP Yang Tidak Ditemukan / Tidak Memenuhi syarat
                </th>
                <th colSpan={2}>PBP Pengganti</th>
                <th style={{ width: 200 }} rowSpan={2}>
                  Sebab Penggantian*
                </th>
                <th style={{ width: 150 }} rowSpan={2}>
                  Tanda Tangan PBP Pengganti
                </th>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}>Nama</td>
                <td style={{ fontWeight: "bold" }}>Alamat</td>
              </tr>
              <tbody>
                <tr>
                  <td style={{ padding: 5 }}>1</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td style={{ padding: 5 }}>2</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td style={{ padding: 5 }}>3</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td style={{ padding: 5 }}>4</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td style={{ padding: 5 }}>5</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td style={{ padding: 5 }}>dst.</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td style={{ padding: 5, fontWeight: "bold" }}>Jumlah</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
              {/* <tr>
                <td style={{ padding: 5 }}></td>
                <td></td>
                <td>Nama</td>
                <td>Alamat</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td style={{ padding: 5 }}>2</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td style={{ padding: 5 }}>3</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td style={{ padding: 5 }}>4</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td style={{ padding: 5 }}>5</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td style={{ padding: 5 }}>dst.</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td style={{ padding: 5, fontWeight: 'bold' }}>Jumlah</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr> */}
            </table>
            <h5>
              Demikian Berita Acara ini dibuat dengan sebenarnya untuk dapat
              dipergunakan sebagaimana mestinya.
            </h5>
            <div
              className=""
              style={{
                display: "flex",
                justifyContent: "space-around",
                textAlign: "left",
                // marginBottom: 60,
                // marginTop: 40,
              }}
            >
              <div className="">
                <br />
                <h5>Mengetahui</h5>
                <h5>Aparat Setempat **</h5>
                <br />
                <br />
                <h5>.......................................</h5>
                <h5>(Tanda Tangan dan Stempel)***</h5>{" "}
              </div>
              <div className="">
                <h5>...................., ................... 2023</h5>
                <h5 style={{ textAlign: "right" }}>Transporter</h5>
                {/* <h5 style={{ marginTop: 50, marginBottom: 65 }}>
                    (Tanda Tangan)
                  </h5> */}
                <br />
                <br />
                <br />
                <h5 style={{ textAlign: "right" }}>
                  .......................................
                </h5>
              </div>
            </div>
            <h5>Keterangan:</h5>
            <h5>
              * diisi meninggal dunia,pindah domisili,dicatat lebih dari 1(satu)
              kali,tidak ditemukan alamatnya, tidak ditemukan pada alamat
              terdata, dan/atau telah mampu (tidak memenuhi syarat sebagai PBP).
            </h5>
            <h5>
              ** Aparat Setempat adalah Pengurus RT/RW atau sebutan nama lainnya
              atau aparat Kelurahan/Desa atau perwakilan penerima bantuan pangan
              sasaran.
            </h5>
            <h5>*** Stempel bagi yang memiliki.</h5>
          </div>
        </div>

        {/* )} */}
      </>
    );
  }
);
