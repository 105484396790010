import { APIService } from "services";

export const getSptjm = async (pageNo, pageSize, search, periode_id, date, province_id, city_id, district_id, subdistrict_id) => {
    let res;
    if (search.length === 0) {
        res = await getAllSptjm(pageNo, pageSize, search, periode_id, date, province_id, city_id, district_id, subdistrict_id);
    }
    else {
        try {
            res = await searchSptjm(pageNo, pageSize, search, periode_id, date, province_id, city_id, district_id, subdistrict_id);
        } catch (err) {
            return { records: [], total_count: 0 }
        }
    }
    if (res && res.data && res.data.data) {
        return { records: res.data.data.data, total_count: res.data.data.total }
    } else {
        return { records: [], totalCount: 0 }
    }

}


export const addSptjm = (data) => {
    return APIService.api().post(`/replace-pbps`, data)
}
export const addReplacePbps = (data,id) => {
    return APIService.api().post(`/replace-pbps/${id}`, data)
}
export const updateSptjm = (data) => {
    return APIService.api().put(`/replace-pbps/${data.id}`, data)
}
export const getAllSptjm = (page, paginator, search, periode_id, date, province_id, city_id, district_id, subdistrict_id) => {
    return APIService.api().get(`/replace-pbps?page=${page}&paginator=${paginator}&periode_id=${periode_id}&date=${date}&province_id=${province_id}&city_id=${city_id}&district_id=${district_id}&subdistrict_id=${subdistrict_id}`)
}
export const getOneSptjm = (id) => {
    return APIService.api().get(`/replace-pbps/${id}`)
}
export const searchSptjm = (page, paginator, search, periode_id, date, province_id, city_id, district_id, subdistrict_id) => {
    return APIService.api().get(`/replace-pbps/search/${search}/?page=${page}&paginator=${paginator}&periode_id=${periode_id}&date=${date}&province_id=${province_id}&city_id=${city_id}&district_id=${district_id}&subdistrict_id=${subdistrict_id}`)
}
export const deleteSptjm = (id) => {
    return APIService.api().delete(`/replace-pbps/${id}`)
}