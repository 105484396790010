import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IReports {
    total?: number,
    open?: string,
    completed?: string,
    reversal?: number,
    remaining?: string,
    percentage_total?: string,
    document_id?: number,
    province_name?: string
}

interface IReportsData {
    list?: Array<IReports>;
    date?: string
    // pageNo: number;
    // pageSize: number;
    // searchKey?: string;
    // totalCount?: number;
    message?: string;
    loading?:boolean;
}

const initialState: IReportsData = {
    // pageNo: 1,
    // pageSize: 20,
    // searchKey: '',
    list: [],
    date: '',
    // totalCount: 0,
    message: '',
    loading: false
};

const reportsSlice = createSlice({
    name: "reports",
    initialState,
    reducers: {
        setReportsList: (state, _action: PayloadAction<IReportsData>) => {
            state.list = _action.payload.list;
            state.date = _action.payload.date;
            // state.pageNo = _action.payload.pageNo;
            // state.pageSize = _action.payload.pageSize;
            // state.totalCount = _action.payload.totalCount;
        },
        resetReportsToInit: (state) => {
            state = initialState;
        },
        setReportsMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
        startLoadingReports: (state) => {
            state.loading = true
        },
        stopLoadingReports: (state) => {
            state.loading = false
        }
    },
});

export const { setReportsList, resetReportsToInit, setReportsMessage, startLoadingReports, stopLoadingReports } = reportsSlice.actions

export default reportsSlice.reducer;

