import React from "react";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";

import { useAppDispatch } from "redux/store";
import {
  resetShipmentsToInit,
  setPengirimanList,
  setPengirimanMessage,
  startLoadingPengiriman,
  stopLoadingPengiriman,
} from "redux/actions";
import { Button, Card, Spinner } from "react-bootstrap";
import { getAllReports } from "services/reportsService";
import { getPengiriman } from "services/pengirimanService";
import { Constant } from "template/Constant";

function TableBNBA() {
  const dispatch = useAppDispatch();
  
  const getData = (
    page,
    pageSize,
    searchKey,
    periode_id,
    date,
    province_id,
    city_id,
    district_id,
    subdistrict_id
  ) => {
    dispatch(startLoadingPengiriman());
    getPengiriman(
      page,
      pageSize,
      searchKey,
      periode_id,
      date,
      province_id,
      city_id,
      district_id,
      subdistrict_id
    ).then((response) => {
      console.log('response', response)
      if (response && response.records) {
        dispatch(
          setPengirimanList({
            pageNo: page,
            pageSize: pageSize,
            list: response.records,
            totalCount: response.total_count,
            searchKey: searchKey,
            lastPage: response.lastPage,
          })
        );
        dispatch(stopLoadingPengiriman());
      } else {
        dispatch(setPengirimanMessage("Data kosong"));
        dispatch(stopLoadingPengiriman());
      }
    });
  };

  React.useEffect(() => {
    getData();
  }, []);

  const [search, setSearch] = React.useState("");
  const rData = useSelector((state) => state.pengiriman);
  const periodsId = localStorage.getItem("periode_id");
  const provinceId = localStorage.getItem("province_id");
  const cityId = localStorage.getItem("city_id");
  const districtId = localStorage.getItem("district_id");
  const subdistrictId = localStorage.getItem("subdistrict_id");
  const isLoading = useSelector((state) => state.pengiriman.loading);

  const handlePerRowsChange = async (newPerPage, page) => {
    await getData(
      page,
      newPerPage,
      search ?? "",
      periodsId ?? "",
      "",
      provinceId,
      cityId,
      districtId,
      subdistrictId
    );
  };

  const handlePageChange = (page) => {
    getData(
      page,
      rData.pageSize,
      search ?? "",
      periodsId ?? "",
      "",
      provinceId,
      cityId,
      districtId,
      subdistrictId
    );
  };

  React.useEffect(() => {
    if (rData && rData.list && rData.list?.length === 0) {
      getData(
        Constant.defaultPageNumber,
        Constant.defaultPageSize,
        "",
        periodsId,
        "",
        provinceId,
        cityId,
        districtId,
        subdistrictId
      );
    }
  }, [rData.list?.length]);

  const handleReset = () => {
    setSearch("");
    dispatch(resetShipmentsToInit());
    getData(
      Constant.defaultPageNumber,
      rData.pageSize,
      "",
      periodsId,
      "",
      provinceId,
      cityId,
      districtId,
      subdistrictId
    );
  };

  const handleRowSelection = (row) => {
    console.log(row); // Row Selection Functionality can be written here
  };

  const columns = [
    { name: "Periode", selector: (row) => row.periode_id, sortable: true },
    { name: "Kode BAST", selector: (row) => row.bast_code, sortable: true },
    { name: "Status", selector: (row) => row.status, sortable: true },
    {
      name: "Tanggal Pengiriman",
      selector: (row) => row.shipment_date,
      sortable: true,
    },
    { name: "Tujuan", selector: (row) => row.destination, sortable: true },

    {
      name: "Tanggal Diperbarui",
      selector: (row) => row.updated_at,
      sortable: true,
    },

    {
      name: "Kuantitas Paket",
      selector: (row) => row.package_qty,
      sortable: true,
    },
    { name: "Catatan", selector: (row) => row.notes, sortable: true },
  ];

  return (
    <Card className="shadow mb-4">
      <Card.Header className="py-3">
        <h6 className="m-0 font-weight-bold text-primary">
          Daftar BNBA
          <Button
            variant="light"
            className="btn-circle btn-sm ml-2"
            onClick={handleReset}
          >
            <i className="fa fa-refresh"></i>
          </Button>
        </h6>
      </Card.Header>
      <Card.Body>
        <div className="table-responsive">
          {isLoading && rData.list?.length === 0 ? (
            <Spinner
              animation="border"
              variant="danger"
              className="d-flex mx-auto"
            ></Spinner>
          ) : (
            <DataTable
              // selectableRows={true}
              onSelectedRowsChange={handleRowSelection}
              paginationPerPage={Constant.defaultPageNumber}
              paginationRowsPerPageOptions={
                Constant.paginationRowsPerPageOptions
              }
              columns={columns}
              data={rData.list}
              onChangeRowsPerPage={handlePerRowsChange}
              paginationTotalRows={rData.totalCount}
              className="table table-bordered"
              pagination
              paginationServer
              progressPending={isLoading}
              onChangePage={handlePageChange}
            ></DataTable>
          )}
        </div>
      </Card.Body>
    </Card>
  );
}

export default TableBNBA;
