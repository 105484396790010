import React, { useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  setPengirimanList,
  setPengirimanMessage,
  startLoadingPengiriman,
  stopLoadingPengiriman,
} from "redux/actions";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import Layout from "template";
import { Constant } from "template/Constant";
import { ShipmentsForm } from "./form";
import { ShipmentsTable } from "./table";
import { getPengiriman } from "services/pengirimanService";
import { ShipmentsPrint } from "./print";

type Props = {
  section?: any;
};

export const Shipments: React.FC<Props> = ({ section }) => {
  const dispatch = useAppDispatch();
  const rData = useSelector((state: RootState) => state.pengiriman);
  const [row, setRow] = useState(undefined);
  const [action, setAction] = useState("");
  const getData = (
    page,
    pageSize,
    searchKey,
    periode_id,
    date,
    province_id,
    city_id,
    district_id,
    subdistrict_id
  ) => {
    dispatch(startLoadingPengiriman());
    getPengiriman(
      page,
      pageSize,
      searchKey,
      periode_id,
      date,
      province_id,
      city_id,
      district_id,
      subdistrict_id
    ).then((response) => {
      if (response && response.records) {
        dispatch(
          setPengirimanList({
            pageNo: page,
            pageSize: pageSize,
            list: response.records,
            totalCount: response.total_count,
            searchKey: searchKey,
            lastPage: response.lastPage,
          })
        );
        dispatch(stopLoadingPengiriman());
      } else {
        dispatch(setPengirimanMessage("Data kosong"));
        dispatch(stopLoadingPengiriman());
      }
    });
  };
  const handleRowEdit = (rowData) => {
    setRow(rowData);
    dispatch(setPengirimanMessage(""));
    setAction("edit");
  };
  const handleRowView = (rowData) => {
    setRow(rowData);
    dispatch(setPengirimanMessage(""));
    setAction("view");
  };
  return (
    <Layout>
      <div className="container-fluid">
        <div className="d-flex flex-column min-vh-100">
          {rData.message ? (
            <Alert
              variant={Constant.defaultAlertVarient}
              className="alert-dismissible fade">
              {rData.message}
              <Button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-label="Close"
                onClick={() => dispatch(setPengirimanMessage(""))}>
                <span aria-hidden="true">&times;</span>
              </Button>
            </Alert>
          ) : null}
          {section === "add" || action ? (
            <ShipmentsForm
              hideShowForm={setAction}
              action={action || section}
              row={row}
              getData={getData}
            />
          ) : section === "print" ? (
            <ShipmentsPrint getData={getData} />
          ) : (
            <ShipmentsTable
              handleRowView={handleRowView}
              handleRowEdit={handleRowEdit}
              hideShowForm={setAction}
              getData={getData}
            />
          )}
        </div>
      </div>
    </Layout>
  );
};
