import axios from "axios"
export class APIService {

    static apiCustomHeader(headers: any) {
        return axios.create({
            baseURL: process.env.REACT_APP_API_BASE_URL,
            headers: headers,
        })
    }
    static api() {
        const jwtToken = localStorage.getItem("token");
        const instance = axios.create({
            baseURL: process.env.REACT_APP_API_BASE_URL,
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                // 'Authorization': 'Bearer ' + jwtToken,
                // 'ngrok-skip-browser-warning': 'true'

            }
        })
        instance.interceptors.request.use(
            (config) => {
                // You can further modify the request config here if needed
                if (jwtToken) {
                    config.headers['Authorization'] = 'Bearer ' + jwtToken;
                }
                return config;
            },
            (error) => {
                // Handle request error
                return Promise.reject(error);
            }
        );
        instance.interceptors.response.use(
            (response) => {
                // You can further modify the response data here if needed
                return response;
            },
            (error) => {
                if (error.response.status === 401 || error.response.statusText === 'Unauthorized') {
                    localStorage.clear();
                }
                return Promise.reject(error.response)
            }
        );

        return instance;
    }
    static apiNoAuth() {
        return axios.create({
            baseURL: process.env.REACT_APP_API_BASE_URL,
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                // 'ngrok-skip-browser-warning': 'true'
            }
        })
    }
    static apiNoAuthDownload() {
        return axios.create({
            baseURL: process.env.REACT_APP_API_BASE_URL_NOAUTH,
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                // 'ngrok-skip-browser-warning': 'true',
            },
        })
    }
}
