import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IPeriods {
    id: number;
    month: string;
    year: string;
    status: null | string;
    created_by: null | string;
    updated_by: null | string;
    created_at: string;
    updated_at: string;
}

interface IPeriodsData {
    list?: Array<IPeriods>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
    loading?: boolean;
    lastPage?: number;
}

const initialState: IPeriodsData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
    loading: false,
    lastPage: 0
};

const periodsSlice = createSlice({
    name: "periods",
    initialState,
    reducers: {
        setPeriodsList: (state, _action: PayloadAction<IPeriodsData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
            state.lastPage = _action.payload.lastPage;
        },
        resetPeriodsToInit: (state) => {
            state = initialState;
        },
        setPeriodsMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
        startLoadingPeriods: (state) => {
            state.loading = true
        },
        stopLoadingPeriods: (state) => {
            state.loading = false
        }
    },
});

export const { setPeriodsList, resetPeriodsToInit, setPeriodsMessage, startLoadingPeriods, stopLoadingPeriods } = periodsSlice.actions;

export default periodsSlice.reducer;

