import React from "react";
interface Props {
    bastData: any;
}
export const ComponentLampiran = React.forwardRef<HTMLInputElement, Props>(
    (props, ref) => {
        const { bastData } = props;
        return (
            <>
                {bastData && bastData?.pbps && (
                    <div className="" ref={ref} style={{ padding: 50 }}>
                        <div
                            className=""
                            style={{ display: "flex", justifyContent: "space-between" }}
                        >
                            <img src="../logos/bulog-logo.png" alt="" width={350} />
                            <img src="../logos/logo-sbl-logistic.png" alt="" width={250} />
                        </div>
                        <hr />
                        <div className="mt-5" style={{ textAlign: "center" }}>
                            <div className="">
                                <h3><strong>LAMPIRAN</strong></h3>
                            </div>
                            <div className="">
                                <h3>BAST PENGIRIMAN</h3>
                            </div>
                            <div className="">
                                <h4>
                                    <strong>{bastData.bast_code}</strong>
                                </h4>
                            </div>
                        </div>
                        <table className="table table-bordered mt-5">
                            <thead>
                                <tr>
                                    <th scope="col">NAMA KPM KPH</th>
                                    <th scope="col">DIWAKILKAN</th>
                                    <th scope="col">NAMA PENERIMA</th>
                                    <th scope="col">FOTO</th>
                                    <th scope="col">FOTO PERWAKILAN</th>
                                    <th scope="col">FOTO DROP POINT</th>
                                </tr>
                            </thead>
                            <tbody>
                                {bastData?.pbps.map((value, index) => (
                                    <tr>
                                        <th scope="row" style={{ textTransform: "uppercase" }}>
                                            000{index + 1}.
                                            {value?.kpm_name}
                                            <div
                                                style={{
                                                    fontWeight: "normal",
                                                    textTransform: "uppercase",
                                                }}
                                            >
                                                {value?.kpm_name}
                                            </div>
                                        </th>
                                        <td>
                                            {value?.attachments != null
                                                ? value?.attachments.is_represented === true
                                                    ? "YA"
                                                    : value?.attachments.is_represented === false
                                                        ? "TIDAK"
                                                        : "-"
                                                : "-"}
                                        </td>
                                        <td> {value?.attachments != null
                                            ? value?.attachments.receiver_name
                                            : "-"}</td>
                                        <td>
                                            <img
                                                src={
                                                    value?.attachments != null
                                                        ? value?.attachments.attachment_received
                                                        : "-"
                                                }
                                                alt=""
                                                width={120}
                                                height={120}
                                            />
                                        </td>
                                        <td><img
                                            src={
                                                value?.attachments != null
                                                    ? value?.attachments.attachment_represent
                                                    : "-"
                                            }
                                            alt=""
                                            width={120}
                                            height={120}
                                        /></td>
                                        <td><img
                                            src={
                                                value?.attachments != null
                                                    ? value?.attachments.attachment_arrived
                                                    : "-"
                                            }
                                            alt=""
                                            width={120}
                                            height={120}
                                        /></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </>
        );
    }
);
