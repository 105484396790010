import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { resetQueuingsToInit, setQueuingsMessage } from "redux/actions";
import { Button, Card, Col, Form, InputGroup, Spinner } from "react-bootstrap";
import { Constant } from "template/Constant";
import ConfirmationModal from "template/ConfirmationModal";
import { deleteQueuings } from "services/queuingsService";
type Props = {
    hideShowForm: (action) => void;
    handleRowEdit: (row) => void;
    getData: (page, pageSize, searchKey) => void;
};
export const QueuingsTable: React.FC<Props> = ({ hideShowForm, handleRowEdit, getData }) => {
    const dispatch = useAppDispatch();
    const [search, setSearch] = useState('');
    const [showDelete, setShowDelete] = useState(false);
    const [rowData, setRowData] = useState(undefined);
    const rData = useSelector((state: RootState) => state.queuings);
    const isLoading = useSelector((state: RootState) => state.queuings.loading);
    const handleSearch = () => {
        if (search.length > 0) {
            getData(Constant.defaultPageNumber, Constant.defaultPageSize, search);
        }
    }
    const handlePerRowsChange = async (newPerPage, page) => {
        await getData(page, newPerPage, search ?? '');
    }
    const handlePageChange = (page) => {
        getData(page, rData.pageSize, search ?? '');
    };
    const handleRowDeleteClick = (row) => {
        setRowData(row);
        setShowDelete(true);
    }
    useEffect(() => {
        if (rData && rData.list && rData.list.length === 0) {
            if (!search) {
                dispatch(resetQueuingsToInit());
                getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
            }else{
                getData(Constant.defaultPageNumber, Constant.defaultPageSize, search);
            }
        }
    }, [rData.list.length])
    const handleReset = () => {
        setSearch('');
        dispatch(resetQueuingsToInit());
        getData(Constant.defaultPageNumber, rData.pageSize, '');
    }
    const handleServerDelete = async () => {
        if (rowData) {
            const response = await deleteQueuings(rowData.id);
            if (response) {
                dispatch(resetQueuingsToInit());
                dispatch(setQueuingsMessage("Berhasil menghapus data"));
                getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                setShowDelete(false);
            } else {
                dispatch(setQueuingsMessage("Terjadi kesalahan"));
            }
        }
    }

    const handleRowSelection = (row) => {
        console.log(row); // Row Selection Functionality can be written here
    }
    const handleAddButtonClick = () => {
        dispatch(setQueuingsMessage(''));
        hideShowForm('add');
    }

    const columns = [
        // { name: 'id', selector: row => row.id, sortable: true },
        { name: 'Kendaraan', selector: row => row.vehicle_id, sortable: true },
        { name: 'Merk', selector: row => row.vehicles.make, sortable: true },
        { name: 'Posisi Antrian', selector: row => row.queue_position, sortable: true },
        { name: 'Status', selector: row => row.status, sortable: true },
        // { name: 'created_at', selector: row => row.created_at, sortable: true },
        // { name: 'updated_at', selector: row => row.updated_at, sortable: true },
        { name: 'ID Pengiriman', selector: row => row.shipment_id, sortable: true },
        { name: 'Status Pengiriman', selector: row => row.shipments.status, sortable: true },

        {
            name: '',
            button: true,
            cell: (row) => <Button variant="link" className="btn-sm" onClick={() => handleRowEdit(row)}>Ubah</Button>,
        },
        {
            name: '',
            button: true,
            cell: (row) => <Button variant="link" className="btn-sm" onClick={() => handleRowDeleteClick(row)}>Hapus</Button>,
        },
    ];
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-primary">Daftar Antrian ({rData.totalCount})
                    <Button variant="light" className="btn-circle btn-sm ml-2" onClick={handleReset}><i className="fa fa-refresh"></i></Button>
                    {/* <Button className="btn-icon-split float-right" onClick={handleAddButtonClick}>
                        <span className="icon text-white-50">
                            <i className="fas fa-add"></i>
                        </span>
                        <span className="text">Add New</span>
                    </Button> */}
                    </h6>

            </Card.Header>
            <Card.Body>
                <Col className="ml-auto" md={3} xs={12} xl={3}>
                    <InputGroup>
                        <Form.Control
                            placeholder="Cari"
                            aria-label="Search"
                            aria-describedby="basic-search"
                            onChange={(e) => setSearch(e.target.value)}
                        />
                        <Button variant="dark" className="rounded-0 rounded-right" id="button-search" onClick={handleSearch}>
                            Cari
                        </Button>
                    </InputGroup>
                </Col>
                <div className="table-responsive">
                {isLoading && rData.list.length === 0 ? <Spinner animation="border" variant="danger" className="d-flex mx-auto"></Spinner> : <DataTable
                        // selectableRows={true}
                        onSelectedRowsChange={handleRowSelection}
                        paginationPerPage={Constant.defaultPageNumber}
                        paginationRowsPerPageOptions={Constant.paginationRowsPerPageOptions}
                        columns={columns} data={rData.list}
                        onChangeRowsPerPage={handlePerRowsChange}
                        paginationTotalRows={rData.totalCount}
                        className="table table-bordered"
                        pagination
                        paginationServer
                        progressPending={isLoading}
                        onChangePage={handlePageChange}></DataTable>}
                </div>
            </Card.Body>
            <ConfirmationModal buttonNegative="Batal" buttonPositive="Hapus" title="Konfirmasi Hapus" show={showDelete} body={"Apakah anda yakin"} onNegative={() => setShowDelete(false)} onPositive={handleServerDelete} />
        </Card>
    );
}

