import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Periods {
    id: number;
    month: string;
    year: string;
}

interface Provinces {
    province_id: number;
    province_name: string;
}

interface Cities {
    city_id: number;
    city_name: string;
}

interface Districts {
    district_id: number;
    district_name: string;
}

interface Subdistricts {
    subdistrict_id: number;
    subdistrict_name: string;
}

interface IPBP {
    id: number;
    periode_id: number;
    province_id: number;
    city_id: number;
    district_id: number;
    subdistrict_id: number;
    status: string;
    reg_number: string;
    kpm_name: string;
    kpm_age: string;
    address: string;
    created_at: string;
    batch_number: string;
    periods: Periods;
    provinces: Provinces;
    cities: Cities;
    districts: Districts;
    subdistricts: Subdistricts;
    attachments: any[]; // You might want to replace 'any' with a more specific type if you have information about the attachment structure
    pbps_old: any[]; // Similar to attachments, replace 'any' with the specific type if available
}


interface IPBPData {
    list?: Array<IPBP>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
    loading?: boolean;
}

const initialState: IPBPData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
    loading: false
};

const pbpSlice = createSlice({
    name: "pbp",
    initialState,
    reducers: {
        setPBPList: (state, _action: PayloadAction<IPBPData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetPBPToInit: (state) => {
            state = initialState;
        },
        setPBPMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
        startLoadingPBP: (state) => {
            state.loading = true
        },
        stopLoadingPBP: (state) => {
            state.loading = false
        }
    },
});

export const { setPBPList, resetPBPToInit, setPBPMessage, startLoadingPBP, stopLoadingPBP } = pbpSlice.actions;

export default pbpSlice.reducer;

