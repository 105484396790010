import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IRoles {
    id?: number,
    role_name?: string
}

interface IRolesData {
    list?: Array<IRoles>;
    message?: string;
    loading?:boolean;
}

const initialState: IRolesData = {
    list: [],
    message: '',
    loading:false
};

const rolesSlice = createSlice({
    name: "roles",
    initialState,
    reducers: {
        setRolesList: (state, _action: PayloadAction<IRolesData>) => {
            state.list = _action.payload.list;
        },
        resetRolesToInit: (state) => {
            state = initialState;
        },
        setRolesMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
        startLoadingRoles: (state) => {
            state.loading = true
        },
        stopLoadingRoles: (state) => {
            state.loading = false
        }
    },
});

export const { setRolesList, resetRolesToInit, setRolesMessage, startLoadingRoles, stopLoadingRoles } = rolesSlice.actions

export default rolesSlice.reducer;

