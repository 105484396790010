import axios from "axios";
import { APIService } from "services";

export const getKpms = async (pageNo, pageSize, search, periode_id, date, province_id, city_id, district_id, subdistrict_id) => {
    let res;
    if (search.length === 0) {
        res = await getAllKpms(pageNo, pageSize, search, periode_id, date, province_id, city_id, district_id, subdistrict_id);
    }
    else {
        try {
            res = await searchKpms(pageNo, pageSize, search, periode_id, date, province_id, city_id, district_id, subdistrict_id);
        } catch (err) {
            return { records: [], total_count: 0 }
        }
    }
    if (res && res.data && res.data.data) {
        return { records: res.data.data.data, total_count: res.data.data.total, lastPage: res.data.data.last_page }
    } else {
        return { records: [], totalCount: 0 }
    }

}


export const addKpms = (data) => {
    return APIService.api().post(`/kpms`, data)
}
export const updateKpms = (data) => {
    return APIService.api().put(`/kpms/${data.id}`, data)
}
export const getAllKpms = (page, paginator, search, periode_id, date, province_id, city_id, district_id, subdistrict_id) => {
    return APIService.api().get(`/pbps?page=${page}&paginator=${paginator}&periode_id=${periode_id}&date=${date}&province_id=${province_id}&city_id=${city_id}&district_id=${district_id}&subdistrict_id=${subdistrict_id}`)
}
export const getOneKpms = (id) => {
    return APIService.api().get(`/pbps/${id}`)
}
export const searchKpms = (page, paginator, search, periode_id, date, province_id, city_id, district_id, subdistrict_id) => {
    return APIService.api().get(`/pbps/search/${search}/?page=${page}&paginator=${paginator}&periode_id=${periode_id}&date=${date}&province_id=${province_id}&city_id=${city_id}&district_id=${district_id}&subdistrict_id=${subdistrict_id}`)
}
export const deleteKpms = (id) => {
    return APIService.api().delete(`/kpms/${id}`)
}
export const downloadTemplateKpms = () => {
    return APIService.api().post(`/kpms/download-url`)
}

export const getPBP = (page, limit) => {
    return axios.get(`https://yaons.bkb.digital/public/get-all-pbps.php?page=${page}&limit=${limit}`)

}

export const replaceKpms = (data) => {
    return APIService.api().post(`/replace-kpms`, data)
}

export const getAllInvitation = (paginator, periode_id, province_id, city_id, district_id, subdistrict_id) => {
    return APIService.api().get(`/invitations?paginator=${paginator}&periode_id=${periode_id}&province_id=${province_id}&city_id=${city_id}&district_id=${district_id}&subdistrict_id=${subdistrict_id}`)
}