import React from "react";
import { QRCodeCanvas } from "qrcode.react";
interface Props {
  data: any;
}

export const ListBNBA = React.forwardRef<HTMLInputElement, Props>(
  (props, ref) => {
    const { data } = props;
    return (
      <>
        {data.length > 0 && (
          <table id="my-table" style={{ display: "none" }}>
            <thead>
              <tr>
                <th>NO</th>
                <th>NAMA</th>
                <th>ALAMAT</th>
                <th>NOMOR BARCODE</th>
                <th>TANDA TANGAN PBP</th>
              </tr>
            </thead>
            <tbody>
              {data[0]?.pbps?.map((item, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{item.kpm_name}</td>
                  <td>
                    {item.address}, RT {item.rt}, RW {item.rw}
                  </td>
                  <td>
                    <div className="d-flex flex-column">
                      <QRCodeCanvas id={`qr_${i}`} value={item.reg_number} />
                      <span id={item.reg_number}></span>
                    </div>
                  </td>
                  <td></td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </>
    );
  }
);
