import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface City {
    city_id: number;
    city_name: string;
}

export interface IDistricts {
    district_id: number;
    province_id: number;
    city_id: number;
    district_name: string;
    district_lat: null | number; // Replace 'null' with an appropriate type if you have more specific information
    district_lng: null | number; // Replace 'null' with an appropriate type if you have more specific information
    created_at: string;
    updated_at: string;
    cities: City;
}

interface IDistrictsData {
    list?: Array<IDistricts>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
    loading?:boolean
}

const initialState: IDistrictsData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
    loading:false
};

const districtsSlice = createSlice({
    name: "districts",
    initialState,
    reducers: {
        setDistrictsList: (state, _action: PayloadAction<IDistrictsData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetDistrictsToInit: (state) => {
            state = initialState;
        },
        setDistrictsMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
        startLoadingDistricts: (state) => {
            state.loading = true
        },
        stopLoadingDistricts: (state) => {
            state.loading = false
        }
    },
});

export const { setDistrictsList, resetDistrictsToInit, setDistrictsMessage, startLoadingDistricts, stopLoadingDistricts } = districtsSlice.actions;

export default districtsSlice.reducer;

