import React, { useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import Layout from "template";
import { Constant } from "template/Constant";
import { SptjmForm } from "./form";
import { SptjmTable } from "./table";
import { startLoadingKpms, setKpmsList, stopLoadingKpms, setKpmsMessage } from "redux/actions";
import { getKpms } from "services/kpmsService";

type Props = {
  section?: any
}

export const Sptjm: React.FC<Props> = ({ section }) => {
  const dispatch = useAppDispatch();
  const rData = useSelector((state: RootState) => state.kpms);
  const [row, setRow] = useState(undefined);
  const [action, setAction] = useState('');
  const getData = (page, pageSize, searchKey, periode_id, date, province_id, city_id, district_id, subdistrict_id) => {
    dispatch(startLoadingKpms())
    getKpms(page, pageSize, searchKey, periode_id, date, province_id, city_id, district_id, subdistrict_id).then((response) => {
      if (response && response.records) {
        dispatch(setKpmsList({ pageNo: page, pageSize: pageSize, list: response.records, totalCount: response.total_count, searchKey: searchKey, lastPage: response.lastPage }));
        dispatch(stopLoadingKpms())
      } else {
        dispatch(setKpmsMessage("Data kosong"));
        dispatch(stopLoadingKpms())
      }
    })
  }

  const handleRowEdit = (rowData) => {
    setRow(rowData);
    dispatch(setKpmsMessage(''));
    setAction('edit');
  }
  return (
    <Layout>
      <div className="container-fluid">
        {/* <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">SPTJM</h1>
        </div> */}
        {/* {section === '' && <div className="d-card d-flex justify-content-between mb-4 flex-column flex-md-row flex-wrap" style={{ gap: '12px' }}>
          <Card style={{ borderRadius: 20, border: 'none', padding: 10 }} className="card-counter">
            <Card.Body>
              <Card.Title>Total</Card.Title>
              <Card.Text style={{ fontSize: 32, color: '#040D12', fontWeight: "bold" }}>
                3,999,999
              </Card.Text>
            </Card.Body>
          </Card>
          <Card style={{ borderRadius: 20, border: 'none', padding: 10 }} className="card-counter">
            <Card.Body>
              <Card.Title style={{ color: 'blue' }}>Dikirim</Card.Title>
              <Card.Text style={{ fontSize: 32, color: '#040D12', fontWeight: "bold" }}>
                23.543
              </Card.Text>
            </Card.Body>
          </Card>
          <Card style={{ borderRadius: 20, border: 'none', padding: 10 }} className="card-counter">
            <Card.Body>
              <Card.Title className="text-primary">Dikirim</Card.Title>
              <Card.Text style={{ fontSize: 32, color: '#040D12', fontWeight: "bold" }}>
                23.543
              </Card.Text>
            </Card.Body>
          </Card>
          <Card style={{ borderRadius: 20, border: 'none', padding: 10 }} className="card-counter">
            <Card.Body>
              <Card.Title className="text-success">Diterima</Card.Title>
              <Card.Text style={{ fontSize: 32, color: '#040D12', fontWeight: "bold" }}>
                23.543
              </Card.Text>
            </Card.Body>
          </Card>
        </div>} */}

        <div className="d-flex flex-column min-vh-100">
          {rData.message ?
            <Alert variant={Constant.defaultAlertVarient} className="alert-dismissible fade">{rData.message}
              <Button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => dispatch(setKpmsMessage(''))}>
                <span aria-hidden="true">&times;</span>
              </Button>
            </Alert> : null}
          {section === 'add' || action ? <SptjmForm hideShowForm={setAction} action={action || section} row={row} getData={getData} /> :
            <SptjmTable handleRowEdit={handleRowEdit} hideShowForm={setAction} getData={getData} />}
        </div>

      </div>
    </Layout >
  );
};

