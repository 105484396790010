import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IQueuings {
id:number,
vehicle_id?:number,
queue_position?:number,
status?:string,
created_at?:Date,
updated_at?:Date,
shipment_id?:number
}

interface IQueuingsData {
    list?: Array<IQueuings>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
    loading?:boolean;
}

const initialState: IQueuingsData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
    loading:false
};

const queuingsSlice = createSlice({
    name: "queuings",
    initialState,
    reducers: {
        setQueuingsList: (state, _action: PayloadAction<IQueuingsData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetQueuingsToInit: (state) => {
            state = initialState;
        },
        setQueuingsMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
        startLoadingQueuings: (state) => {
            state.loading = true
        },
        stopLoadingQueuings: (state) => {
            state.loading = false
        }
    },
});

export const { setQueuingsList, resetQueuingsToInit, setQueuingsMessage , startLoadingQueuings, stopLoadingQueuings} = queuingsSlice.actions;

export default queuingsSlice.reducer;

