import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IRecap {
    id?: string,
    province_id?: string,
    city_id?: string,
    district_id?: string,
    subdistrict_id?: string,
    subdistrict?: string,
    total_alocation?: string
}

interface IRecapData {
    list?: Array<IRecap>;
    loading?: boolean;
}

const initialState: IRecapData = {
    list: [],
    loading: false,
};

const recapSlice = createSlice({
    name: "recap",
    initialState,
    reducers: {
        setRecapList: (state, _action: PayloadAction<IRecapData>) => {
            state.list = _action.payload.list;
        },
        resetRecapToInit: (state) => {
            state = initialState;
        },
        startLoadingRecap: (state) => {
            state.loading = true
        },
        stopLoadingRecap: (state) => {
            state.loading = false
        }
    },
});

export const { setRecapList, resetRecapToInit, startLoadingRecap, stopLoadingRecap } = recapSlice.actions

export default recapSlice.reducer;

