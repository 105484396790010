import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { Button, Nav } from "react-bootstrap";
import { useSelector } from "react-redux";
import { activateTopMenu, sidebarToggle } from "redux/actions";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
const Topbar: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleActiveDropdown = (menuName: string) => {
    dispatch(activateTopMenu(menuName));
  };
  const isTopActiveMenu = useSelector(
    (state: RootState) => state.template.topActiveMenu
  );
  const userProfile = useSelector(
    (state: RootState) => state.template.userProfile
  );
  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
    // navigate('/dashboard', { replace: true });
  };
  const email = localStorage.getItem("userEmail");
  const role = localStorage.getItem("user");
  return (
    <Nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow-sm">
      <button
        id="sidebarToggleTop"
        className="btn btn-link text-dark d-md-none rounded-circle mr-3"
        onClick={() => dispatch(sidebarToggle())}>
        <i className="fa fa-bars"></i>
      </button>
      <ul className="navbar-nav ml-auto">
        <li
          className={
            isTopActiveMenu === "Profile"
              ? "nav-item dropdown no-arrow show"
              : "nav-item dropdown no-arrow"
          }
          onClick={() => handleActiveDropdown("Profile")}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <div
            
            className="nav-link dropdown-toggle"
            id="userDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false">
            <i className="fas fa-user-circle fa-sm fa-fw mr-2 text-gray-400 h3"></i>
            <div className="">
              <span className="d-none d-lg-inline text-gray-600 font-weight-bold">
                Hi, {email}
              </span>
              <span className="d-none d-lg-block text-gray-600 small">
                {role}
              </span>
            </div>
          </div>
          <div
            className={
              isTopActiveMenu === "Profile"
                ? "dropdown-menu dropdown-menu-right shadow animated--grow-in show"
                : "dropdown-menu dropdown-menu-right shadow animated--grow-in"
            }
            aria-labelledby="userDropdown">
            {/* <Button variant="link" className="dropdown-item">
                            <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                            {userProfile?.username ?? userProfile.email}
                        </Button>
                        <Button variant="link" className="dropdown-item">
                            <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                            Pengaturan
                        </Button>
                        <Button variant="link" className="dropdown-item">
                            <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                            
                        </Button>
                        <div className="dropdown-divider"></div> */}
            <Button
              variant="link"
              className="dropdown-item"
              onClick={handleLogout}>
              <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
              Keluar
            </Button>
          </div>
        </li>
      </ul>
    </Nav>
  );
};

export default Topbar;
