import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Periods {
    id: number;
    month: string;
    year: string;
}

interface Provinces {
    province_id: number;
    province_name: string;
}

interface Cities {
    city_id: number;
    city_name: string;
}

interface Districts {
    district_id: number;
    district_name: string;
}

interface Subdistricts {
    subdistrict_id: number;
    subdistrict_name: string;
}

interface ISptjm {
    id: number;
    periode_id: number;
    province_id: number;
    city_id: number;
    district_id: number;
    subdistrict_id: number;
    reg_number: string;
    kpm_name: string;
    kpm_age: string;
    address: string;
    created_at: string;
    batch_number: string;
    pbps_id: number;
    periods: Periods;
    provinces: Provinces;
    cities: Cities;
    districts: Districts;
    subdistricts: Subdistricts;
}


interface ISptjmData {
    list?: Array<ISptjm>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
    loading?: boolean;
}

const initialState: ISptjmData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
    loading: false
};

const sptjmSlice = createSlice({
    name: "sptjm",
    initialState,
    reducers: {
        setSPTJMList: (state, _action: PayloadAction<ISptjmData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetSPTJMToInit: (state) => {
            state = initialState;
        },
        setSPTJMMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
        startLoadingSPTJM: (state) => {
            state.loading = true
        },
        stopLoadingSPTJM: (state) => {
            state.loading = false
        }
    },
});

export const { setSPTJMList, resetSPTJMToInit, setSPTJMMessage, startLoadingSPTJM, stopLoadingSPTJM } = sptjmSlice.actions;

export default sptjmSlice.reducer;

