import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import {
  resetProvincesToInit,
  startLoadingProvinces,
  setProvincesList,
  stopLoadingProvinces,
  setProvincesMessage,
  resetCitiesToInit,
  startLoadingCities,
  setCitiesList,
  stopLoadingCities,
  setCitiesMessage,
  resetDistrictsToInit,
  startLoadingDistricts,
  setDistrictsList,
  stopLoadingDistricts,
  setDistrictsMessage,
  resetSubdistrictsToInit,
  startLoadingSubDistricts,
  setSubdistrictsList,
  stopLoadingSubDistricts,
  setSubdistrictsMessage,
} from "redux/actions";
import { RootState } from "redux/reducers";
import { getCities } from "services/citiesService";
import { getDistricts } from "services/districtsService";
import { getProvinces } from "services/provincesService";
import { getSubdistricts } from "services/subdistrictsService";
import ConfirmationModal from "template/ConfirmationModal";
import { Constant } from "template/Constant";
import { ComponentInvitationPrint } from "./print/printInvitation";
import { getAllInvitation } from "services/kpmsService";
import { useAppDispatch } from "redux/store";
type Props = {
  setMessage: (message) => void;
};
export const InvitationsPrint: React.FC<Props> = ({ setMessage }) => {
  const provincesData = useSelector((state: RootState) => state.provinces);
  const citiesData = useSelector((state: RootState) => state.cities);
  const districtsData = useSelector((state: RootState) => state.districts);
  const subdistrictsData = useSelector(
    (state: RootState) => state.subdistricts
  );
  const provincesLoading = useSelector(
    (state: RootState) => state.provinces.loading
  );
  const citiesLoading = useSelector((state: RootState) => state.cities.loading);
  const districtsLoading = useSelector(
    (state: RootState) => state.districts.loading
  );
  const subdistrictsLoading = useSelector(
    (state: RootState) => state.subdistricts.loading
  );
  const [selectedProvince, setSelectedProvince] = useState(0);
  const [selectedCity, setSelectedCity] = useState(0);
  const [selectedDistrict, setSelectedDistrict] = useState(0);
  const [selectedSubdistrict, setSelectedSubdistrict] = useState(0);
  const periodeId = localStorage.getItem("periode_id");
  const provinceId = localStorage.getItem("province_id");
  const cityId = localStorage.getItem("city_id");
  const roles = localStorage.getItem("user");
  const provinceName = localStorage.getItem("province_name");
  const cityName = localStorage.getItem("city_name");
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const handleProvinceChange = (event) => {
    const newValue = event.target.value;
    setSelectedProvince(newValue);
  };

  const handleCityChange = (event) => {
    const newValue = event.target.value;
    setSelectedCity(newValue);
  };

  const handleDistrictChange = (event) => {
    const newValue = event.target.value;
    setSelectedDistrict(newValue);
  };

  const handleSubdistrictChange = (event) => {
    const newValue = event.target.value;
    setSelectedSubdistrict(newValue);
  };

  const [showInvitation, setShowInvitation] = useState(null);
  const [data, setData] = useState([]);
  const componentInvitationRef = useRef();
  const handleShowPrintInvitation = () => {
    if (!selectedDistrict && !selectedSubdistrict) {
      setMessage("Lengkapi data");
    } else {
      setShowInvitation(true);
      setMessage("");
    }
  };

  const handlePrintInvitation = useReactToPrint({
    content: () => componentInvitationRef.current,
    onBeforeGetContent() {
      setLoading(true);
    },
    onAfterPrint() {
      setLoading(false);
    },
    pageStyle: `
    @page {
      margin: 6mm 0mm 17mm 0mm;
    }`,
    documentTitle: `${data[0]?.subdistricts?.subdistrict_name} - ${data[0]?.districts?.district_name} - ${data[0]?.cities?.city_name}`,
  });
  useEffect(() => {
    if (
      provincesData &&
      provincesData.list &&
      provincesData.list.length === 0 &&
      roles === "Super Admin"
    ) {
      dispatch(resetProvincesToInit());
      dispatch(startLoadingProvinces());
      getProvinces(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        ""
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setProvincesList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
          dispatch(stopLoadingProvinces());
        } else {
          dispatch(setProvincesMessage("Data kosong"));
          dispatch(stopLoadingProvinces());
        }
      });
    }
  }, []);
  useEffect(() => {
    if (selectedProvince) {
      dispatch(resetCitiesToInit());
      dispatch(startLoadingCities());
      getCities(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        "",
        selectedProvince
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setCitiesList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
          dispatch(stopLoadingCities());
        } else {
          dispatch(setCitiesMessage("Data kosong"));
          dispatch(stopLoadingCities());
        }
      });
    }
  }, [selectedProvince]);

  useEffect(() => {
    if (roles === "Super Admin") {
      if (selectedCity) {
        dispatch(resetDistrictsToInit());
        dispatch(startLoadingDistricts());
        getDistricts(
          Constant.defaultPageNumber,
          Constant.defaultDropdownPageSize,
          "",
          selectedCity
        ).then((response) => {
          if (response && response.records) {
            dispatch(
              setDistrictsList({
                pageNo: Constant.defaultPageNumber,
                pageSize: Constant.defaultDropdownPageSize,
                list: response.records,
                totalCount: response.total_count,
                searchKey: "",
              })
            );
            dispatch(stopLoadingDistricts());
          } else {
            dispatch(setDistrictsMessage("Data kosong"));
            dispatch(stopLoadingDistricts());
          }
        });
      }
    } else {
      dispatch(resetDistrictsToInit());
      dispatch(startLoadingDistricts());
      getDistricts(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        "",
        cityId
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setDistrictsList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
          dispatch(stopLoadingDistricts());
        } else {
          dispatch(setDistrictsMessage("Data kosong"));
          dispatch(stopLoadingDistricts());
        }
      });
    }
  }, [selectedCity]);

  useEffect(() => {
    if (selectedDistrict) {
      dispatch(resetSubdistrictsToInit());
      dispatch(startLoadingSubDistricts());
      getSubdistricts(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        "",
        selectedDistrict
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setSubdistrictsList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
          dispatch(stopLoadingSubDistricts());
        } else {
          dispatch(setSubdistrictsMessage("Data kosong"));
          dispatch(stopLoadingSubDistricts());
        }
      });
    }
  }, [selectedDistrict]);

  useEffect(() => {
    if (roles === "Super Admin") {
      if (
        selectedProvince &&
        selectedCity &&
        selectedDistrict &&
        selectedSubdistrict
      ) {
        getAllInvitation(
          10000,
          periodeId,
          selectedProvince,
          selectedCity,
          selectedDistrict,
          selectedSubdistrict
        ).then((response) => {
          if (response) {
            setData(response.data.data.data);
          }
        });
      }
    } else {
      if (selectedDistrict && selectedSubdistrict)
        getAllInvitation(
          10000,
          periodeId,
          provinceId,
          cityId,
          selectedDistrict,
          selectedSubdistrict
        ).then((response) => {
          if (response) {
            setData(response.data.data.data);
          }
        });
    }
  }, [selectedProvince, selectedCity, selectedDistrict, selectedSubdistrict]);
  return (
    <Card className="mb-4" style={{ border: "none" }}>
      <Card.Header
        style={{ backgroundColor: "white", border: "none" }}
        className="p-0 px-3"
      >
        <h5 className="font-weight-bold">Undangan</h5>
        <h6
          className="m-0 font-weight-bold mb-2 mb-md-0"
          style={{ color: "black" }}
        >
          Cetak Undangan
        </h6>
      </Card.Header>
      <Card.Body className="col">
        <hr className="m-0 mb-4" />
        <Row>
          <Col md={3}>
            <Form.Group controlId="exampleForm.SelectCustom">
              <Form.Control
                style={{ borderRadius: "20px" }}
                as="select"
                custom
                value={selectedProvince}
                disabled={roles !== "Super Admin"}
                onChange={handleProvinceChange}
              >
                <option value={0} disabled>
                  {provincesLoading
                    ? "Loading..."
                    : roles !== "Super Admin"
                    ? provinceName
                    : "Pilih Provinsi"}
                </option>
                {provincesData.list.map((item, i) => {
                  return (
                    <option value={item.province_id} key={`provinces-${i}`}>
                      {item.province_name}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
          </Col>

          <Col md={3}>
            <Form.Group controlId="exampleForm.SelectCustom">
              <Form.Control
                style={{ borderRadius: "20px" }}
                as="select"
                custom
                value={selectedCity}
                disabled={roles !== "Super Admin"}
                onChange={handleCityChange}
              >
                <option value={0} disabled>
                  {citiesLoading
                    ? "Loading..."
                    : roles !== "Super Admin"
                    ? cityName
                    : "Pilih Kota"}
                </option>
                {citiesData.list.map((item, i) => {
                  return (
                    <option value={item.city_id} key={`cities-${i}`}>
                      {item.city_name}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
          </Col>

          <Col md={3}>
            <Form.Group controlId="exampleForm.SelectCustom">
              <Form.Control
                style={{ borderRadius: "20px" }}
                as="select"
                custom
                value={selectedDistrict}
                onChange={handleDistrictChange}
              >
                <option value={0} disabled>
                  {districtsLoading ? "Loading..." : "Pilih Kecamatan"}
                </option>
                {districtsData.list.map((item, i) => {
                  return (
                    <option value={item.district_id} key={`districts-${i}`}>
                      {item.district_name}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
          </Col>

          <Col md={3}>
            <Form.Group controlId="exampleForm.SelectCustom">
              <Form.Control
                style={{ borderRadius: "20px" }}
                as="select"
                custom
                value={selectedSubdistrict}
                onChange={handleSubdistrictChange}
              >
                <option value={0} disabled>
                  {subdistrictsLoading ? "Loading..." : "Pilih Kelurahan"}
                </option>
                {subdistrictsData.list.map((item, i) => {
                  return (
                    <option
                      value={item.subdistrict_id}
                      key={`subdistricts-${i}`}
                    >
                      {item.subdistrict_name}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Button
          className="float-right"
          style={{ borderRadius: "20px" }}
          onClick={handleShowPrintInvitation}
        >
          Cetak
        </Button>
        <ConfirmationModal
          buttonNegative="Batal"
          buttonPositive="Cetak"
          title="Konfirmasi Cetak Undangan"
          show={showInvitation}
          body={"Apakah anda yakin?"}
          onNegative={() => setShowInvitation(false)}
          onPositive={handlePrintInvitation}
          disableButton={loading}
        />
        <div className="" style={{ display: "none" }}>
          <ComponentInvitationPrint ref={componentInvitationRef} data={data} />
        </div>
      </Card.Body>
    </Card>
  );
};
