import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { setCitiesMessage, setProvincesMessage } from "redux/actions";
import { resetProvincesToInit, setProvincesList } from "redux/actions";

import { getProvinces } from "services/provincesService";

import { useAppDispatch } from "redux/store";
import { addCities, updateCities } from "services/citiesService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const CitiesForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue = { city_id: 0, province_id: 0, city_name: '', postal_code: '' };
    const initialValue = action === 'edit' ? row : iValue;
    const provincesData = useSelector((state: RootState) => state.provinces);

    useEffect(() => {
        if (provincesData && provincesData.list && provincesData.list.length === 0) {
            dispatch(resetProvincesToInit());
            getProvinces(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '').then((response) => {
                if (response && response.records) {
                    dispatch(setProvincesList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
                } else {
                    dispatch(setProvincesMessage("No Record Found For Provinces"));
                }
            })
        }
    })
    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            if (action === 'edit') {
                const response = await updateCities(values);
                if (response && response.data && response.data.code === 1) {
                    dispatch(setCitiesMessage("Updated Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setCitiesMessage("Some error occured!"));
                }
            } else if (action === 'add') {
                const response = await addCities(values);
                if (response && response.data && response.data.code === 1) {
                    dispatch(setCitiesMessage("Added Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setCitiesMessage("Some error occured!"));
                }
            }
        },
        validationSchema: yup.object({
            city_id: yup.number().required('city_id is required'),
            city_name: yup.string().nullable(),
            postal_code: yup.string().nullable(),
            province_id: yup.string().required('province_id is required'),

        }),
    });
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-primary text-capitalize">{action} Cities
                    <Button className="btn-icon-split float-right" onClick={() => hideShowForm(false)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-list"></i>
                        </span>
                        <span className="text">View Cities</span>
                    </Button>
                </h6>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                    <Form.Group>
                        <label className="form -control-label">city_id</label>
                        <Form.Control type="text" name="city_id" className="form-control" value={formik.values.city_id}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.city_id && !!formik.errors.city_id}
                            isValid={!!formik.touched.city_id && !formik.errors.city_id}
                        ></Form.Control>
                        {
                            formik.errors.city_id && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.city_id}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group>
                        <label className="form -control-label">city_name</label>
                        <Form.Control type="text" name="city_name" className="form-control" value={formik.values.city_name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.city_name && !!formik.errors.city_name}
                            isValid={!!formik.touched.city_name && !formik.errors.city_name}
                        ></Form.Control>
                        {
                            formik.errors.city_name && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.city_name}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group>
                        <label className="form -control-label">postal_code</label>
                        <Form.Control type="text" name="postal_code" className="form-control" value={formik.values.postal_code}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.postal_code && !!formik.errors.postal_code}
                            isValid={!!formik.touched.postal_code && !formik.errors.postal_code}
                        ></Form.Control>
                        {
                            formik.errors.postal_code && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.postal_code}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group>
                        <label className="form -control-label">province_id</label>
                        <Form.Control as="select" name="province_id" className="form-control" value={formik.values.province_id}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.province_id && !!formik.errors.province_id}
                            isValid={!!formik.touched.province_id && !formik.errors.province_id}
                        >
                            <option value={0}>Select Provinces </option>
                            {
                                provincesData.list.map((item, i) => {
                                    return <option value={item.province_id} key={`provinces-${i}`}>{item.province_name}</option>
                                })}
                        </Form.Control>
                        {
                            formik.errors.province_id && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.province_id}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>

                    <Form.Group>
                        <Button type="submit" className="float-right" variant="primary">Save</Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

