import { APIService } from "services";

export const getShipments = async (pageNo, pageSize, search, periode_id, date, province_id, city_id, district_id, subdistrict_id) => {
    let res;
    if (search.length === 0) {
        res = await getAllShipments(pageNo, pageSize, search, periode_id, date, province_id, city_id, district_id, subdistrict_id);
    }
    else {
        try {
            res = await searchShipments(pageNo, pageSize, search, periode_id, date, province_id, city_id, district_id, subdistrict_id);
        } catch (err) {
            return { records: [], total_count: 0 }
        }
    }
    if (res && res.data && res.data.data) {
        return { records: res.data.data.data, total_count: res.data.data.total, lastPage: res.data.data.last_page }
    } else {
        return { records: [], totalCount: 0 }
    }

}


export const addShipments = (data) => {
    return APIService.api().post(`/shipments`, data)
}
export const updateShipments = (data) => {
    return APIService.api().put(`/shipments/${data.id}`, data)
}
export const getAllShipments = (page, paginator, search, periode_id, date, province_id, city_id, district_id, subdistrict_id) => {
    return APIService.api().get(`/shipments?page=${page}&paginator=${paginator}&periode_id=${periode_id}&date=${date}&province_id=${province_id}&city_id=${city_id}&district_id=${district_id}&subdistrict_id=${subdistrict_id}`)
}
export const getOneShipments = (id) => {
    return APIService.api().get(`/shipments/${id}`)
}
export const searchShipments = (page, paginator, search, periode_id, date, province_id, city_id, district_id, subdistrict_id) => {
    return APIService.api().get(`/shipments/search/${search}/?page=${page}&paginator=${paginator}&periode_id=${periode_id}&date=${date}&province_id=${province_id}&city_id=${city_id}&district_id=${district_id}&subdistrict_id=${subdistrict_id}`)
}
export const deleteShipments = (id) => {
    return APIService.api().delete(`/shipments/${id}`)
}

export const getBastShipmentById = (id, data) => {
    return APIService.api().post(`/shipments/get-bast/${id}`, data)
}
