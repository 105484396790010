import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IWarehouses {
    id: number,
    code?: string,
    name?: string,
    subdistrict?: string,
    district?: string,
    city?: string,
    state?: string,
    postal_code?: string,
    district_code?: string,
    address1?: string,
    address2?: string,
    latitude?: number,
    longitude?: number,
    telepon?: string,
    deleted_at?: Date,
    created_at?: Date,
    updated_at?: Date
}

interface IWarehousesData {
    list?: Array<IWarehouses>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
    loading?: boolean;
}

const initialState: IWarehousesData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
    loading: false
};

const warehousesSlice = createSlice({
    name: "warehouses",
    initialState,
    reducers: {
        setWarehousesList: (state, _action: PayloadAction<IWarehousesData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetWarehousesToInit: (state) => {
            state = initialState;
        },
        setWarehousesMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
        startLoadingWarehouses: (state) => {
            state.loading = true
        },
        stopLoadingWarehouses: (state) => {
            state.loading = false
        }
    },
});

export const { setWarehousesList, resetWarehousesToInit, setWarehousesMessage, startLoadingWarehouses, stopLoadingWarehouses } = warehousesSlice.actions;

export default warehousesSlice.reducer;

