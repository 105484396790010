import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IShipments {
    id: number,
    status?: string,
    queue_number?: number,
    document_id?: number,
    driver_id?: number,
    vehicle_id?: number,
    shipment_date?: Date,
    destination?: string,
    destination_latitude?: number,
    destination_longitude?: number,
    verif_documents?: string,
    created_by?: string,
    updated_by?: string,
    upload_doc_by?: string,
    photo_pickup?: string,
    finish_by?: string,
    created_at: Date,
    updated_at: Date,
    loading_at: Date,
    loading_by?: string,
    package_qty?: string,
    notes?: string,
    bast_code?: string,
    ttb_code?: string,
    nama_pejabat?: string,
    nama_pendamping?: string
}

interface IShipmentsData {
    list?: Array<IShipments>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
    loading?: boolean;
    lastPage?: number;
}

const initialState: IShipmentsData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
    loading: false,
    lastPage: 0
};

const shipmentsSlice = createSlice({
    name: "shipments",
    initialState,
    reducers: {
        setShipmentsList: (state, _action: PayloadAction<IShipmentsData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
            state.lastPage = _action.payload.lastPage;
        },
        resetShipmentsToInit: (state) => {
            state = initialState;
        },
        setShipmentsMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
        startLoadingShipments: (state) => {
            state.loading = true
        },
        stopLoadingShipments: (state) => {
            state.loading = false
        }
    },
});

export const { setShipmentsList, resetShipmentsToInit, setShipmentsMessage, startLoadingShipments, stopLoadingShipments } = shipmentsSlice.actions;

export default shipmentsSlice.reducer;

