import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IDashboard {
    total_alokasi: number;
    total_handover: number;
    total_process: number;
    total_completed: number;
    total_rows: number;
    province_id: number;
    name: string;
    total_city: number;
    total_district: number;
    total_subdistrict: number;
    province_lat: string;
    province_lng: string;
    percentage_province: string;
    realisasi: string;
    percentage_realisasi: string;
    reversal: number;
    percentage_reversal: number;
    pengiriman: string;
    percentage_pengiriman: string;
    proses: string;
    percentage_proses: string;
    diterima: string;
    percentage_diterima: string;
    selesai: string;
    percentage_selesai: string;
    sisa: string;


}

interface IDashboardData {
    list?: any;
    message?: string;
    loading?: boolean;
}

const initialState: IDashboardData = {
    list: [],
    message: '',
    loading: false
};

const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {
        setDashboardList: (state, _action: PayloadAction<IDashboardData>) => {
            state.list = _action.payload.list;
        },
        resetDashboardToInit: (state) => {
            state = initialState;
        },
        setDashboardMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
        startLoadingDashboard: (state) => {
            state.loading = true
        },
        stopLoadingDashboard: (state) => {
            state.loading = false
        }
    },
});

export const { setDashboardList, resetDashboardToInit, setDashboardMessage, startLoadingDashboard, stopLoadingDashboard } = dashboardSlice.actions

export default dashboardSlice.reducer;

