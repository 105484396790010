import axios from "axios"
import { APIService } from "services"

export const getAllReports = (params) => {
    const { type, id, period, code, doc_id, periode_id } = params
    return APIService.api().get(`/report/${type}/${id}?periode_id=${periode_id}`)
}

export const getAllRecap = () => {
    return axios.get('https://yaons.bkb.digital/public/get-recap.php')
}