import "react-loading-skeleton/dist/skeleton.css";
import Skeleton from "react-loading-skeleton";

const TotalCard = (props) => {
  const { data, loading } = props;
  return (
    <div>
      <div className="row" style={{ gap: 5, color: "black" }}>
        <div
          className="col p-3"
          style={{ backgroundColor: "#E3F5FF", borderRadius: "17.61px",  }}>
          <div className="d-flex justify-content-center py-2">Total Alokasi</div>
          <div className="d-flex justify-content-center font-weight-bolder py-2">
            {loading ? (
              <Skeleton style={{ width: "80px" }} />
            ) : (
              data?.list?.total_alokasi
            )}
          </div>
        </div>

        <div
          className="col p-3 "
          style={{ backgroundColor: "#E5ECF6", borderRadius: "17.61px",  }}>
          <div className="d-flex justify-content-center py-2">Total Diproses</div>
          <div className="d-flex justify-content-center font-weight-bolder py-2">
            {loading ? (
              <Skeleton style={{ width: "80px" }} />
            ) : (
              data?.list?.total_process
            )}
          </div>
        </div>

        <div
          className="col p-3 "
          style={{ backgroundColor: "#E5F6EA", borderRadius: "17.61px",  }}>
          <div className="d-flex justify-content-center py-2">Total Selesai</div>
          <div className="d-flex justify-content-center font-weight-bolder py-2">
            {loading ? (
              <Skeleton style={{ width: "80px" }} />
            ) : (
              data?.list?.total_completed
            )}
          </div>
        </div>
      </div>
      {/* <div className="clas"></div> */}
      {/* {JSON.stringify(data, 2, null)}
       */}
      {/* <div className="card-chart shadow-sm">
        <p className="font-weight-bold">Total Keseluruhan Proses</p>
        <p>Data diambil berdasarkan data dilapangan</p>
        <div className="circle-chart">
          <div className="circle">
            <div className="content">
              <div className="">Total Alokasi</div>
              <div className="" style={{ fontSize: 24 }}>
                {" "}
                {loading ? <Skeleton style={{width:"80px"}}/> : data?.list?.total_alokasi}
              </div>
            </div>
          </div>
          <div className="circle-2">
            <div className="content">
              <div className="">Total Diproses</div>
              <div className="" style={{ fontSize: 24 }}>
                {" "}
                {loading ? <Skeleton style={{width:"80px"}}/> : data?.list?.total_process}
              </div>
            </div>
          </div>
          <div className="circle-3">
            <div className="content">
              <div className="">Total Selesai</div>
              <div className="" style={{ fontSize: 24 }}>
                {" "}
                {loading ? <Skeleton style={{width:"80px"}}/> : data?.list?.total_completed}
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default TotalCard;
