import React, { useEffect } from "react";
import { Button, NavLink } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { RootState } from "redux/reducers";
import { sidebarToggle, activateMenu, activateTopMenu } from "redux/actions";
import { useAppDispatch } from "redux/store";
import { MenuItems } from "./MenuItems";
const Sidebar: React.FC = () => {
  const isTopActiveMenu = useSelector(
    (state: RootState) => state.template.topActiveMenu
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleActiveMenu = (menuName: string) => {
    dispatch(activateMenu(menuName));
  };

  const isSidebar = useSelector((state: RootState) => state.template.sidebar);

  const isActiveMenu = useSelector(
    (state: RootState) => state.template.activeMenu
  );

  const location = useLocation();
  const handleMenuClick = (path) => {
    dispatch(activateMenu(path));
    navigate(path, { replace: true });
  };

  const handleSubMenuClick = (path) => {
    if (location.pathname === "/bnba" && path === "/bnba/print") {
      navigate(path, { replace: true });
      window.location.reload();
    } else {
      navigate(path, { replace: true });
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
  };

  useEffect(() => {
    document.body.classList.toggle("sidebar-toggled", isSidebar);
  }, [isSidebar]);

  return (
    <div className="">
      <div className="d-md-flex d-none">
        {!isSidebar && (
          <ul className="navbar-nav ml-auto">
            <img
              src="../logos/logo-sbl-logistic.png"
              style={{ width: 120 }}
            />
          </ul>
        )}
        <i
          // cursor-pointer
          className="fas fa-bars ml-auto mr-3 mt-4 
           text-white"
          // onClick={() => dispatch(sidebarToggle())}
        ></i>
      </div>
      <ul
        className={
          isSidebar
            ? "navbar-nav sidebar sidebar-dark accordion toggled"
            : "navbar-nav sidebar sidebar-dark accordion"
        }
        id="accordionSidebar">
        <div className="mt-4"></div>

        {MenuItems.map((item, i) => {
          return item.subMenu && item.subMenu.length > 0 ? (
            <li className="nav-item" key={`Menu-${i}`}>
              <NavLink
                style={{ color: "black" }}
                className={isActiveMenu === item.title ? "" : "collapsed"}
                aria-expanded={isActiveMenu === item.title ? true : false}
                onClick={() => handleActiveMenu(item.title)}>
                <i
                  style={{ color: "black" }}
                  className={
                    isActiveMenu === item.title
                      ? "fas fa-fw fa-angle-down"
                      : "fas fa-fw fa-angle-right"
                  }></i>
                <img
                  style={{ color: "black" }}
                  src={item.logopath}
                  alt={item.title}></img>
                <span className="ml-1">{item.title}</span>
              </NavLink>
              <div
                id="collapseTwo"
                className={
                  isActiveMenu === item.title ? "collapse show" : "collapse"
                }
                aria-labelledby="headingTwo"
                data-parent="#accordionSidebar">
                <div
                  className="py-2 collapse-inner rounded"
                  style={{ backgroundColor: "#F3F3F3" }}>
                  {item.subMenu.map((sub, k) => {
                    return (
                      <>
                        <ul
                          onClick={() => handleSubMenuClick(sub.path)}
                          className="cursor-pointer"
                          style={{ padding: 5, marginLeft: 25 }}>
                          <li
                            className=""
                            style={{ listStyle: "inside", color: "black" }}>
                            <span className=" h6">{sub.title}</span>
                          </li>
                        </ul>
                      </>
                    );
                  })}
                </div>
              </div>
            </li>
          ) : (
            <li
              className={
                isActiveMenu === item.path ? "nav-item active" : "nav-item"
              }
              key={`Menu-${i}`}>
              <div className="mx-3">Dashboards</div>
              <Button
                style={{ color: "black" }}
                variant="link"
                className="nav-link pt-2 ml-3"
                onClick={() => handleMenuClick(item.path)}>
                <img src={item.logopath} alt=""></img>
                <span className="ml-1">{item.title}</span>
              </Button>
              <hr className="sidebar-divider" />
            </li>
          );
        })}
        <button
          className="mb-3 p-2"
          style={{
            border: "none",
            color: "black",
          }}
          onClick={handleLogout}>
          <i className="fas fa-sign-out-alt"></i>
        </button>
      </ul>
    </div>
  );
};

export default Sidebar;
