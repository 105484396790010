import React from "react";

interface Props {
  data: any;
}
export const ComponentUndangan = React.forwardRef<HTMLInputElement, Props>(
  (props, ref) => {
    const { data } = props;
    return (
      <>
        <div
          className=""
          ref={ref}
          style={{ paddingLeft: 50, paddingRight: 50, color: "black" }}>
          {/* {data.map((inv) => ( */}
          <div className="">
            <div className="" style={{ display: "flex", alignItems: "center" }}>
              <img
                src="../logos/logo-bulog.jpeg"
                alt=""
                width={125}
                height={40}
              />
              <img
                src="../logos/logo-bapanas.png"
                alt=""
                style={{ marginLeft: 200 }}
                width={90}
                height={40}
              />
              <img
                src="../logos/logo-sbl-logistic.png"
                alt=""
                style={{ marginLeft: 200 }}
                width={90}
                // height={55}
              />
            </div>
            <div className="" style={{ display: "flex", marginTop: 10 }}>
              <div className="">
                <h6 style={{ fontWeight: "bold" }}>PEMBERITAHUAN</h6>
                <h6>Nomor: {data.reg_number}</h6>
              </div>
              <div className="" style={{ marginLeft: 100 }}>
                <h6 style={{ fontWeight: "bold" }}>KEPADA:</h6>
                <h6>{data.kpm_name}</h6>
                <h6>{data.address}</h6>
              </div>
            </div>
            <div className="" style={{ marginBottom: 20 }}>
              <p>Dengan Hormat,</p>
              <p>
                Berdasarkan Keputusan Pemerintah Republik Indonesia c.q. Badan
                Pangan Nasional Republik Indonesia, Bapak/Ibu/Sdr/i dinyatakan
                berhak memperoleh Bantuan Pangan Tahun 2023 dari Pemerintah RI.
                Harap menjadi perhatian Bapak/Ibu penerima Bantuan Pangan
                Oktober 2023:
              </p>
              <ol>
                <li>
                  Persyaratan pengambilan/penerimaan Bantuan Pangan Tahun 2023
                  dengan membawa dan menunjukkan Surat Undangan, KTP-el dan/atau
                  Kartu Keluarga asli.
                </li>
                <li>
                  Dalam penyaluran Bantuan Pangan Tahun 2023 PT. Sarana Bandar
                  Logistik tidak memungut biaya apapun. Jika ada pungutan oleh
                  Petugas silahkan laporkan dengan menghubungi PIC Kantor Pusat
                  PT. Sarana Bandar Logistik, dengan melampirkan bukti terkait.
                </li>
                <li>
                  Pada saat penyerahan akan dilakukan pendataan geotagging dan
                  foto diri PBP (Penerima Bantuan Pangan) Tahun 2023 oleh Juru
                  Serah.
                </li>
              </ol>
            </div>
            <div
              className=""
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "end",
              }}>
              <div className="">
                <p style={{ marginBottom: 0 }}>Hormat Kami,</p>
                <p>PT. SBL</p>
              </div>
              <div className="" style={{ display: "flex", alignItems: "end" }}>
                <h1 style={{ marginRight: 8 }}>{data.id}</h1>
                <img width={80} src={data.kpm_barcode} alt="" />
              </div>
            </div>
            <hr />
          </div>
          {/* ))} */}
        </div>
      </>
    );
  }
);
