import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ICreatedShipments {
    id?: number;
    status?: string;
    queue_number?: string;
}

interface ICreatedShipmentsData {
    list?: Array<ICreatedShipments>;
    message?: string;
    loading?: boolean;
}

const initialState: ICreatedShipmentsData = {
    list: [],
    message: '',
    loading: false
};

const createdShipmentsSlice = createSlice({
    name: "createdShipments",
    initialState,
    reducers: {
        setCreatedShipmentsList: (state, _action: PayloadAction<ICreatedShipmentsData>) => {
            state.list = _action.payload.list;
        },
        resetCreatedShipmentsToInit: (state) => {
            state = initialState;
        },
        setCreatedShipmentsMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
        startLoadingCreatedShipments: (state) => {
            state.loading = true
        },
        stopLoadingCreatedShipments: (state) => {
            state.loading = false
        }
    },
});

export const { setCreatedShipmentsList, resetCreatedShipmentsToInit, setCreatedShipmentsMessage, startLoadingCreatedShipments, stopLoadingCreatedShipments } = createdShipmentsSlice.actions

export default createdShipmentsSlice.reducer;

