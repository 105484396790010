import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { resetPeriodsToInit, setPeriodsMessage } from "redux/actions";
import { Button, Card, Col, Form, InputGroup, Spinner } from "react-bootstrap";
import { Constant } from "template/Constant";
import ConfirmationModal from "template/ConfirmationModal";
import { deletePeriods } from "services/periodsService";
import { useNavigate } from "react-router";
type Props = {
  hideShowForm: (action) => void;
  handleRowEdit: (row, index) => void;
  getData: (page, pageSize, searchKey) => void;
};
export const PeriodsTable: React.FC<Props> = ({
  hideShowForm,
  handleRowEdit,
  getData,
}) => {
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState("");
  const [showDelete, setShowDelete] = useState(false);
  const [rowData, setRowData] = useState(undefined);
  const rData = useSelector((state: RootState) => state.periods);
  const isLoading = useSelector((state: RootState) => state.periods.loading);
  const handleSearch = () => {
    if (search.length > 0) {
      getData(Constant.defaultPageNumber, Constant.defaultPageSize, search);
    }
  };
  const handlePerRowsChange = async (newPerPage, page) => {
    await getData(page, newPerPage, search ?? "");
  };
  const handlePageChange = (page) => {
    getData(page, rData.pageSize, search ?? "");
  };
  const handleRowDeleteClick = (row) => {
    setRowData(row);
    setShowDelete(true);
  };
  const navigate = useNavigate();
  useEffect(() => {
    if (rData && rData.list && rData.list.length === 0) {
      if (!search) {
        dispatch(resetPeriodsToInit());
        getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
      } else {
        getData(Constant.defaultPageNumber, Constant.defaultPageSize, search);
      }
    }
  }, [rData.list.length]);
  const handleReset = () => {
    setSearch("");
    dispatch(resetPeriodsToInit());
    getData(Constant.defaultPageNumber, rData.pageSize, "");
  };
  const handleServerDelete = async () => {
    if (rowData) {
      const response = await deletePeriods(rowData.id);
      if (response) {
        dispatch(resetPeriodsToInit());
        dispatch(setPeriodsMessage("Berhasil menghapus data"));
        getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
        setShowDelete(false);
      } else {
        dispatch(setPeriodsMessage("Terjadi kesalahan"));
      }
    }
  };

  const handleRowSelection = (row) => {
    console.log(row); // Row Selection Functionality can be written here
  };
  const handleAddButtonClick = () => {
    dispatch(setPeriodsMessage(""));
    hideShowForm("add");
  };
  const tableCustomStyles = {
    headRow: {
      style: {
        color: "white",
        backgroundColor: "#1D3557",
        borderRadius: "8px",
      },
    },
    rows: {
      stripedStyle: {
        backgroundColor: "#E1F5FE",
        borderRadius: "8px",
      },
    },
  };
  const columns = [
    { name: "Periode", selector: (row) => row.id, sortable: true },
    { name: "Bulan", selector: (row) => row.month, sortable: true },
    { name: "Tahun", selector: (row) => row.year, sortable: true },
    {
      name: "Ubah",
      button: true,
      cell: (row, index) => (
        <Button
          variant="link"
          className="btn-sm"
          onClick={() => handleRowEdit(row, index)}>
          <i className="table-icon fas fa-edit"></i>
        </Button>
      ),
    },
    {
      name: "Hapus",
      button: true,
      cell: (row) => (
        <Button
          variant="link"
          className="btn-sm"
          onClick={() => handleRowDeleteClick(row)}>
          <i className="table-icon fas fa-trash"></i>
        </Button>
      ),
    },
  ];
  return (
    <Card className="mb-4" style={{ border: "none" }}>
      <Card.Header
        style={{ backgroundColor: "white", border: "none" }}
        className="p-0 px-3 d-md-flex justify-content-between">
        <div className="">
          <h5 className="font-weight-bold">Periode</h5>
          <h6
            className="m-0 font-weight-bold mb-2 mb-md-0"
            style={{ color: "black" }}>
            Daftar Periode ({rData.totalCount})
            <Button
              variant="light"
              className="btn-circle btn-sm ml-2"
              onClick={handleReset}>
              <i className="fa fa-refresh"></i>
            </Button>
          </h6>
        </div>
        <div className="">
          <Button
            className="btn-icon-split"
            onClick={() => navigate("/periods/add")}
            style={{
              backgroundColor: "green",
              borderRadius: "20px",
              padding: 6,
            }}>
            <span className="text">TAMBAH</span>
          </Button>
        </div>
      </Card.Header>
      <Card.Body>
        <hr className="m-0 mb-4" />

        <div className="table-responsive">
          {isLoading && rData.list.length === 0 ? (
            <Spinner
              animation="border"
              variant="danger"
              className="d-flex mx-auto mt-5"></Spinner>
          ) : (
            <DataTable
              // selectableRows={true}
              onSelectedRowsChange={handleRowSelection}
              paginationPerPage={Constant.defaultPageNumber}
              paginationRowsPerPageOptions={
                Constant.paginationRowsPerPageOptions
              }
              columns={columns}
              data={rData.list}
              onChangeRowsPerPage={handlePerRowsChange}
              paginationTotalRows={rData.lastPage}
              className="table table-bordered"
              pagination
              paginationServer
              customStyles={tableCustomStyles}
              progressPending={isLoading}
              striped
              onChangePage={handlePageChange}></DataTable>
          )}
        </div>
      </Card.Body>
      <ConfirmationModal
        buttonNegative="Batal"
        buttonPositive="Hapus"
        title="Konfirmasi Hapus"
        show={showDelete}
        body={"Apakah anda yakin?"}
        onNegative={() => setShowDelete(false)}
        onPositive={handleServerDelete}
      />
    </Card>
  );
};
