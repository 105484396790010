import React, { useEffect } from "react";
import Layout from "template";

import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";

import "react-loading-skeleton/dist/skeleton.css";
import ChartComponent from "./Chart";
import {
  setDashboardList,
  startLoadingDashboard,
  stopLoadingDashboard,
} from "redux/actions";
import MapComponent from "./MapChart";
import { getAllDashboardReports } from "services/dashboardService";
import { useAppDispatch } from "redux/store";
import { ReportsTable } from "./tableReport";
import TotalCard from "./TotalCard";
import { ShipmentsTable } from "components/shipments/table";
import TableBNBA from "./tableBNBA";

export const Dashboard: React.FC = () => {
  const dispatch = useAppDispatch();
  const dashboardLoading = useSelector(
    (state: RootState) => state.dashboard.loading
  );
  const dashboardData = useSelector((state: RootState) => state.dashboard);
  const periodsId = localStorage.getItem("periode_id");

  const getDashboardData = () => {
    dispatch(startLoadingDashboard());
    getAllDashboardReports(periodsId).then((response) => {
      if (response) {
        dispatch(setDashboardList({ list: response.data }));
        dispatch(stopLoadingDashboard());
      }
    });
  };
  useEffect(() => {
    // if (dashboardData && dashboardData.list && dashboardData.list.length === 0) {
    getDashboardData();
    // }
  }, []);
  return (
    <Layout>
      <div className="container-fluid">
        <h6 style={{ color: "black" }}>Total Keseluruhan Proses</h6>
        <div className="row">
          <div className="col-lg-6 my-1">
            <div className="col mb-3">
              <TotalCard data={dashboardData} loading={dashboardLoading} />
            </div>
            <div className="col mt-3">
              <ChartComponent data={dashboardData} loading={dashboardLoading} />
            </div>
          </div>
          <div className="col-lg-6 my-1">
            <MapComponent data={dashboardData} loading={dashboardLoading} />
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div
          className="d-card d-flex justify-content-between justify-content-lg-center flex-column flex-md-row flex-wrap"
          style={{ gap: "12px" }}></div>

        <div className="sj-table">
          {/* <ReportsTable /> */}
          <TableBNBA />
        </div>
      </div>
    </Layout>
  );
};
