import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Alert, Button, Card, Col, Form, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import {
  resetProvincesToInit,
  setPengirimanMessage,
  setProvincesList,
  setProvincesMessage,
  setShipmentsMessage,
  startLoadingProvinces,
  stopLoadingProvinces,
} from "redux/actions";

import { useAppDispatch } from "redux/store";
import { Constant } from "template/Constant";
import * as yup from "yup";
import { useNavigate } from "react-router";
import { uploadFileService } from "services/fileUploadService";
import { getProvinces } from "services/provincesService";
import { addPengiriman, updatePengiriman } from "services/pengirimanService";
type Props = {
  row?: any;
  hideShowForm: (actionName) => void;
  getData: (
    page,
    pageSize,
    searchKey,
    periode_id,
    date,
    province_id,
    city_id,
    district_id,
    subdistrict_id
  ) => void;
  action?: string;
};
export const ShipmentsForm: React.FC<Props> = ({
  row,
  hideShowForm,
  getData,
  action,
}) => {
  const dispatch = useAppDispatch();
  const iValue = {
    id: 0,
    province_id: 0,
    city_id: 0,
    district_id: 0,
    subdistrict_id: 0,
    shipment_date: "",
    destination: "",
    destination_latitude: "",
    destination_longitude: "",
    package_qty: "",
    notes: "",
    nama_pejabat: "",
    nama_pendamping: "",
    driver_name: "",
    phone_number: "",
    vehicle_name: "",
    plate_number: "",
    warehouse_name: "",
  };
  const initialValue = action === "edit" || action === "view" ? row : iValue;
  const driversData = useSelector((state: RootState) => state.drivers);
  const vehiclesData = useSelector((state: RootState) => state.vehicles);
  const provincesData = useSelector((state: RootState) => state.provinces);
  const citiesData = useSelector((state: RootState) => state.cities);
  const districtsData = useSelector((state: RootState) => state.districts);
  const subdistrictsData = useSelector(
    (state: RootState) => state.subdistricts
  );
  const provincesLoading = useSelector(
    (state: RootState) => state.provinces.loading
  );
  const citiesLoading = useSelector((state: RootState) => state.cities.loading);
  const districtsLoading = useSelector(
    (state: RootState) => state.districts.loading
  );
  const subdistrictsLoading = useSelector(
    (state: RootState) => state.subdistricts.loading
  );
  const periodsId = localStorage.getItem("periode_id");
  const provinceId = localStorage.getItem("province_id");
  const cityId = localStorage.getItem("city_id");
  const districtId = localStorage.getItem("district_id");
  const subdistrictId = localStorage.getItem("subdistrict_id");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    // if (kpmsData && kpmsData.list && kpmsData.list.length === 0) {
    //     dispatch(resetKpmsToInit());
    //     getKpms(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '').then((response) => {
    //         if (response && response.records) {
    //             dispatch(setKpmsList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
    //         } else {
    //             dispatch(setKpmsMessage("Data kosong"));
    //         }
    //     })
    // }
    // if (createdShipmentsData && createdShipmentsData.list && createdShipmentsData.list.length === 0) {
    //     dispatch(resetCreatedShipmentsToInit());
    //     dispatch(startLoadingCreatedShipments())
    //     getCreatedShipments().then((response) => {
    //         if (response) {
    //             dispatch(setCreatedShipmentsList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.data.data, searchKey: '' }));
    //             dispatch(stopLoadingProvinces())
    //         } else {
    //             dispatch(setCreatedShipmentsMessage("Data kosong"));
    //             dispatch(stopLoadingProvinces())
    //         }
    //     })
    // }

    if (
      provincesData &&
      provincesData.list &&
      provincesData.list.length === 0
    ) {
      dispatch(resetProvincesToInit());
      dispatch(startLoadingProvinces());
      getProvinces(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        ""
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setProvincesList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
          dispatch(stopLoadingProvinces());
        } else {
          dispatch(setProvincesMessage("Data kosong"));
          dispatch(stopLoadingProvinces());
        }
      });
    }
    // if (driversData && driversData.list && driversData.list.length === 0) {
    //     dispatch(resetDriversToInit());
    //     dispatch(startLoadingDrivers())
    //     getDrivers(Constant.defaultPageNumber, 1000, '').then((response) => {
    //         if (response && response.records) {
    //             dispatch(setDriversList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
    //             dispatch(stopLoadingDrivers())
    //         } else {
    //             dispatch(setDriversMessage("Data kosong"));
    //             dispatch(stopLoadingDrivers())
    //         }
    //     })
    // }
    // if (vehiclesData && vehiclesData.list && vehiclesData.list.length === 0) {
    //     dispatch(resetVehiclesToInit());
    //     dispatch(startLoadingVehicles())
    //     getVehicles(Constant.defaultPageNumber, 1000, '').then((response) => {
    //         if (response && response.records) {
    //             dispatch(setVehiclesList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
    //             dispatch(stopLoadingVehicles())
    //         } else {
    //             dispatch(setVehiclesMessage("Data kosong"));
    //             dispatch(stopLoadingVehicles())
    //         }
    //     })
    // }
  }, []);
  const formik = useFormik({
    initialValues: initialValue,
    onSubmit: async (values) => {
      setLoading(true);
      if (action === "edit") {
        const result = {
          id: values.id,
          province_id: provinceId,
          city_id: cityId,
          district_id: districtId,
          subdistrict_id: subdistrictId,
          // shipment_date: values.shipment_date,
          destination: values.destination,
          destination_latitude: values.destination_latitude,
          destination_longitude: values.destination_longitude,
          package_qty: values.package_qty,
          notes: values.notes,
          nama_pejabat: values.nama_pejabat,
          nama_pendamping: values.nama_pendamping,
          driver_name: values.driver_name,
          phone_number: values.phone_number,
          vehicle_name: values.vehicle_name,
          plate_number: values.plate_number,
          warehouse_name: values.warehouse_name,
        };
        updatePengiriman(result)
          .then((response: any) => {
            if (response && response.data && response.data.code === 1) {
              setLoading(false);
              dispatch(setPengirimanMessage("Berhasil mengubah data"));
              getData(
                Constant.defaultPageNumber,
                Constant.defaultPageSize,
                "",
                periodsId,
                "",
                provinceId,
                cityId,
                districtId,
                subdistrictId
              );
              hideShowForm("");
            }
          })
          .catch(() => {
            dispatch(setPengirimanMessage("Terjadi kesalahan"));
            setLoading(false);
          });
      } else if (action === "add") {
        const result = {
          periode_id: periodsId,
          province_id: provinceId,
          city_id: cityId,
          district_id: districtId,
          subdistrict_id: subdistrictId,
          // shipment_date: values.shipment_date,
          destination: values.destination,
          destination_latitude: values.destination_latitude,
          destination_longitude: values.destination_longitude,
          package_qty: values.package_qty,
          notes: values.notes,
          nama_pejabat: values.nama_pejabat,
          nama_pendamping: values.nama_pendamping,
          driver_name: values.driver_name,
          phone_number: values.phone_number,
          vehicle_name: values.vehicle_name,
          plate_number: values.plate_number,
          warehouse_name: values.warehouse_name,
        };
        addPengiriman(result)
          .then((response: any) => {
            if (response && response.data && response.data.code === 1) {
              setLoading(false);
              dispatch(setPengirimanMessage("Berhasil menambah data"));
              getData(
                Constant.defaultPageNumber,
                Constant.defaultPageSize,
                "",
                periodsId,
                "",
                provinceId,
                cityId,
                districtId,
                subdistrictId
              );
              // hideShowForm('');
              navigate("/shipments");
            }
          })
          .catch(() => {
            dispatch(setPengirimanMessage("Terjadi kesalahan"));
            setLoading(false);
          });
      }
    },
    validationSchema: yup.object({
      // shipment_date: yup.date().required(),
      destination: yup.string().required(),
      // status: yup.string().nullable(),
      // queue_number: yup.number().nullable(),
      // shipment_date: yup.date().required(),
      // destination: yup.string().required(),
      destination_latitude: yup.number().required(),
      destination_longitude: yup.number().required(),
      // no_so:yup.string().nullable(),
      // no_doc_out:yup.string().nullable()
      // verif_documents: yup.string().nullable(),
      // created_by: yup.string().nullable(),
      // updated_by: yup.string().nullable(),
      // upload_doc_by: yup.string().nullable(),
      // photo_pickup: yup.string().nullable(),
      // finish_by: yup.string().nullable(),
      // // created_at: yup.date().required('created_at is required'),
      // // updated_at: yup.date().required('updated_at is required'),
      // // loading_at: yup.date().required('loading_at is required'),
      // loading_by: yup.string().nullable(),
      // package_qty: yup.string().required(),
      // notes: yup.string().nullable(),
      // // bast_code: yup.string().required(),
      // // ttb_code: yup.string().required(),
      // nama_pejabat: yup.string().nullable(),
      // nama_pendamping: yup.string().nullable(),
      // // document_id: yup.string().required('ID Dokumen wajib diisi'),
      // // driver_id: yup.string().required('Supir wajib diisi'),
      // // vehicle_id: yup.string().required('Kendaraan wajib diisi'),
    }),
  });
  const formikFile = useFormik({
    initialValues: { fileData: [] },
    onSubmit: async (values) => {
      var formData = new FormData();
      formData.append("file", values.fileData[0]);
      formData.append("type", "shipment");
      formData.append("id", row.id);
      formData.append("description", "document");
      uploadFileService(formData).then((res: any) => {
        if (res && res.data && res.data.success) {
          setMessage("Dokumen berhasil diunggah : " + res.data.file);
          dispatch(setShipmentsMessage("Dokumen berhasil diunggah"));
          hideShowForm("");
          formikFile.resetForm();
          navigate("/shipments");
        } else {
          setMessage("Gagal mengunggah dokumen");
          formikFile.resetForm();
        }
      });
    },
    validationSchema: yup.object({
      fileData: yup.array().min(1, "Pilih minimal 1 file"),
    }),
  });
  const handleDownloadVerif = (value) => {
    window.open(value, "_blank", "noopener,noreferrer");
  };
  const handleDownloadBastQrCode = (value) => {
    window.open(value, "_blank", "noopener,noreferrer");
  };
  const resetForm = () => {
    formik.resetForm();
  };
  // useEffect(() => {
  //     if (provinceId) {
  //         dispatch(resetCitiesToInit());
  //         dispatch(startLoadingCities())
  //         getCities(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '', provinceId).then((response) => {
  //             if (response && response.records) {
  //                 dispatch(setCitiesList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
  //                 dispatch(stopLoadingCities())
  //             } else {
  //                 dispatch(setCitiesMessage("Data kosong"));
  //                 dispatch(stopLoadingCities())
  //             }
  //         })
  //     }
  //     if (cityId) {
  //         dispatch(resetDistrictsToInit());
  //         dispatch(startLoadingDistricts())
  //         getDistricts(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '', cityId).then((response) => {
  //             if (response && response.records) {
  //                 dispatch(setDistrictsList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
  //                 dispatch(stopLoadingDistricts())
  //             } else {
  //                 dispatch(setDistrictsMessage("Data kosong"));
  //                 dispatch(stopLoadingDistricts())
  //             }
  //         })
  //     }
  //     if (districtId) {
  //         dispatch(resetSubdistrictsToInit());
  //         dispatch(startLoadingSubDistricts())
  //         getSubdistricts(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '', districtId).then((response) => {
  //             if (response && response.records) {
  //                 dispatch(setSubdistrictsList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
  //                 dispatch(stopLoadingSubDistricts())
  //             } else {
  //                 dispatch(setSubdistrictsMessage("Data kosong"));
  //                 dispatch(stopLoadingSubDistricts())
  //             }
  //         })
  //     }
  //     if (bastData && bastData.list && bastData.list.length === 0 && provinceId && cityId && districtId && subdistrictId) {
  //         dispatch(resetBastToInit());
  //         dispatch(startLoadingBast())
  //         getAllBast(provinceId, cityId, districtId, subdistrictId).then((response) => {
  //             if (response && response.data) {
  //                 dispatch(setBastList({ list: response.data.message }));
  //                 dispatch(stopLoadingBast())
  //             } else {
  //                 dispatch(setBastMessage("Data kosong"));
  //                 dispatch(stopLoadingBast())
  //             }
  //         })
  //     }
  // }, [provinceId, cityId, districtId, subdistrictId])
  // useEffect(() => {
  //     if (formikProvinceId) {
  //         dispatch(startLoadingCities())
  //         getCities(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '', formikProvinceId).then((response) => {
  //             if (response && response.records) {
  //                 dispatch(setCitiesList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
  //                 dispatch(stopLoadingCities())
  //                 dispatch(resetCitiesToInit());

  //             } else {
  //                 dispatch(setCitiesMessage("Data kosong"));
  //                 dispatch(stopLoadingCities())
  //                 dispatch(resetCitiesToInit());

  //             }
  //         })
  //     }
  // }, [formikProvinceId]);

  // useEffect(() => {
  //     if (formikCityId) {
  //         dispatch(resetDistrictsToInit());
  //         dispatch(startLoadingDistricts())
  //         getDistricts(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '', formikCityId).then((response) => {
  //             if (response && response.records) {
  //                 dispatch(setDistrictsList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
  //                 dispatch(stopLoadingDistricts())
  //             } else {
  //                 dispatch(setDistrictsMessage("Data kosong"));
  //                 dispatch(stopLoadingDistricts())
  //             }
  //         })

  //     }
  // }, [formikCityId]);

  // useEffect(() => {
  //     if (formikDistrictId) {
  //         dispatch(resetSubdistrictsToInit());
  //         dispatch(startLoadingSubDistricts())
  //         getSubdistricts(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '', formikDistrictId).then((response) => {
  //             if (response && response.records) {
  //                 dispatch(setSubdistrictsList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
  //                 dispatch(stopLoadingSubDistricts())
  //             } else {
  //                 dispatch(setSubdistrictsMessage("Data kosong"));
  //                 dispatch(stopLoadingSubDistricts())
  //             }
  //         })
  //     }
  //     if (periodsData && periodsData.list && periodsData.list.length === 0) {
  //         dispatch(resetPeriodsToInit());
  //         dispatch(startLoadingPeriods())
  //         getPeriods(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '').then((response) => {
  //           if (response && response.records) {
  //             dispatch(setPeriodsList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
  //             dispatch(stopLoadingPeriods())
  //           } else {
  //             dispatch(setPeriodsMessage("Data kosong"));
  //             dispatch(stopLoadingPeriods())
  //           }
  //         })
  //       }
  // }, [formikDistrictId]);

  // useEffect(() => {
  //     if (provinceId && cityId && districtId && subdistrictId) {
  //         dispatch(resetBastToInit());
  //         dispatch(startLoadingBast());
  //         getAllBast(provinceId, cityId, districtId, subdistrictId).then((response) => {
  //             if (response && response.data) {
  //                 dispatch(setBastList({ list: response.data.message }));
  //                 dispatch(stopLoadingBast());
  //             } else {
  //                 dispatch(setBastMessage("Data kosong"));
  //                 dispatch(stopLoadingBast());
  //             }
  //         });
  //     }
  // }, [provinceId, cityId, districtId, subdistrictId]);

  return (
    <Card className="mb-4" style={{ border: "none" }}>
      <Card.Header
        style={{ backgroundColor: "white", border: "none" }}
        className="p-0 px-3">
        <h5 className="font-weight-bold">BNBA</h5>
        <h6
          className="m-0 font-weight-bold text-capitalize"
          style={{ color: "black" }}>
          {action === "view" ? "Lihat" : action === "add" ? "Tambah" : "Ubah"}
        </h6>
      </Card.Header>
      <Card.Body>
        {message ? (
          <Col md={12}>
            <Alert variant="info">{message}</Alert>
          </Col>
        ) : null}
        {action !== "view" && action !== "add" && (
          <Form onSubmit={formikFile.handleSubmit}>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Upload Pengiriman</Form.Label>
              <Form.Control
                type="file"
                onChange={(event: React.ChangeEvent) => {
                  const dFile = event.target as HTMLInputElement;
                  if (dFile.files && dFile.files[0]) {
                    formikFile.setFieldValue(
                      "fileData",
                      Array.from(dFile.files)
                    );
                  }
                }}
                onBlur={formikFile.handleBlur}
                isInvalid={
                  !!formikFile.touched.fileData && !!formikFile.errors.fileData
                }
                isValid={
                  !!formikFile.touched.fileData && !formikFile.errors.fileData
                }
              />
              {formikFile.errors.fileData && (
                <Form.Control.Feedback type="invalid">
                  {formikFile.errors.fileData}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group>
              <Button type="submit" variant="primary">
                Upload
              </Button>
            </Form.Group>
          </Form>
        )}
        <div className="mb-4">Data Utama</div>

        <Form onSubmit={formik.handleSubmit} className="d-flex w-100 flex-wrap">
          {/* <Form.Group>
                        <label className="form -control-label">status</label>
                        <Form.Control type="text" name="status" className="form-control" value={formik.values.status}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.status && !!formik.errors.status}
                            isValid={!!formik.touched.status && !formik.errors.status}
                        ></Form.Control>
                        {
                            formik.errors.status && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.status}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group> */}
          {/* <Form.Group className="w-100 px-2 split-form">
                        <label className="form -control-label">Nomor Antrian Pengiriman</label>
                        <Multiselect
                            options={createdShipmentsData.list}
                            selectedValues={action === 'add' ? selectedOptions : mapQueueNumber}
                            onSelect={onSelect}
                            onRemove={onRemove}
                            displayValue="queue_number"
                            disable={action === 'view'}
                        />
                    </Form.Group> */}

          {/* <Form.Group style={{ display: action !== 'view' && 'none' }} className="w-100 px-2 split-form">
                        <label className="form -control-label">Nomor Antrian</label>
                        <Form.Control type="text" name="queue_number" className="form-control" value={formik.values.queue_number} disabled={action === 'view'}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.queue_number && !!formik.errors.queue_number}
                            isValid={!!formik.touched.queue_number && !formik.errors.queue_number}
                        ></Form.Control>
                        {
                            formik.errors.queue_number && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.queue_number}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group> */}
          {/* <Form.Group className="w-100 px-2 split-form" style={{ display: action != 'add' && 'none' }}>
                        <label className="form -control-label">Periode</label>
                        <Form.Control as="select" name="periode_id" className="form-control" value={formik.values.periode_id}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.periode_id && !!formik.errors.periode_id}
                            isValid={!!formik.touched.periode_id && !formik.errors.periode_id}
                            disabled={periodsLoading || action === 'view'}
                        >
                            <option value={0}>{periodsLoading ? 'Loading...' : 'Pilih Periode'}</option>
                            {
                                periodsData.list.map((item, i) => {
                                    return <option value={item.id} key={`periods-${i}`}>{item.id} ({item.month} - {item.year})</option>
                                })}
                        </Form.Control>
                        {
                            formik.errors.periode_id && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.periode_id}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group> */}
          {/* <Form.Group className="w-100 px-2 split-form">
                        <label className="form -control-label">Tanggal Pengiriman</label>
                        <Form.Control type="date" name="shipment_date" className="form-control" value={formik.values.shipment_date} disabled={action === 'view'}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.shipment_date && !!formik.errors.shipment_date}
                            isValid={!!formik.touched.shipment_date && !formik.errors.shipment_date}
                        ></Form.Control>
                        {
                            formik.errors.shipment_date && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.shipment_date}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group> */}
          <Form.Group className="w-100 pr-md-5 split-form">
            <label className="form -control-label">Tujuan</label>
            <Form.Control
              type="text"
              name="destination"
              className="form-control"
              value={formik.values.destination}
              disabled={action === "view"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.destination && !!formik.errors.destination
              }
              isValid={
                !!formik.touched.destination && !formik.errors.destination
              }></Form.Control>
            {formik.errors.destination && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.destination}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="w-100 pl-md-5 split-form">
            <label className="form -control-label">Latitude Tujuan</label>
            <Form.Control
              type="text"
              name="destination_latitude"
              className="form-control"
              value={formik.values.destination_latitude}
              disabled={action === "view"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.destination_latitude &&
                !!formik.errors.destination_latitude
              }
              isValid={
                !!formik.touched.destination_latitude &&
                !formik.errors.destination_latitude
              }></Form.Control>
            {formik.errors.destination_latitude && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.destination_latitude}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="w-100 pr-md-5 split-form">
            <label className="form -control-label">Longitude Tujuan</label>
            <Form.Control
              type="text"
              name="destination_longitude"
              className="form-control"
              value={formik.values.destination_longitude}
              disabled={action === "view"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.destination_longitude &&
                !!formik.errors.destination_longitude
              }
              isValid={
                !!formik.touched.destination_longitude &&
                !formik.errors.destination_longitude
              }></Form.Control>
            {formik.errors.destination_longitude && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.destination_longitude}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group
            style={{ display: action !== "view" && "none" }}
            className="w-100 pl-md-5 split-form">
            <label className="form -control-label">No. SO</label>
            <Form.Control
              type="text"
              name="no_so"
              className="form-control"
              value={formik.values.no_so}
              disabled={action === "view"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={!!formik.touched.no_so && !!formik.errors.no_so}
              isValid={
                !!formik.touched.no_so && !formik.errors.no_so
              }></Form.Control>
            {formik.errors.no_so && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.no_so}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group
            style={{ display: action !== "view" && "none" }}
            className="w-100 pr-md-5 split-form">
            <label className="form -control-label">No. DOK OUT</label>
            <Form.Control
              type="text"
              name="no_doc_out"
              className="form-control"
              value={formik.values.no_doc_out}
              disabled={action === "view"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.no_doc_out && !!formik.errors.no_doc_out
              }
              isValid={
                !!formik.touched.no_doc_out && !formik.errors.no_doc_out
              }></Form.Control>
            {formik.errors.no_doc_out && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.no_doc_out}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group
            style={{ display: action !== "view" && "none" }}
            className="w-100 pl-md-5 split-form">
            <label className="form -control-label">Verifikasi Dokumen</label>
            <div className="d-flex align-items-center">
              <Form.Control
                type="text"
                name="verif_documents"
                className="form-control w-100"
                value={formik.values.verif_documents}
                disabled={action === "view"}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.verif_documents &&
                  !!formik.errors.verif_documents
                }
                isValid={
                  !!formik.touched.verif_documents &&
                  !formik.errors.verif_documents
                }></Form.Control>
              {formik.errors.verif_documents && (
                <Form.Control.Feedback type="invalid">
                  {formik.errors.verif_documents}
                </Form.Control.Feedback>
              )}
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}

              {/* <a href="" className="ml-4 btn btn-primary" onClick={() => handleDownloadVerif(formik.values.verif_documents)}>Lihat</a> */}
              <button
                className="btn btn-primary ml-4"
                disabled={formik.values.verif_documents === null}
                onClick={() =>
                  handleDownloadVerif(formik.values.verif_documents)
                }>
                Lihat
              </button>
            </div>
          </Form.Group>
          <Form.Group
            style={{ display: action !== "view" && "none" }}
            className="w-100 pr-md-5 split-form">
            <label className="form -control-label">BAST QR Code</label>
            <div className="d-flex align-items-center">
              <Form.Control
                type="text"
                name="bast_code_qrcode"
                className="form-control w-100"
                value={formik.values.bast_code_qrcode}
                disabled={action === "view"}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.touched.bast_code_qrcode &&
                  !!formik.errors.bast_code_qrcode
                }
                isValid={
                  !!formik.touched.bast_code_qrcode &&
                  !formik.errors.bast_code_qrcode
                }></Form.Control>
              {formik.errors.bast_code_qrcode && (
                <Form.Control.Feedback type="invalid">
                  {formik.errors.bast_code_qrcode}
                </Form.Control.Feedback>
              )}
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}

              {/* <a href="" className="ml-4 btn btn-primary" onClick={() => handleDownloadVerif(formik.values.verif_documents)}>Lihat</a> */}
              <button
                className="btn btn-primary ml-4"
                disabled={formik.values.bast_code_qrcode === null}
                onClick={() =>
                  handleDownloadBastQrCode(formik.values.bast_code_qrcode)
                }>
                Lihat
              </button>
            </div>
          </Form.Group>
          {/* <Form.Group>
                        <label className="form -control-label">created_by</label>
                        <Form.Control type="text" name="created_by" className="form-control" value={formik.values.created_by}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.created_by && !!formik.errors.created_by}
                            isValid={!!formik.touched.created_by && !formik.errors.created_by}
                        ></Form.Control>
                        {
                            formik.errors.created_by && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.created_by}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group> */}
          {/* <Form.Group>
                        <label className="form -control-label">updated_by</label>
                        <Form.Control type="text" name="updated_by" className="form-control" value={formik.values.updated_by}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.updated_by && !!formik.errors.updated_by}
                            isValid={!!formik.touched.updated_by && !formik.errors.updated_by}
                        ></Form.Control>
                        {
                            formik.errors.updated_by && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.updated_by}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group> */}
          {/* <Form.Group>
                        <label className="form -control-label">upload_doc_by</label>
                        <Form.Control type="text" name="upload_doc_by" className="form-control" value={formik.values.upload_doc_by}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.upload_doc_by && !!formik.errors.upload_doc_by}
                            isValid={!!formik.touched.upload_doc_by && !formik.errors.upload_doc_by}
                        ></Form.Control>
                        {
                            formik.errors.upload_doc_by && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.upload_doc_by}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group> */}
          {/* <Form.Group>
                        <label className="form -control-label">photo_pickup</label>
                        <Form.Control type="text" name="photo_pickup" className="form-control" value={formik.values.photo_pickup}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.photo_pickup && !!formik.errors.photo_pickup}
                            isValid={!!formik.touched.photo_pickup && !formik.errors.photo_pickup}
                        ></Form.Control>
                        {
                            formik.errors.photo_pickup && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.photo_pickup}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group> */}
          {/* <Form.Group>
                        <label className="form -control-label">finish_by</label>
                        <Form.Control type="text" name="finish_by" className="form-control" value={formik.values.finish_by}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.finish_by && !!formik.errors.finish_by}
                            isValid={!!formik.touched.finish_by && !formik.errors.finish_by}
                        ></Form.Control>
                        {
                            formik.errors.finish_by && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.finish_by}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group> */}
          {/* <Form.Group>
                        <label className="form -control-label">created_at</label>
                        <Form.Control type="text" name="created_at" className="form-control" value={formik.values.created_at}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.created_at && !!formik.errors.created_at}
                            isValid={!!formik.touched.created_at && !formik.errors.created_at}
                        ></Form.Control>
                        {
                            formik.errors.created_at && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.created_at}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group> */}
          {/* <Form.Group>
                        <label className="form -control-label">updated_at</label>
                        <Form.Control type="text" name="updated_at" className="form-control" value={formik.values.updated_at}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.updated_at && !!formik.errors.updated_at}
                            isValid={!!formik.touched.updated_at && !formik.errors.updated_at}
                        ></Form.Control>
                        {
                            formik.errors.updated_at && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.updated_at}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group> */}
          {/* <Form.Group>
                        <label className="form -control-label">loading_at</label>
                        <Form.Control type="text" name="loading_at" className="form-control" value={formik.values.loading_at}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.loading_at && !!formik.errors.loading_at}
                            isValid={!!formik.touched.loading_at && !formik.errors.loading_at}
                        ></Form.Control>
                        {
                            formik.errors.loading_at && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.loading_at}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group> */}
          {/* <Form.Group>
                        <label className="form -control-label">loading_by</label>
                        <Form.Control type="text" name="loading_by" className="form-control" value={formik.values.loading_by}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.loading_by && !!formik.errors.loading_by}
                            isValid={!!formik.touched.loading_by && !formik.errors.loading_by}
                        ></Form.Control>
                        {
                            formik.errors.loading_by && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.loading_by}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group> */}
          <Form.Group className="w-100 pl-md-5 split-form">
            <label className="form -control-label">Kuantitas Paket</label>
            <Form.Control
              type="text"
              name="package_qty"
              className="form-control"
              value={formik.values.package_qty}
              disabled={action === "view"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.package_qty && !!formik.errors.package_qty
              }
              isValid={
                !!formik.touched.package_qty && !formik.errors.package_qty
              }></Form.Control>
            {formik.errors.package_qty && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.package_qty}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="w-100 pr-md-5 split-form">
            <label className="form -control-label">Catatan</label>
            <Form.Control
              type="text"
              name="notes"
              className="form-control"
              value={formik.values.notes}
              disabled={action === "view"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={!!formik.touched.notes && !!formik.errors.notes}
              isValid={
                !!formik.touched.notes && !formik.errors.notes
              }></Form.Control>
            {formik.errors.notes && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.notes}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group
            style={{ display: action !== "view" && "none" }}
            className="w-100 px-2 split-form">
            <label className="form -control-label">Kode BAST</label>
            <Form.Control
              type="text"
              name="bast_code"
              className="form-control"
              value={formik.values.bast_code}
              disabled={action === "view"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.bast_code && !!formik.errors.bast_code
              }
              isValid={
                !!formik.touched.bast_code && !formik.errors.bast_code
              }></Form.Control>
            {formik.errors.bast_code && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.bast_code}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          {/* <Form.Group style={{ display: action !== 'view' && 'none' }} className="w-100 px-2 split-form">
                        <label className="form -control-label">Kode TTB</label>
                        <Form.Control type="text" name="ttb_code" className="form-control" value={formik.values.ttb_code} disabled={action === 'view'}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.ttb_code && !!formik.errors.ttb_code}
                            isValid={!!formik.touched.ttb_code && !formik.errors.ttb_code}
                        ></Form.Control>
                        {
                            formik.errors.ttb_code && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.ttb_code}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group> */}
          <Form.Group className="w-100 px-2 split-form">
            <label className="form -control-label">Nama Pejabat</label>
            <Form.Control
              type="text"
              name="nama_pejabat"
              className="form-control"
              value={formik.values.nama_pejabat}
              disabled={action === "view"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.nama_pejabat && !!formik.errors.nama_pejabat
              }
              isValid={
                !!formik.touched.nama_pejabat && !formik.errors.nama_pejabat
              }></Form.Control>
            {formik.errors.nama_pejabat && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.nama_pejabat}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="w-100 px-2 split-form">
            <label className="form -control-label">Nama Pendamping</label>
            <Form.Control
              type="text"
              name="nama_pendamping"
              className="form-control"
              value={formik.values.nama_pendamping}
              disabled={action === "view"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.nama_pendamping &&
                !!formik.errors.nama_pendamping
              }
              isValid={
                !!formik.touched.nama_pendamping &&
                !formik.errors.nama_pendamping
              }></Form.Control>
            {formik.errors.nama_pendamping && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.nama_pendamping}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group
            className="w-100 px-2 split-form"
            style={{ display: action !== "view" && "none" }}>
            <label className="form -control-label">ID Provinsi</label>
            <Form.Control
              as="select"
              name="province_id"
              className="form-control"
              value={formik.values.province_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.province_id && !!formik.errors.province_id
              }
              isValid={
                !!formik.touched.province_id && !formik.errors.province_id
              }
              disabled={provincesLoading || action === "view"}>
              <option value={0}>
                {provincesLoading ? "Loading..." : "Pilih Provinsi"}
              </option>
              {provincesData.list.map((item, i) => {
                return (
                  <option value={item.province_id} key={`provinces-${i}`}>
                    {item.province_name}
                  </option>
                );
              })}
            </Form.Control>
            {formik.errors.province_id && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.province_id}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group
            className="w-100 px-2 split-form"
            style={{ display: action !== "view" && "none" }}>
            <label className="form -control-label">ID Kota</label>
            <Form.Control
              as="select"
              name="city_id"
              className="form-control"
              value={formik.values.city_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={!!formik.touched.city_id && !!formik.errors.city_id}
              isValid={!!formik.touched.city_id && !formik.errors.city_id}
              disabled={citiesLoading || action === "view"}>
              <option value={0}>
                {citiesLoading ? "Loading..." : "Pilih Kota"}
              </option>
              {citiesData.list.map((item, i) => {
                return (
                  <option value={item.city_id} key={`cities-${i}`}>
                    {item.city_name}
                  </option>
                );
              })}
            </Form.Control>
            {formik.errors.city_id && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.city_id}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group
            className="w-100 px-2 split-form"
            style={{ display: action !== "view" && "none" }}>
            <label className="form -control-label">ID Kecamatan</label>
            <Form.Control
              as="select"
              name="district_id"
              className="form-control"
              value={formik.values.district_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.district_id && !!formik.errors.district_id
              }
              isValid={
                !!formik.touched.district_id && !formik.errors.district_id
              }
              disabled={districtsLoading || action === "view"}>
              <option value={0}>
                {districtsLoading ? "Loading..." : "Pilih Kecamatan"}
              </option>
              {districtsData.list.map((item, i) => {
                return (
                  <option value={item.district_id} key={`districts-${i}`}>
                    {item.district_name}
                  </option>
                );
              })}
            </Form.Control>
            {formik.errors.district_id && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.district_id}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group
            className="w-100 px-2 split-form"
            style={{ display: action !== "view" && "none" }}>
            <label className="form -control-label">ID Kelurahan</label>
            <Form.Control
              as="select"
              name="subdistrict_id"
              className="form-control"
              value={formik.values.subdistrict_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.subdistrict_id &&
                !!formik.errors.subdistrict_id
              }
              isValid={
                !!formik.touched.subdistrict_id && !formik.errors.subdistrict_id
              }
              disabled={subdistrictsLoading || action === "view"}>
              <option value={0}>
                {subdistrictsLoading ? "Loading..." : "Pilih Kelurahan"}
              </option>
              {subdistrictsData.list.map((item, i) => {
                return (
                  <option value={item.subdistrict_id} key={`subdistricts-${i}`}>
                    {item.subdistrict_name}
                  </option>
                );
              })}
            </Form.Control>
            {formik.errors.subdistrict_id && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.subdistrict_id}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="w-100 px-2 split-form">
            <label className="form -control-label">Nama Supir</label>
            <Form.Control
              type="text"
              name="driver_name"
              className="form-control"
              value={formik.values.driver_name}
              disabled={action === "view"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.driver_name && !!formik.errors.driver_name
              }
              isValid={
                !!formik.touched.driver_name && !formik.errors.driver_name
              }></Form.Control>
            {formik.errors.driver_name && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.driver_name}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="w-100 px-2 split-form">
            <label className="form -control-label">No. Telp Supir</label>
            <Form.Control
              type="text"
              name="phone_number"
              className="form-control"
              value={formik.values.phone_number}
              disabled={action === "view"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.phone_number && !!formik.errors.phone_number
              }
              isValid={
                !!formik.touched.phone_number && !formik.errors.phone_number
              }></Form.Control>
            {formik.errors.phone_number && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.phone_number}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="w-100 px-2 split-form">
            <label className="form -control-label">Nama Kendaraan</label>
            <Form.Control
              type="text"
              name="vehicle_name"
              className="form-control"
              value={formik.values.vehicle_name}
              disabled={action === "view"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.vehicle_name && !!formik.errors.vehicle_name
              }
              isValid={
                !!formik.touched.vehicle_name && !formik.errors.vehicle_name
              }></Form.Control>
            {formik.errors.vehicle_name && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.vehicle_name}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="w-100 px-2 split-form">
            <label className="form -control-label">No. Pol. Kendaraan</label>
            <Form.Control
              type="text"
              name="plate_number"
              className="form-control"
              value={formik.values.plate_number}
              disabled={action === "view"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.plate_number && !!formik.errors.plate_number
              }
              isValid={
                !!formik.touched.plate_number && !formik.errors.plate_number
              }></Form.Control>
            {formik.errors.plate_number && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.plate_number}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="w-100 px-2 split-form">
            <label className="form -control-label">Nama Gudang</label>
            <Form.Control
              type="text"
              name="warehouse_name"
              className="form-control"
              value={formik.values.warehouse_name}
              disabled={action === "view"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.warehouse_name &&
                !!formik.errors.warehouse_name
              }
              isValid={
                !!formik.touched.warehouse_name && !formik.errors.warehouse_name
              }></Form.Control>
            {formik.errors.warehouse_name && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.warehouse_name}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          {/* <Form.Group className="w-100 px-2 split-form">
                        <label className="form -control-label">ID Dokumen</label>
                        <Form.Control as="select" name="document_id" className="form-control" value={formik.values.document_id} disabled={action === 'view' || bastLoading}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.document_id && !!formik.errors.document_id}
                            isValid={!!formik.touched.document_id && !formik.errors.document_id}
                        >
                            <option value={0}>{bastLoading ? 'Loading...' : 'Pilih PBP'}</option>
                            {
                                bastData.list.map((item, i) => {
                                    return <option value={item.document_id} key={`kpms-${i}`}>{item.document_id} - {item.city_name} - {item.district_name} - {item.subdistrict_name}</option>
                                })}
                        </Form.Control>
                        {
                            formik.errors.document_id && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.document_id}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group> */}
          {/* <Form.Group className="w-100 px-2 split-form">
                        <label className="form -control-label">Supir</label>

                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text id="basic-addon1"><i className="fas fa-search"></i></InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                placeholder="Cari Supir"
                                value={searchDriver}
                                onChange={handleSearchDriver}
                                disabled={driversLoading}
                            />
                        </InputGroup>
                        <Form.Control as="select" name="driver_id" className="form-control" value={formik.values.driver_id} disabled={action === 'view' || driversLoading}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.driver_id && !!formik.errors.driver_id}
                            isValid={!!formik.touched.driver_id && !formik.errors.driver_id}

                        >
                            <option value={0}>{driversLoading ? 'Loading...' : 'Pilih Supir'}</option>
                            {searchDriver ? filteredDrivers.map((item, i) => {
                                return <option value={item.id} key={`drivers-${i}`}>{item.name}</option>
                            }) : driversData.list.map((item, i) => {
                                return <option value={item.id} key={`drivers-${i}`}>{item.name}</option>
                            })}


                        </Form.Control>
                        {
                            formik.errors.driver_id && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.driver_id}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group className="w-100 px-2 split-form">
                        <label className="form -control-label">Kendaraan</label>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text id="basic-addon1"><i className="fas fa-search"></i></InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                placeholder="Cari Kendaraan"
                                value={searchVehicle}
                                onChange={handleSearchVehicle}
                                disabled={vehiclesLoading}
                            />
                        </InputGroup>
                        <Form.Control as="select" name="vehicle_id" className="form-control" value={formik.values.vehicle_id} disabled={action === 'view' || vehiclesLoading}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.vehicle_id && !!formik.errors.vehicle_id}
                            isValid={!!formik.touched.vehicle_id && !formik.errors.vehicle_id}
                        >
                            <option value={0}>{vehiclesLoading ? 'Loading...' : 'Pilih Kendaraan'}</option>
                            {searchVehicle ? filteredVehicles.map((item, i) => {
                                return <option value={item.id} key={`vehicles-${i}`}>{item.make}</option>
                            }) : vehiclesData.list.map((item, i) => {
                                return <option value={item.id} key={`vehicles-${i}`}>{item.make}</option>
                            })}
                        </Form.Control>
                        {
                            formik.errors.vehicle_id && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.vehicle_id}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group> */}

          <Form.Group className="w-100 px-2">
            {action !== "view" && (
              <Button
                type="submit"
                className="float-left mr-3"
                variant="primary"
                disabled={loading}>
                {loading && (
                  <Spinner
                    variant="light"
                    animation="border"
                    size="sm"
                    className="mr-1"></Spinner>
                )}
                Simpan
              </Button>
            )}
            <Button
              style={{ display: action === "view" && "none" }}
              className="float-left"
              variant="secondary"
              onClick={() => resetForm()}>
              Reset
            </Button>
          </Form.Group>
        </Form>
      </Card.Body>
    </Card>
  );
};
