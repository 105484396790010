import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  resetProvincesToInit,
  startLoadingProvinces,
  setProvincesList,
  stopLoadingProvinces,
  setProvincesMessage,
  resetCitiesToInit,
  startLoadingCities,
  setCitiesList,
  stopLoadingCities,
  setCitiesMessage,
  resetDistrictsToInit,
  startLoadingDistricts,
  setDistrictsList,
  stopLoadingDistricts,
  setDistrictsMessage,
  resetSubdistrictsToInit,
  startLoadingSubDistricts,
  setSubdistrictsList,
  stopLoadingSubDistricts,
  setSubdistrictsMessage,
  setPengirimanMessage,
} from "redux/actions";
import { RootState } from "redux/reducers";
import { getCities } from "services/citiesService";
import { getDistricts } from "services/districtsService";
import { getProvinces } from "services/provincesService";
import { getSubdistricts } from "services/subdistrictsService";
import { Constant } from "template/Constant";
import LogoBulog from "../../logos/logo-bulog.jpeg";
import LogoSBL from "../../logos/logo-sbl-logistic.png";
import { ListBNBA } from "./table/bnba";
import { useAppDispatch } from "redux/store";
type Props = {
  getData: (
    page,
    pageSize,
    searchKey,
    periode_id,
    date,
    province_id,
    city_id,
    district_id,
    subdistrict_id
  ) => void;
};
export const ShipmentsPrint: React.FC<Props> = ({ getData }) => {
  const provincesData = useSelector((state: RootState) => state.provinces);
  const citiesData = useSelector((state: RootState) => state.cities);
  const districtsData = useSelector((state: RootState) => state.districts);
  const subdistrictsData = useSelector(
    (state: RootState) => state.subdistricts
  );
  const provincesLoading = useSelector(
    (state: RootState) => state.provinces.loading
  );
  const citiesLoading = useSelector((state: RootState) => state.cities.loading);
  const districtsLoading = useSelector(
    (state: RootState) => state.districts.loading
  );
  const subdistrictsLoading = useSelector(
    (state: RootState) => state.subdistricts.loading
  );
  const [selectedProvince, setSelectedProvince] = useState(0);
  const [selectedCity, setSelectedCity] = useState(0);
  const [selectedDistrict, setSelectedDistrict] = useState(0);
  const [selectedSubdistrict, setSelectedSubdistrict] = useState(0);
  const periodsId = localStorage.getItem("periode_id");
  const provinceId = localStorage.getItem("province_id");
  const cityId = localStorage.getItem("city_id");
  const roles = localStorage.getItem("user");
  const provinceName = localStorage.getItem("province_name");
  const cityName = localStorage.getItem("city_name");
  const rData = useSelector((state: RootState) => state.pengiriman);
  const isLoading = useSelector((state: RootState) => state.pengiriman.loading);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const handleProvinceChange = (event) => {
    const newValue = event.target.value;
    setSelectedProvince(newValue);
  };

  const handleCityChange = (event) => {
    const newValue = event.target.value;
    setSelectedCity(newValue);
  };

  const handleDistrictChange = (event) => {
    const newValue = event.target.value;
    setSelectedDistrict(newValue);
  };

  const handleSubdistrictChange = (event) => {
    const newValue = event.target.value;
    setSelectedSubdistrict(newValue);
  };
  useEffect(() => {
    if (
      provincesData &&
      provincesData.list &&
      provincesData.list.length === 0 &&
      roles === "Super Admin"
    ) {
      dispatch(resetProvincesToInit());
      dispatch(startLoadingProvinces());
      getProvinces(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        ""
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setProvincesList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
          dispatch(stopLoadingProvinces());
        } else {
          dispatch(setProvincesMessage("Data kosong"));
          dispatch(stopLoadingProvinces());
        }
      });
    }
  }, []);
  useEffect(() => {
    if (selectedProvince) {
      dispatch(resetCitiesToInit());
      dispatch(startLoadingCities());
      getCities(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        "",
        selectedProvince
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setCitiesList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
          dispatch(stopLoadingCities());
        } else {
          dispatch(setCitiesMessage("Data kosong"));
          dispatch(stopLoadingCities());
        }
      });
    }
  }, [selectedProvince]);

  useEffect(() => {
    if (roles === "Super Admin") {
      if (selectedCity) {
        dispatch(resetDistrictsToInit());
        dispatch(startLoadingDistricts());
        getDistricts(
          Constant.defaultPageNumber,
          Constant.defaultDropdownPageSize,
          "",
          selectedCity
        ).then((response) => {
          if (response && response.records) {
            dispatch(
              setDistrictsList({
                pageNo: Constant.defaultPageNumber,
                pageSize: Constant.defaultDropdownPageSize,
                list: response.records,
                totalCount: response.total_count,
                searchKey: "",
              })
            );
            dispatch(stopLoadingDistricts());
          } else {
            dispatch(setDistrictsMessage("Data kosong"));
            dispatch(stopLoadingDistricts());
          }
        });
      }
    } else {
      dispatch(resetDistrictsToInit());
      dispatch(startLoadingDistricts());
      getDistricts(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        "",
        cityId
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setDistrictsList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
          dispatch(stopLoadingDistricts());
        } else {
          dispatch(setDistrictsMessage("Data kosong"));
          dispatch(stopLoadingDistricts());
        }
      });
    }
  }, [selectedCity]);

  useEffect(() => {
    if (selectedDistrict) {
      dispatch(resetSubdistrictsToInit());
      dispatch(startLoadingSubDistricts());
      getSubdistricts(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        "",
        selectedDistrict
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setSubdistrictsList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
          dispatch(stopLoadingSubDistricts());
        } else {
          dispatch(setSubdistrictsMessage("Data kosong"));
          dispatch(stopLoadingSubDistricts());
        }
      });
    }
  }, [selectedDistrict]);

  const handlePrintPDF = () => {
    if (!selectedDistrict && !selectedSubdistrict) {
      dispatch(setPengirimanMessage("Lengkapi data"));
    } else if (rData.list.length <= 0) {
      dispatch(setPengirimanMessage("Data kosong"));
    } else if (rData.list.length > 0) {
      dispatch(setPengirimanMessage(""));
      const doc = new jsPDF();
      const totalPagesExp = "{total_pages_count_string}";
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        autoTable(doc, {
          html: "#my-table",
          styles: {
            textColor: [0, 0, 0],
            cellPadding: 5,
            lineWidth: 0.1,
            lineColor: [0, 0, 0],
          },
          headStyles: { fillColor: [235, 195, 58], cellPadding: 3 },
          columnStyles: {
            1: { cellWidth: 50 }, // Atur lebar sel kolom pertama
            2: { cellWidth: 60, minCellHeight: 18 }, // Atur lebar sel kolom pertama
            3: { cellWidth: 30 }, // Atur lebar sel kolom pertama
          },

          willDrawPage: function (data) {
            doc.setFontSize(10);
            doc.setTextColor(40);
            doc.addImage(
              LogoBulog,
              "JPEG",
              data.settings.margin.left,
              10,
              30,
              10
            );
            doc.addImage(LogoSBL, "JPEG", 156, 5, 15, 12);
            doc.text(rData.list[0].bast_code ?? "-", 154, 22);
            doc.setFont("helvetica", "bold"); // Set the font style to bold
            doc.text("BERITA ACARA SERAH TERIMA (BAST)", 70, 30);
            doc.text("PENERIMA BANTUAN PANGAN - CBP 2023", 67.5, 35);
            doc.setFont("helvetica", "normal"); // Set the font style to bold
            doc.text("Nomor             : ", 67.5, 40);
            doc.text(
              `Alokasi Bulan  : ${
                rData.list[0].periods.month +
                  " " +
                  rData.list[0].periods.year ?? "-"
              }`,
              67.5,
              45
            );
            doc.text("PROVINSI", data.settings.margin.left, 55);
            doc.text(
              `: ${rData.list[0].provinces.province_name.toUpperCase() ?? "-"}`,
              50,
              55
            );
            doc.text("KABUPATEN", data.settings.margin.left, 60);
            doc.text(`: ${rData.list[0].cities.city_name.toUpperCase() ?? "-"}`, 50, 60);
            doc.text("KECAMATAN", data.settings.margin.left, 65);
            doc.text(
              `: ${rData.list[0].districts.district_name.toUpperCase() ?? "-"}`,
              50,
              65
            );
            doc.text("KELURAHAN/DESA", data.settings.margin.left, 70);
            doc.text(
              `: ${rData.list[0].subdistricts.subdistrict_name.toUpperCase() ?? "-"}`,
              50,
              70
            );
            doc.text(
              `Kami yang bertanda tangan pada daftar dibawah ini : Menyatakan dengan sebenar-benarnya bahwa telah menerima`,
              data.settings.margin.left,
              80
            );
            doc.text(
              `10 KG beras bantuan pangan – CBP 2023 dengan kualitas baik :`,
              data.settings.margin.left,
              85
            );
          },
          didDrawPage: function (data) {
            let str = "Halaman " + doc.getNumberOfPages();
            if (typeof doc.putTotalPages === "function") {
              str =
                str +
                " | " +
                totalPagesExp +
                ` ${rData?.list[0].subdistricts?.subdistrict_name}`;
            }
            doc.setFontSize(10);
            const pageSize = doc.internal.pageSize;
            const pageHeight = pageSize.height
              ? pageSize.height
              : pageSize.getHeight();
            doc.text(str, data.settings.margin.left, pageHeight - 10);
          },
          didDrawCell: function (data) {
            if (data.column.index === 3 && data.cell.section === "body") {
              const canvasId = `qr_${data.row.index}`;

              const canvas = document.getElementById(
                canvasId
              ) as HTMLCanvasElement;
              const jpeg = canvas.toDataURL("image/jpeg");
              var td = data.cell.raw;
              if (td instanceof HTMLTableCellElement) {
                var text = td.getElementsByTagName("span")[0];
                doc.addImage(
                  jpeg,
                  "JPEG",
                  data.cell.x + 9.5,
                  data.cell.y + 2,
                  10,
                  10
                );
                doc.setFontSize(6);
                doc.text(text.id, data.cell.x + 4, data.cell.y + 15);
              }
            }
          },

          margin: { top: 90 },
        });

        if (doc.getNumberOfPages() > 0) {
          doc.addPage();
          doc.setFontSize(10);
          doc.setTextColor(40);
          doc.addImage(LogoBulog, "JPEG", 14.111111111111109, 10, 30, 10);
          doc.addImage(LogoSBL, "JPEG", 156, 5, 15, 12);
          doc.text(rData.list[0].bast_code ?? "-", 154, 22);
          doc.setFont("helvetica", "bold"); // Set the font style to bold
          doc.text("BERITA ACARA SERAH TERIMA (BAST)", 70, 30);
          doc.text("PENERIMA BANTUAN PANGAN - CBP 2023", 67.5, 35);
          doc.setFont("helvetica", "normal"); // Set the font style to bold
          doc.text("Nomor             : ", 67.5, 40);
          doc.text(
            `Alokasi Bulan  : ${
              rData.list[0].periods.month + " " + rData.list[0].periods.year ??
              "-"
            }`,
            67.5,
            45
          );
          doc.text("PROVINSI", 14.111111111111109, 55);
          doc.text(`: ${rData.list[0].provinces.province_name.toUpperCase() ?? "-"}`, 50, 55);
          doc.text("KABUPATEN", 14.111111111111109, 60);
          doc.text(`: ${rData.list[0].cities.city_name.toUpperCase() ?? "-"}`, 50, 60);
          doc.text("KECAMATAN", 14.111111111111109, 65);
          doc.text(`: ${rData.list[0].districts.district_name.toUpperCase() ?? "-"}`, 50, 65);
          doc.text("KELURAHAN/DESA", 14.111111111111109, 70);
          doc.text(
            `: ${rData.list[0].subdistricts.subdistrict_name.toUpperCase() ?? "-"}`,
            50,
            70
          );
          doc.text(
            `Kami yang bertanda tangan pada daftar dibawah ini : Menyatakan dengan sebenar-benarnya bahwa telah menerima`,
            14.111111111111109,
            80
          );
          doc.text(
            `10 KG beras bantuan pangan – CBP 2023 dengan kualitas baik :`,
            14.111111111111109,
            85
          );
          doc.text(`..................,..............2023`, 155, 95);
          doc.text(`Mengetahui,`, 50, 105);
          doc.text(`Aparat setempat`, 47, 110);
          doc.text(`Yang menyerahkan,`, 125, 105);
          doc.text(`Transporter`, 132, 110);
          doc.text(`...............................................`, 119, 140);
          doc.text(
            `...............................................`,
            38.5,
            140
          );
          doc.text(`(Nama Jelas)`, 130, 145);
          doc.text(`(Nama Jelas, TTD dan Stempel)`, 36, 145);
          doc.text(`Keterangan :`, 14.111111111111109, 155);
          doc.text(
            `* Aparat Setempat adalah Pengurus RT/RW atau sebutan nama lainnya atau aparat kelurahan/desa atau perwakilan`,
            14.111111111111109,
            160
          );
          doc.text(`penerima bantuan pangan sasaran.`, 14.111111111111109, 165);
          doc.text(
            `Halaman ${doc.getNumberOfPages()} | ${doc.getNumberOfPages()} ${
              rData?.list[0].subdistricts?.subdistrict_name
            }`,
            14.111111111111109,
            297.0000833333333 - 10
          );
        }

        if (typeof doc.putTotalPages === "function") {
          doc.putTotalPages(totalPagesExp);
        }

        doc.save(
          `${rData?.list[0].subdistricts?.subdistrict_name} - ${rData?.list[0].districts?.district_name} - ${rData?.list[0].cities?.city_name}.pdf`
        );
      }, 1000);
    }
  };

  useEffect(() => {
    if (roles === "Super Admin") {
      if (
        selectedProvince &&
        selectedCity &&
        selectedDistrict &&
        selectedSubdistrict
      ) {
        getData(
          "",
          10000,
          "",
          periodsId,
          "",
          selectedProvince,
          selectedCity,
          selectedDistrict,
          selectedSubdistrict
        );
      }
    } else {
      if (selectedDistrict && !selectedSubdistrict) {
        getData(
          "",
          10000,
          "",
          periodsId,
          "",
          provinceId,
          cityId,
          selectedDistrict,
          selectedSubdistrict
        );
      }
    }
  }, [selectedProvince, selectedCity, selectedDistrict, selectedSubdistrict]);

  return (
    <Card className="mb-4" style={{ border: "none" }}>
      <Card.Header
        style={{ backgroundColor: "white", border: "none" }}
        className="p-0 px-3"
      >
        <h5 className="font-weight-bold">BNBA</h5>
        <h6
          className="m-0 font-weight-bold mb-2 mb-md-0"
          style={{ color: "black" }}
        >
          Cetak BNBA
        </h6>
      </Card.Header>
      <Card.Body className="">
        <hr className="m-0 mb-4" />
        <Row>
          <Col md={3}>
            <Form.Group controlId="exampleForm.SelectCustom">
              <Form.Control
                style={{ borderRadius: "20px" }}
                as="select"
                custom
                value={selectedProvince}
                disabled={roles !== "Super Admin"}
                onChange={handleProvinceChange}
              >
                <option value={0} disabled>
                  {provincesLoading
                    ? "Loading..."
                    : roles !== "Super Admin"
                    ? provinceName
                    : "Pilih Provinsi"}
                </option>
                {provincesData.list.map((item, i) => {
                  return (
                    <option value={item.province_id} key={`provinces-${i}`}>
                      {item.province_name}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
          </Col>

          <Col md={3}>
            <Form.Group controlId="exampleForm.SelectCustom">
              <Form.Control
                style={{ borderRadius: "20px" }}
                as="select"
                custom
                value={selectedCity}
                disabled={roles !== "Super Admin"}
                onChange={handleCityChange}
              >
                <option value={0} disabled>
                  {citiesLoading
                    ? "Loading..."
                    : roles !== "Super Admin"
                    ? cityName
                    : "Pilih Kota"}
                </option>
                {citiesData.list.map((item, i) => {
                  return (
                    <option value={item.city_id} key={`cities-${i}`}>
                      {item.city_name}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
          </Col>

          <Col md={3}>
            <Form.Group controlId="exampleForm.SelectCustom">
              <Form.Control
                style={{ borderRadius: "20px" }}
                as="select"
                custom
                value={selectedDistrict}
                onChange={handleDistrictChange}
              >
                <option value={0} disabled>
                  {districtsLoading ? "Loading..." : "Pilih Kecamatan"}
                </option>
                {districtsData.list.map((item, i) => {
                  return (
                    <option value={item.district_id} key={`districts-${i}`}>
                      {item.district_name}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
          </Col>

          <Col md={3}>
            <Form.Group controlId="exampleForm.SelectCustom">
              <Form.Control
                style={{ borderRadius: "20px" }}
                as="select"
                custom
                value={selectedSubdistrict}
                onChange={handleSubdistrictChange}
              >
                <option value={0} disabled>
                  {subdistrictsLoading ? "Loading..." : "Pilih Kelurahan"}
                </option>
                {subdistrictsData.list.map((item, i) => {
                  return (
                    <option
                      value={item.subdistrict_id}
                      key={`subdistricts-${i}`}
                    >
                      {item.subdistrict_name}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <ListBNBA data={rData.list} />
        <Button
          style={{ borderRadius: "20px" }}
          className="float-right"
          onClick={handlePrintPDF}
          disabled={loading}
        >
          {isLoading ||
            (loading && (
              <Spinner
                animation="border"
                variant="light"
                style={{
                  width: "15px",
                  height: "15px",
                  fontSize: "10px",
                }}
              ></Spinner>
            ))}{" "}
          Cetak
        </Button>
      </Card.Body>
    </Card>
  );
};
