const user = localStorage.getItem("user");
export const MenuItems = [
  {
    title: "Overview",
    path: "/dashboard",
    // icon: "fas fa-fw fa-chart-line",
    // logopath: '../../logos/logo-dashboard.svg',
    logopath: "../../logos/logo-chartpie.svg",
    hidden: user === "Admin / Juru Serah",
    subMenu: [],
  },
  {
    title: "PBP",
    path: "/pbp",
    icon: "fas fa-fw fa-box",
    logopath: "./../logos/pbp-logo.svg",
    hidden: user === "Admin / Juru Serah" || user === "BOD",
    subMenu: [
      {
        title: "Daftar PBP",
        path: "/pbp",
        icon: "fas fa-fw fa-table",
      },
      {
        title: "Upload Foto PBP",
        path: "/pbp/upload-images",
        icon: "fas fa-fw fa-upload",
      },
      // {
      //   title: "Tambah PBP",
      //   path: "/pbp/add",
      //   icon: "fas fa-fw fa-plus",
      // },
    ],
  },
  {
    title: "BNBA",
    path: "/bnba",
    icon: "fas fa-fw fa-truck-fast",
    logopath: "./../logos/bnba-logo.svg",
    hidden: user === "BOD",
    subMenu: [
      {
        title: "Daftar BNBA",
        path: "/bnba",
        icon: "fas fa-fw fa-table",
      },
      // {
      //   title: "Tambah BNBA",
      //   path: "/bnba/add",
      //   icon: "fas fa-fw fa-plus",
      // },
      {
        title: "Cetak BNBA",
        path: "/bnba/print",
        icon: "fas fa-fw fa-print",
      },
    ],
  },

  {
    title: "Surat Jalan",
    path: "/sj",
    icon: "fas fa-fw fa-boxes-stacked",
    logopath: "../../logos/sj-logo.svg",
    hidden: user === "BOD",
    subMenu: [
      {
        title: "Daftar Surat Jalan",
        path: "/sj",
        icon: "fas fa-fw fa-table",
      },
      // {
      //   title: "Tambah Surat Jalan",
      //   path: "/sj/add",
      //   icon: "fas fa-fw fa-plus",
      // },
    ],
  },
  {
    title: "Undangan",
    path: "/bnba",
    hidden: user === "BOD",
    logopath: "../../logos/undangan-logo.svg",
    icon: "fas fa-fw fa-envelope",
    subMenu: [
      {
        title: "Cetak Undangan",
        path: "/invitations",
        icon: "fas fa-fw fa-print",
      },
    ],
  },
  {
    title: "SPTJM",
    path: "/sptjm",
    icon: "fas fa-fw fa-user-astronaut",
    logopath: "./../logos/sptjm-logo.svg",
    hidden: user === "BOD",
    subMenu: [
      {
        title: "Daftar SPTJM",
        path: "/sptjm",
        icon: "fas fa-fw fa-table",
      },
      // {
      //   title: "Tambah STPJM",
      //   path: "/sptjm/add",
      //   icon: "fas fa-fw fa-plus",
      // },
    ],
  },
  {
    title: "Dashboard Foto",
    path: "/dashboard-images",
    icon: "fas fa-fw fa-images",
    logopath: "./../logos/photo-logo.svg",
    hidden: user === "Admin / Juru Serah" || user === "BOD",
    subMenu: [
      {
        title: "Daftar Foto",
        path: "/dashboard-images",
        icon: "fas fa-fw fa-images",
      },
    ],
  },

  {
    title: "Periode",
    path: "/periods",
    icon: "fas fa-fw fa-calendar",
    logopath: "./../logos/periode-logo.svg",
    hidden: user === "Admin / Juru Serah" || user === "BOD",
    subMenu: [
      {
        title: "Daftar Periode",
        path: "/periods",
        icon: "fas fa-fw fa-table",
      },
    ],
  },
  {
    title: "Pengguna",
    path: "/users",
    icon: "fas fa-fw fa-user",
    logopath: "./../logos/pengguna-logo.svg",
    hidden: user === "Admin / Juru Serah" || user === "BOD",
    subMenu: [
      {
        title: "Daftar Pengguna",
        path: "/users",
        icon: "fas fa-fw fa-table",
      },
    ],
  },

  {
    title: "Laporan",
    path: "/reports",
    icon: "fas fa-fw fa-scroll",
    logopath: "./../logos/laporan-logo.svg",
    hidden: user === "Admin / Juru Serah",
    subMenu: [
      {
        title: "Lihat Laporan",
        path: "/reports",
        icon: "fas fa-fw fa-table",
      },
    ],
  },
];
