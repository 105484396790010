import { APIService } from "services";

export const getWarehouses = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllWarehouses(pageNo,pageSize);
    }
    else{
        try {
            res = await searchWarehouses(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], total_count:0 }
        }
    }
    if(res && res.data && res.data.data){
    return { records:res.data.data.data, total_count:res.data.data.total }
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const addWarehouses = (data) => {
return APIService.api().post(`/warehouses`,data)
}
export const updateWarehouses = (data) => {
return APIService.api().put(`/warehouses/${data.id}`,data)
}
export const getAllWarehouses = (page,paginator) => {
return APIService.api().get(`/warehouses?page=${page}&paginator=${paginator}`)
}
export const getOneWarehouses = (id) => {
return APIService.api().get(`/warehouses/${id}`)
}
export const searchWarehouses = (searchKey,page,paginator) => {
return APIService.api().get(`/warehouses/search/${searchKey}/?page=${page}&paginator=${paginator}`)
}
export const deleteWarehouses = (id) => {
return APIService.api().delete(`/warehouses/${id}`)
}
