import React, { useEffect, useState } from "react";
// import { Alert, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { setReportsList, setReportsMessage } from "redux/actions";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { getAllReports } from "services/reportsService";
// import { getWarehouses } from "services/warehousesService";
import Layout from "template";
// import { Constant } from "template/Constant";
import { ReportsTable } from "./table";
import { RecapTable } from "./tableRecap";
import PaginationComponent from './pagination';
// import { WarehousesForm } from "./form";
// import { WarehousesTable } from "./table";

// type Props ={
//   section? : any;
// }

export const Reports: React.FC = () => {
  const dispatch = useAppDispatch();
  // const rData = useSelector((state: RootState) => state.reports);
  const [row, setRow] = useState(undefined);
  const [action, setAction] = useState('');
  //   const getData = () => {
  //     getAllReports('','',0,0,0).then((response) => {
  //       if (response) {
  //         dispatch(setReportsList({list: response.data.message, date : response.data.date}));
  //       } else {
  //         dispatch(setReportsMessage("No Record Found"));
  //       }
  //     })
  //   }
  // useEffect(() => {
  //   getData()
  // }, [])
  // const handleRowView = (rowData) => {
  //     setRow(rowData);
  //     dispatch(setShipmentsMessage(''));
  //     setAction('view');
  //   }
  //   const handleRowEdit = (rowData) => {
  //     setRow(rowData);
  //     dispatch(setWarehousesMessage(''));
  //     setAction('edit');
  //   }
  const rData = useSelector((state: RootState) => state.recap);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage] = useState<number>(10);
  const indexOfLastItem: number = currentPage * perPage;
  const indexOfFirstItem: number = indexOfLastItem - perPage;
  const currentData = rData.list.slice(indexOfFirstItem, indexOfLastItem);
  const onPageChange = (pageNumber: number): void => {
    setCurrentPage(pageNumber);
  }; return (
    <Layout>
      <div className="container-fluid">
        {/* <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Laporan</h1>
        </div> */}
        <div className="d-flex flex-column min-vh-100">
          {/* {rData.message ?
            <Alert variant={Constant.defaultAlertVarient} className="alert-dismissible fade">{rData.message}
              <Button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => dispatch(setWarehousesMessage(''))}>
                <span aria-hidden="true">&times;</span>
              </Button>
            </Alert> : null} */}
          {/* {section === 'add' ||  action ? <WarehousesForm hideShowForm={setAction} action={action || section} row={row} getData={getData} /> :
            <WarehousesTable handleRowEdit={handleRowEdit} hideShowForm={setAction} getData={getData} />} */}
          <ReportsTable/>
          {/* <RecapTable data={currentData} />
          <div className="d-flex mx-auto">
            <PaginationComponent
              currentPage={currentPage}
              totalPages={Math.ceil(rData.list.length / perPage)}
              onPageChange={onPageChange}
              limitPaginationButtons={5}
            /></div> */}
        </div>

      </div>
    </Layout >
  );
};

