import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface District {
    district_id: number;
    district_name: string;
}

export interface ISubdistricts {
    subdistrict_id: number;
    province_id: number;
    city_id: number;
    district_id: number;
    subdistrict_name: string;
    subdistrict_lat: null | number; // Replace 'null' with an appropriate type if you have more specific information
    subdistrict_lng: null | number; // Replace 'null' with an appropriate type if you have more specific information
    created_at: string;
    updated_at: string;
    districts: District;
}

interface ISubdistrictsData {
    list?: Array<ISubdistricts>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
    loading?:boolean
}

const initialState: ISubdistrictsData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
    loading:false
};

const subdistrictsSlice = createSlice({
    name: "subdistricts",
    initialState,
    reducers: {
        setSubdistrictsList: (state, _action: PayloadAction<ISubdistrictsData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetSubdistrictsToInit: (state) => {
            state = initialState;
        },
        setSubdistrictsMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
        startLoadingSubDistricts: (state) => {
            state.loading = true
        },
        stopLoadingSubDistricts: (state) => {
            state.loading = false
        }
    },
});

export const { setSubdistrictsList, resetSubdistrictsToInit, setSubdistrictsMessage, startLoadingSubDistricts, stopLoadingSubDistricts } = subdistrictsSlice.actions;

export default subdistrictsSlice.reducer;

