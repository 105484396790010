import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Alert, Button, Card, Col, Form, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import {
  resetBastToInit,
  resetCitiesToInit,
  resetDistrictsToInit,
  resetPeriodsToInit,
  resetProvincesToInit,
  resetSubdistrictsToInit,
  setBastList,
  setBastMessage,
  setCitiesList,
  setCitiesMessage,
  setDistrictsList,
  setDistrictsMessage,
  setPeriodsList,
  setPeriodsMessage,
  setProvincesList,
  setProvincesMessage,
  setShipmentsMessage,
  setSubdistrictsList,
  setSubdistrictsMessage,
  startLoadingBast,
  startLoadingCities,
  startLoadingDistricts,
  startLoadingPeriods,
  startLoadingProvinces,
  startLoadingSubDistricts,
  stopLoadingBast,
  stopLoadingCities,
  stopLoadingDistricts,
  stopLoadingPeriods,
  stopLoadingProvinces,
  stopLoadingSubDistricts,
} from "redux/actions";

import { useAppDispatch } from "redux/store";
import { addShipments, updateShipments } from "services/shipmentsService";
import { Constant } from "template/Constant";
import * as yup from "yup";
import { useNavigate } from "react-router";
import { uploadFileService } from "services/fileUploadService";
import { getProvinces } from "services/provincesService";
import { getPeriods } from "services/periodsService";
import { getAllBast } from "services/bastService";
import { getDistricts } from "services/districtsService";
import { getSubdistricts } from "services/subdistrictsService";
import { getCities } from "services/citiesService";
type Props = {
  row?: any;
  hideShowForm: (actionName) => void;
  getData: (
    page,
    pageSize,
    searchKey,
    periode_id,
    date,
    province_id,
    city_id,
    district_id,
    subdistrict_id
  ) => void;
  action?: string;
  isFiltered?: any;
};
export const SjForm: React.FC<Props> = ({
  row,
  hideShowForm,
  getData,
  action,
  isFiltered,
}) => {
  const dispatch = useAppDispatch();
  const iValue = {
    shipment_date: "",
    package_qty: "",
    province_id: "",
    city_id: "",
    district_id: "",
    subdistrict_id: "",
    driver_name: "",
    phone_number: "",
    plate_number: "",
  };
  const initialValue = action === "edit" || action === "view" ? row : iValue;
  const kpmsData = useSelector((state: RootState) => state.kpms);
  const driversData = useSelector((state: RootState) => state.drivers);
  const vehiclesData = useSelector((state: RootState) => state.vehicles);
  const bastData = useSelector((state: RootState) => state.bast);
  const driversLoading = useSelector(
    (state: RootState) => state.drivers.loading
  );
  const vehiclesLoading = useSelector(
    (state: RootState) => state.vehicles.loading
  );
  const bastLoading = useSelector((state: RootState) => state.bast.loading);
  const provincesData = useSelector((state: RootState) => state.provinces);
  const citiesData = useSelector((state: RootState) => state.cities);
  const districtsData = useSelector((state: RootState) => state.districts);
  const subdistrictsData = useSelector(
    (state: RootState) => state.subdistricts
  );
  const provincesLoading = useSelector(
    (state: RootState) => state.provinces.loading
  );
  const citiesLoading = useSelector((state: RootState) => state.cities.loading);
  const districtsLoading = useSelector(
    (state: RootState) => state.districts.loading
  );
  const subdistrictsLoading = useSelector(
    (state: RootState) => state.subdistricts.loading
  );
  const periodsData = useSelector((state: RootState) => state.periods);
  const periodsLoading = useSelector(
    (state: RootState) => state.periods.loading
  );
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const periodsId = localStorage.getItem("periode_id");
  const provinceId = localStorage.getItem("province_id");
  const cityId = localStorage.getItem("city_id");
  const districtId = localStorage.getItem("district_id");
  const subdistrictId = localStorage.getItem("subdistrict_id");
  useEffect(() => {
    if (periodsData && periodsData.list && periodsData.list.length === 0) {
      dispatch(resetPeriodsToInit());
      dispatch(startLoadingPeriods());
      getPeriods(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        ""
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setPeriodsList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
          dispatch(stopLoadingPeriods());
        } else {
          dispatch(setPeriodsMessage("Data kosong"));
          dispatch(stopLoadingPeriods());
        }
      });
    }
    if (
      provincesData &&
      provincesData.list &&
      provincesData.list.length === 0
    ) {
      dispatch(resetProvincesToInit());
      dispatch(startLoadingProvinces());
      getProvinces(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        ""
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setProvincesList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
          dispatch(stopLoadingProvinces());
        } else {
          dispatch(setProvincesMessage("Data kosong"));
          dispatch(stopLoadingProvinces());
        }
      });
    }
    // if (driversData && driversData.list && driversData.list.length === 0) {
    //     dispatch(resetDriversToInit());
    //     dispatch(startLoadingDrivers())
    //     getDrivers(Constant.defaultPageNumber, 1000, '').then((response) => {
    //         if (response && response.records) {
    //             dispatch(setDriversList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
    //             dispatch(stopLoadingDrivers())
    //         } else {
    //             dispatch(setDriversMessage("Data kosong"));
    //             dispatch(stopLoadingDrivers())
    //         }
    //     })
    // }
    // if (vehiclesData && vehiclesData.list && vehiclesData.list.length === 0) {
    //     dispatch(resetVehiclesToInit());
    //     dispatch(startLoadingVehicles())
    //     getVehicles(Constant.defaultPageNumber, 1000, '').then((response) => {
    //         if (response && response.records) {
    //             dispatch(setVehiclesList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
    //             dispatch(stopLoadingVehicles())
    //         } else {
    //             dispatch(setVehiclesMessage("Data kosong"));
    //             dispatch(stopLoadingVehicles())
    //         }
    //     })
    // }
  }, []);
  const formik = useFormik({
    initialValues: initialValue,
    onSubmit: async (values) => {
      setLoading(true);
      if (action === "edit") {
        const result = {
          id: values.id,
          province_id: values.province_id,
          city_id: values.city_id,
          district_id: values.district_id,
          subdistrict_id: values.subdistrict_id,
          shipment_date: values.shipment_date,
          destination: values.destination,
          package_qty: values.package_qty,
          driver_name: values.driver_name,
          phone_number: values.phone_number,
          plate_number: values.plate_number,
        };
        updateShipments(result)
          .then((response: any) => {
            if (response && response.data && response.data.code === 1) {
              setLoading(false);
              dispatch(setShipmentsMessage("Berhasil mengubah data"));
              getData(
                Constant.defaultPageNumber,
                Constant.defaultPageSize,
                "",
                periodsId,
                "",
                isFiltered.isProvinceSelected ? row?.provinces?.province_id : 0,
                isFiltered.isCitySelected ? row?.cities?.city_id : 0,
                isFiltered.isDistrictSelected ? row?.districts?.district_id : 0,
                isFiltered.isSubDistrictSelected
                  ? row?.subdistricts?.subdistrict_id
                  : 0
              );
              hideShowForm("");
              navigate("/sj", { state: { isFiltered: isFiltered, row: row } });
            } else {
              dispatch(setShipmentsMessage("Terjadi kesalahan"));
              setLoading(false);
            }
          })
          .catch((error) => {
            setLoading(false);
            formik.resetForm();
            dispatch(setShipmentsMessage("Terjadi kesalahan"));
          });
      } else if (action === "add") {
        const result = {
          periode_id: periodsId,
          province_id: provinceId,
          city_id: cityId,
          district_id: districtId,
          subdistrict_id: subdistrictId,
          shipment_date: values.shipment_date,
          destination: values.destination,
          destination_latitude: values.destination_latitude,
          destination_longitude: values.destination_longitude,
          package_qty: values.package_qty,
          notes: values.notes,
          nama_pejabat: values.nama_pejabat,
          nama_pendamping: values.nama_pendamping,
          driver_name: values.driver_name,
          phone_number: values.phone_number,
          vehicle_name: values.vehicle_name,
          plate_number: values.plate_number,
          warehouse_name: values.warehouse_name,
        };
        addShipments(result)
          .then((response: any) => {
            if (response && response.data && response.data.code === 1) {
              setLoading(false);
              dispatch(setShipmentsMessage("Berhasil menambah data"));
              getData(
                Constant.defaultPageNumber,
                Constant.defaultPageSize,
                "",
                periodsId,
                "",
                provinceId,
                cityId,
                districtId,
                subdistrictId
              );
              hideShowForm("");
            } else {
              dispatch(setShipmentsMessage("Terjadi kesalahan"));
              setLoading(false);
            }
          })
          .catch((error) => {
            setLoading(false);
            formik.resetForm();
            dispatch(setShipmentsMessage("Terjadi kesalahan"));
          });
      }
    },
    validationSchema: yup.object({
      shipment_date: yup
        .date()
        .nullable()
        .required("Tanggal Pengiriman wajib diisi"),
      destination: yup.string().nullable().required("Tujuan wajib diisi"),
      package_qty: yup
        .string()
        .nullable()
        .required("Kuantitas Paket wajib diisi"),
      province_id: yup.string().nullable().required("ID Provinsi wajib diisi"),
      city_id: yup.string().nullable().required("ID Kota wajib diisi"),
      district_id: yup.string().nullable().required("ID Kecamatan wajib diisi"),
      subdistrict_id: yup
        .string()
        .nullable()
        .required("ID Kelurahan wajib diisi"),
      driver_name: yup.string().nullable().required("Nama supir wajib diisi"),
      phone_number: yup
        .string()
        .nullable()
        .required("No. Telp Supir wajib diisi"),
      plate_number: yup
        .string()
        .nullable()
        .required("No. Pol. Kendaraan wajib diisi"),
      nama_korlap: yup.string().nullable(),
    }),
  });
  const formikFile = useFormik({
    initialValues: { fileData: [] },
    onSubmit: async (values) => {
      var formData = new FormData();
      formData.append("file", values.fileData[0]);
      formData.append("type", "shipment");
      formData.append("id", row.id);
      formData.append("description", "document");
      uploadFileService(formData).then((res: any) => {
        if (res && res.data && res.data.success) {
          setMessage("Dokumen berhasil diunggah : " + res.data.file);
          dispatch(setShipmentsMessage("Dokumen berhasil diunggah"));
          hideShowForm("");
          formikFile.resetForm();
          navigate("/shipments");
        } else {
          setMessage("Gagal mengunggah dokumen");
          formikFile.resetForm();
        }
      });
    },
    validationSchema: yup.object({
      fileData: yup.array().min(1, "Pilih minimal 1 file"),
    }),
  });
  const handleDownloadVerif = (value) => {
    window.open(value, "_blank", "noopener,noreferrer");
  };
  const resetForm = () => {
    formik.resetForm();
  };
  const formikProvinceId = formik.values.province_id;
  const formikCityId = formik.values.city_id;
  const formikDistrictId = formik.values.district_id;
  const formikSubdistrictId = formik.values.subdistrict_id;

  useEffect(() => {
    if (formikProvinceId) {
      dispatch(startLoadingCities());
      getCities(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        "",
        formikProvinceId
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setCitiesList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
          dispatch(stopLoadingCities());
          dispatch(resetCitiesToInit());
        } else {
          dispatch(setCitiesMessage("Data kosong"));
          dispatch(stopLoadingCities());
          dispatch(resetCitiesToInit());
        }
      });
    }
  }, [formikProvinceId]);

  useEffect(() => {
    if (formikCityId) {
      dispatch(resetDistrictsToInit());
      dispatch(startLoadingDistricts());
      getDistricts(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        "",
        formikCityId
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setDistrictsList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
          dispatch(stopLoadingDistricts());
        } else {
          dispatch(setDistrictsMessage("Data kosong"));
          dispatch(stopLoadingDistricts());
        }
      });
    }
  }, [formikCityId]);

  useEffect(() => {
    if (formikDistrictId) {
      dispatch(resetSubdistrictsToInit());
      dispatch(startLoadingSubDistricts());
      getSubdistricts(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        "",
        formikDistrictId
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setSubdistrictsList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
          dispatch(stopLoadingSubDistricts());
        } else {
          dispatch(setSubdistrictsMessage("Data kosong"));
          dispatch(stopLoadingSubDistricts());
        }
      });
    }
  }, [formikDistrictId]);

  const [searchDriver, setSearchDriver] = useState("");
  const [filteredDrivers, setFilteredDrivers] = useState(driversData.list);

  const handleSearchDriver = (e) => {
    const searchValue = e.target.value;
    setSearchDriver(searchValue);
    const filtered = driversData.list.filter((item) =>
      item.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredDrivers(filtered);
  };

  const [searchVehicle, setSearchVehicle] = useState("");
  const [filteredVehicles, setFilteredVehicles] = useState(vehiclesData.list);

  const handleSearchVehicle = (e) => {
    const searchValue = e.target.value;
    setSearchVehicle(searchValue);
    const filtered = vehiclesData.list.filter((item) =>
      item.make.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredVehicles(filtered);
  };
  const handleDownloadShipmentQrCode = (value) => {
    window.open(value, "_blank", "noopener,noreferrer");
  };
  return (
    <Card className="shadow mb-4">
      <Card.Header className="py-3">
        <h6 className="m-0 font-weight-bold text-primary text-capitalize">
          {action === "view" ? "Lihat" : action === "add" ? "Tambah" : "Ubah"}{" "}
          Surat Jalan{" "}
        </h6>
      </Card.Header>
      <Card.Body>
        {message ? (
          <Col md={12}>
            <Alert variant="info">{message}</Alert>
          </Col>
        ) : null}

        <Form onSubmit={formik.handleSubmit} className="d-flex w-100 flex-wrap">
          <Form.Group className="w-100 px-2 split-form">
            <label className="form -control-label">Tanggal Pengiriman</label>
            <Form.Control
              type="date"
              name="shipment_date"
              className="form-control"
              value={formik.values.shipment_date}
              disabled={action === "view"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.shipment_date && !!formik.errors.shipment_date
              }
              isValid={
                !!formik.touched.shipment_date && !formik.errors.shipment_date
              }></Form.Control>
            {formik.errors.shipment_date && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.shipment_date}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}

          <Form.Group className="w-100 px-2 split-form">
            <label className="form -control-label">Kuantitas Paket</label>
            <Form.Control
              type="text"
              name="package_qty"
              className="form-control"
              value={formik.values.package_qty}
              disabled={action === "view"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.package_qty && !!formik.errors.package_qty
              }
              isValid={
                !!formik.touched.package_qty && !formik.errors.package_qty
              }></Form.Control>
            {formik.errors.package_qty && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.package_qty}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group className="w-100 px-2 split-form">
            <label className="form -control-label">ID Provinsi</label>
            <Form.Control
              as="select"
              name="province_id"
              className="form-control"
              value={formik.values.province_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.province_id && !!formik.errors.province_id
              }
              isValid={
                !!formik.touched.province_id && !formik.errors.province_id
              }
              disabled={provincesLoading || action === "view"}>
              <option value={0}>
                {provincesLoading ? "Loading..." : "Pilih Provinsi"}
              </option>
              {provincesData.list.map((item, i) => {
                return (
                  <option value={item.province_id} key={`provinces-${i}`}>
                    {item.province_name}
                  </option>
                );
              })}
            </Form.Control>
            {formik.errors.province_id && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.province_id}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="w-100 px-2 split-form">
            <label className="form -control-label">ID Kota</label>
            <Form.Control
              as="select"
              name="city_id"
              className="form-control"
              value={formik.values.city_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={!!formik.touched.city_id && !!formik.errors.city_id}
              isValid={!!formik.touched.city_id && !formik.errors.city_id}
              disabled={citiesLoading || action === "view"}>
              <option value={0}>
                {citiesLoading ? "Loading..." : "Pilih Kota"}
              </option>
              {citiesData.list.map((item, i) => {
                return (
                  <option value={item.city_id} key={`cities-${i}`}>
                    {item.city_name}
                  </option>
                );
              })}
            </Form.Control>
            {formik.errors.city_id && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.city_id}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="w-100 px-2 split-form">
            <label className="form -control-label">ID Kecamatan</label>
            <Form.Control
              as="select"
              name="district_id"
              className="form-control"
              value={formik.values.district_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.district_id && !!formik.errors.district_id
              }
              isValid={
                !!formik.touched.district_id && !formik.errors.district_id
              }
              disabled={districtsLoading || action === "view"}>
              <option value={0}>
                {districtsLoading ? "Loading..." : "Pilih Kecamatan"}
              </option>
              {districtsData.list.map((item, i) => {
                return (
                  <option value={item.district_id} key={`districts-${i}`}>
                    {item.district_name}
                  </option>
                );
              })}
            </Form.Control>
            {formik.errors.district_id && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.district_id}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="w-100 px-2 split-form">
            <label className="form -control-label">ID Kelurahan</label>
            <Form.Control
              as="select"
              name="subdistrict_id"
              className="form-control"
              value={formik.values.subdistrict_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.subdistrict_id &&
                !!formik.errors.subdistrict_id
              }
              isValid={
                !!formik.touched.subdistrict_id && !formik.errors.subdistrict_id
              }
              disabled={subdistrictsLoading || action === "view"}>
              <option value={0}>
                {subdistrictsLoading ? "Loading..." : "Pilih Kelurahan"}
              </option>
              {subdistrictsData.list.map((item, i) => {
                return (
                  <option value={item.subdistrict_id} key={`subdistricts-${i}`}>
                    {item.subdistrict_name}
                  </option>
                );
              })}
            </Form.Control>
            {formik.errors.subdistrict_id && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.subdistrict_id}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="w-100 px-2 split-form">
            <label className="form -control-label">Nama Supir</label>
            <Form.Control
              type="text"
              name="driver_name"
              className="form-control"
              value={formik.values.driver_name}
              disabled={action === "view"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.driver_name && !!formik.errors.driver_name
              }
              isValid={
                !!formik.touched.driver_name && !formik.errors.driver_name
              }></Form.Control>
            {formik.errors.driver_name && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.driver_name}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="w-100 px-2 split-form">
            <label className="form -control-label">No. Telp Supir</label>
            <Form.Control
              type="text"
              name="phone_number"
              className="form-control"
              value={formik.values.phone_number}
              disabled={action === "view"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.phone_number && !!formik.errors.phone_number
              }
              isValid={
                !!formik.touched.phone_number && !formik.errors.phone_number
              }></Form.Control>
            {formik.errors.phone_number && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.phone_number}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group className="w-100 px-2 split-form">
            <label className="form -control-label">No. Pol. Kendaraan</label>
            <Form.Control
              type="text"
              name="plate_number"
              className="form-control"
              value={formik.values.plate_number}
              disabled={action === "view"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.plate_number && !!formik.errors.plate_number
              }
              isValid={
                !!formik.touched.plate_number && !formik.errors.plate_number
              }></Form.Control>
            {formik.errors.plate_number && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.plate_number}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="w-100 px-2 split-form">
            <label className="form -control-label">Nama Korlap</label>
            <Form.Control
              type="text"
              name="nama_korlap"
              className="form-control"
              value={formik.values.nama_korlap}
              disabled={action === "view"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.nama_korlap && !!formik.errors.nama_korlap
              }
              isValid={
                !!formik.touched.nama_korlap && !formik.errors.nama_korlap
              }></Form.Control>
            {formik.errors.nama_korlap && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.nama_korlap}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group className="w-100 px-2">
            {action !== "view" && (
              <Button
                type="submit"
                className="float-left mr-3"
                variant="primary"
                disabled={loading}>
                {loading && (
                  <Spinner
                    variant="light"
                    animation="border"
                    size="sm"
                    className="mr-1"></Spinner>
                )}
                Simpan
              </Button>
            )}
            <Button
              style={{ display: action === "view" && "none" }}
              className="float-left"
              variant="secondary"
              onClick={() => resetForm()}>
              Reset
            </Button>
          </Form.Group>
        </Form>
      </Card.Body>
    </Card>
  );
};
