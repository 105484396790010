import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IUsers {
    id: number,
    warehouse_id?: number,
    email: string,
    password: string,
    levels?: string,
    remember_token?: string,
    created_at?: Date,
    updated_at?: Date
}

interface IUsersData {
    list?: Array<IUsers>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
    loading?: boolean;
    lastPage?: number;
}

const initialState: IUsersData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
    loading: false,
    lastPage: 0
};

const usersSlice = createSlice({
    name: "users",
    initialState,
    reducers: {
        setUsersList: (state, _action: PayloadAction<IUsersData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
            state.lastPage = _action.payload.lastPage;
        },
        resetUsersToInit: (state) => {
            state = initialState;
        },
        setUsersMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
        startLoadingUsers: (state) => {
            state.loading = true
        },
        stopLoadingUsers: (state) => {
            state.loading = false
        }
    },
});

export const { setUsersList, resetUsersToInit, setUsersMessage, startLoadingUsers, stopLoadingUsers } = usersSlice.actions;

export default usersSlice.reducer;

