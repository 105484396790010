import React from "react";
interface Props {
  bastData: any;
}
export const ComponentSj = React.forwardRef<HTMLInputElement, Props>(
  (props, ref) => {
    const { bastData } = props;
    return (
      <>
        {bastData && bastData.pbps && (
          <div ref={ref} style={{ padding: 50 }}>
            <div
              className=""
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <img
                src="../logos/logo-bulog.jpeg"
                alt=""
                width={350}
                height={110}
              />
              <img src="../logos/logo-sbl-logistic.png" alt="" width={120} />
            </div>
            <div
              className=""
              style={{
                border: "1px solid black",
                marginBottom: 50,
                marginTop: 10,
              }}
            ></div>
            <div
              className=""
              style={{ textAlign: "center", marginBottom: 30, color: "black" }}
            >
              <h2 style={{ fontWeight: 700, lineHeight: 1 }}>SURAT JALAN</h2>
              <h2 style={{ fontWeight: 700, lineHeight: 1 }}>
                BANTUAN PANGAN CADANGAN BERAS PEMERINTAH
              </h2>
              <h2 style={{ fontWeight: 700, lineHeight: 1 }}>
                (BANTUAN PANGAN CBP)
              </h2>

              <h2 style={{ fontWeight: 700, lineHeight: 1 }}>
                <span style={{ backgroundColor: "#F2BE22" }}>
                  ALOKASI : {bastData?.periods?.month?.toUpperCase()}{" "}
                  {bastData?.periods?.year}
                </span>
              </h2>
            </div>
            <div className="" style={{ display: "flex" }}>
              <table
                className="table table-bordered"
                style={{ width: "50%", color: "black" }}
              >
                <tbody>
                  <tr style={{ backgroundColor: "#F2BE22" }}>
                    <td style={{ borderColor: "black" }}>
                      <h4 style={{ fontWeight: 700 }}>SURAT JALAN</h4>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: 700, borderColor: "black" }}>
                      PROVINSI :{" "}
                      {bastData?.provinces?.province_name?.toUpperCase()}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: 700, borderColor: "black" }}>
                      KEC : {bastData?.districts?.district_name?.toUpperCase()}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: 700, borderColor: "black" }}>
                      NO. SO : {bastData?.no_so}
                    </td>
                  </tr>
                </tbody>
              </table>
              <table
                className="table table-bordered"
                style={{ width: "50%", color: "black" }}
              >
                <tbody>
                  <tr style={{ backgroundColor: "#F2BE22" }}>
                    <td
                      style={{
                        fontWeight: 700,
                        textTransform: "uppercase",
                        borderColor: "black",
                      }}
                    >
                      <h4
                        style={{
                          fontWeight: 700,
                          textTransform: "uppercase",
                          borderColor: "black",
                        }}
                      >
                        DOKUMEN
                      </h4>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: 700,
                        textTransform: "uppercase",
                        borderColor: "black",
                      }}
                    >
                      KOTA : {bastData?.cities?.city_name?.toUpperCase()}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: 700,
                        textTransform: "uppercase",
                        borderColor: "black",
                      }}
                    >
                      KEL :{" "}
                      {bastData?.subdistricts?.subdistrict_name?.toUpperCase()}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: 700,
                        textTransform: "uppercase",
                        borderColor: "black",
                      }}
                    >
                      NO. DOK OUT : {bastData?.no_doc_out}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="" style={{ display: "flex", marginTop: 40 }}>
              {/* <img
                src={bastData?.bast_code_qrcode}
                alt=""
                style={{ objectFit: "contain", width: "250px" }}
              /> */}
              <table
                className="table table-bordered"
                style={{ height: 80, color: "black" }}
              >
                <tbody>
                  {/* <tr>
                    <td>No. Antrian</td>
                    <td>{bastData?.queue_number}</td>
                  </tr> */}
                  {/* <tr>
                  <td>Periode</td>
                  <td>{bastData?.periods?.tahap_name}</td>
                </tr> */}
                  <tr>
                    <td style={{ borderColor: "black" }}>Dibuat Oleh</td>
                    <td style={{ borderColor: "black" }}>
                      {bastData?.created_by}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ borderColor: "black" }}>Tanggal</td>
                    <td style={{ borderColor: "black" }}>
                      {bastData?.shipment_date}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ borderColor: "black" }}>No. Pol. Kendaraan</td>
                    <td style={{ borderColor: "black" }}>
                      {bastData?.plate_number}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ borderColor: "black" }}>Nama Supir</td>
                    <td style={{ borderColor: "black" }}>
                      {bastData?.driver_name}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ borderColor: "black" }}>No. HP Supir</td>
                    <td style={{ borderColor: "black" }}>
                      {bastData?.phone_number}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ borderColor: "black" }}>Jumlah Paket</td>
                    <td style={{ borderColor: "black" }}>
                      {bastData?.package_qty}
                    </td>
                  </tr>
                </tbody>
              </table>
              {/* <img
              src={bastData?.bast_code_qrcode}
              alt=""
              style={{ objectFit: "contain", width: '250px' }}
            /> */}
            </div>
            {/* <div className="">
            <p>Catatan:</p>
            <div className="" style={{ border: "1px solid black" }}>
              <p style={{ margin: "0px 10px 0px 10px" }}>{bastData?.notes}</p>
            </div>
          </div> */}
            <div className="" style={{ marginTop: 50 }}>
              <table
                className="table table-bordered"
                style={{ height: 200, marginBottom: 0, color: "black" }}
              >
                <tr style={{ textAlign: "center" }}>
                  <td style={{ width: 300, borderColor: "black" }}>Supir</td>
                  <td style={{ width: 300, borderColor: "black" }}>
                    Arko/Korlap
                  </td>
                  <td style={{ width: 300, borderColor: "black" }}>
                    Aparat Setempat
                  </td>
                </tr>
                <tr style={{ textAlign: "center", borderTopStyle: "hidden" }}>
                  <td style={{ height: 10, borderColor: "black" }}>
                    <br />
                    <p>(...........................)</p>
                  </td>
                  <td style={{ height: 10, borderColor: "black" }}>
                    <br />
                    <p>(...........................)</p>
                  </td>
                  <td style={{ height: 10, borderColor: "black" }}>
                    <br />
                    <p>(...........................)</p>
                  </td>
                </tr>
              </table>
              <p style={{ marginTop: 20, color: "black" }}>
                Note:{" "}
                <span style={{ marginLeft: 70 }}>
                  Sopir truk paket kiriman bertanggung jawab penuh atas keutuhan
                  barang kiriman, sebelum diterima oleh penerima yang sah
                </span>
              </p>
            </div>
          </div>
        )}
      </>
    );
  }
);
