import { APIService } from "services";

export const getProvinces = async (pageNo, pageSize, search) => {
    let res;
    if (search.length === 0) {
        res = await getAllProvinces(pageNo, pageSize);
    }
    else {
        try {
            res = await searchProvinces(search, pageNo, pageSize);
        } catch (err) {
            return { records: [], total_count: 0 }
        }
    }
    if (res && res.data) {
        return { records: res.data.data, total_count: 0 }
    } else {
        return { records: [], totalCount: 0 }
    }
}


export const addProvinces = (data) => {
    return APIService.api().post(`/list-provinces`, data)
}
export const updateProvinces = (data) => {
    return APIService.api().put(`/list-provinces/${data.province_id}`, data)
}
export const getAllProvinces = (page, paginator) => {
    return APIService.api().get(`/provinces`)
}
export const getOneProvinces = (province_id) => {
    return APIService.api().get(`/list-provinces/${province_id}`)
}
export const searchProvinces = (searchKey, page, paginator) => {
    return APIService.api().get(`/list-provinces/search/${searchKey}`)
}
export const deleteProvinces = (province_id) => {
    return APIService.api().delete(`/list-provinces/${province_id}`)
}
