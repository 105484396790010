import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IDrivers {
id:number,
name:string,
email?:string,
phone?:string,
license_number?:string,
created_at?:Date,
updated_at?:Date
}

interface IDriversData {
    list?: Array<IDrivers>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
    loading?: boolean;
}

const initialState: IDriversData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
    loading:false
};

const driversSlice = createSlice({
    name: "drivers",
    initialState,
    reducers: {
        setDriversList: (state, _action: PayloadAction<IDriversData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetDriversToInit: (state) => {
            state = initialState;
        },
        setDriversMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
        startLoadingDrivers: (state) => {
            state.loading = true
        },
        stopLoadingDrivers: (state) => {
            state.loading = false
        }
    },
});

export const { setDriversList, resetDriversToInit, setDriversMessage, startLoadingDrivers, stopLoadingDrivers } = driversSlice.actions;

export default driversSlice.reducer;

