import { APIService } from "services"

export const getAllBast = (province_id, city_id, district_id, subdistrict_id) => {
    return APIService.api().get(`/get-document-id?province_id=${province_id}&city_id=${city_id}&district_id=${district_id}&subdistrict_id=${subdistrict_id}`)
}

export const getBastById = (id, data) => {
    return APIService.api().post(`/bnba/get-bast/${id}`, data)
}

