import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import {
  resetProvincesToInit,
  resetReportsToInit,
  setProvincesList,
  setProvincesMessage,
  setReportsList,
  setReportsMessage,
  startLoadingProvinces,
  startLoadingReports,
  stopLoadingProvinces,
  stopLoadingReports,
} from "redux/actions";
import { Button, Card, Form } from "react-bootstrap";
import { getAllReports } from "services/reportsService";
import { Constant } from "template/Constant";
import { useLocation } from "react-router";
import { getProvinces } from "services/provincesService";

const periodsId = localStorage.getItem("periode_id");

interface ParamsState {
  type: string;
  id: string;
  period: number;
  code: number;
  doc_id: number;
  periode_id: any;
}

interface CityState {
  type: string;
  id: string;
  period: number;
  code: number;
  doc_id: number;
  periode_id: any;
}

export const ReportsTable: React.FC = () => {
  const dispatch = useAppDispatch();
  const initialState: ParamsState = {
    type: "prov",
    id: "",
    period: 0,
    code: 0,
    doc_id: 0,
    periode_id: periodsId,
  };
  // const [search, setSearch] = useState('');
  // const [showDelete, setShowDelete] = useState(false);
  // const [rowData, setRowData] = useState(undefined);
  const [params, setParams] = useState<ParamsState>(initialState);
  const [loading, setLoading] = useState(false);
  const [section, setSection] = useState("province");
  const [documentId, setDocumentId] = useState(null);
  const [cityId, setCityId] = useState(null);
  const [districtId, setDistrictId] = useState(null);
  // const [dashboardData, setDashboardData] = useState([])
  const rData = useSelector((state: RootState) => state.reports);
  const reportsLoading = useSelector(
    (state: RootState) => state.reports.loading
  );
  const provincesData = useSelector((state: RootState) => state.provinces);
  const provincesLoading = useSelector(
    (state: RootState) => state.provinces.loading
  );
  const [provinces, setProvinces]: any = useState("");
  const [cityLoadedCrumb, setCityLoadedCrumb] = useState("");
  // const periods = useSelector((state: RootState) => state.periods);
  const location = useLocation();
  const [cityData, setCityData] = useState([]);
  const [cityCrumb, setCityCrumb] = useState(location?.state?.cityCrumb || "");
  const [districtCrumb, setDistrictCrumb] = useState("");
  const [subDistrictCrumb, setSubDistrictCrumb] = useState("");

  const initialCityState: CityState = {
    type: "city",
    id: location?.state?.id,
    period: 0,
    code: 0,
    doc_id: location?.state?.doc_id,
    periode_id: periodsId,
  };
  const [cityParams, setCityParams] = useState<CityState>(initialCityState);
  const getData = () => {
    setLoading(true);
    dispatch(startLoadingReports());
    setCityLoadedCrumb(cityCrumb);
    getAllReports(params).then((response) => {
      if (response) {
        dispatch(
          setReportsList({
            list: response.data.data,
            date: response.data.date,
          })
        );
        setLoading(false);
        dispatch(stopLoadingReports());
      } else {
        dispatch(setReportsMessage("No Record Found"));
        setLoading(false);
        dispatch(stopLoadingReports());
      }
    });
  };

  const getAllProv = () => {
    if (
      provincesData &&
      provincesData.list &&
      provincesData.list.length === 0
    ) {
      dispatch(resetProvincesToInit());
      dispatch(startLoadingProvinces());
      getProvinces(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        ""
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setProvincesList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
          dispatch(stopLoadingProvinces());
        } else {
          dispatch(setProvincesMessage("Data kosong"));
          dispatch(stopLoadingProvinces());
        }
      });
    }
  };
  const getDataCity = () => {
    setLoading(true);
    getAllReports(cityParams).then((response) => {
      if (response) {
        setCityData(response.data.message);
        setLoading(false);
      } else {
        dispatch(setReportsMessage("No Record Found"));
        setLoading(false);
      }
    });
  };
  // const handleSearch = () => {
  //     if (search.length > 0) {
  //         getData(Constant.defaultPageNumber, Constant.defaultPageSize, search);
  //     }
  // }
  // const handlePerRowsChange = async (newPerPage, page) => {
  //     await getData(page, newPerPage, '');
  // }
  // const handlePageChange = (page) => {
  //     getData(page, rData.pageSize, '');
  // };
  // const handleRowDeleteClick = (row) => {
  //     setRowData(row);
  //     setShowDelete(true);
  // }
  // const handleRowPrint = async (row) => {
  //     if (row.verif_documents !== null) {
  //         window.open(row.verif_documents, '_blank', 'noopener,noreferrer');
  //     } else {
  //         alert('null')
  //     }
  // }
  const handleShowProvince = (row) => {
    setSection("province");
    setParams({
      ...params,
      type: "prov",
      doc_id: row,
    });
    setDocumentId(row);
  };
  const handleShowCity = (row) => {
    const parse = JSON.parse(row);
    setSection("city");
    setParams({
      ...params,
      type: "city",
      id: parse.province_id,
    });
    setCityId(parse.province_id);
    setCityCrumb(parse.province_name);
    setDistrictCrumb("");
    setSubDistrictCrumb("");
  };
  const handleShowDistrict = (row) => {
    setSection("district");
    setParams({
      ...params,
      type: "district",
      id: row.city_id,
      doc_id: location.state === null ? documentId : location.state.doc_id,
    });
    setDistrictId(row.city_id);
    setDistrictCrumb(row.city_name);
  };
  const handleShowSubDistrict = (row) => {
    setSection("subdistrict");
    setParams({
      ...params,
      type: "subdistrict",
      id: row.district_id,
    });
    setSubDistrictCrumb(row.district_name);
  };
  const handlePeriods = (id) => {
    setParams({
      type: section === "default" ? "" : section,
      id: districtId,
      period: id,
      code: 0,
      doc_id: documentId,
      periode_id: periodsId,
    });
  };
  useEffect(() => {
    dispatch(resetReportsToInit());
    getAllProv();
    if (location.state && cityData.length === 0) {
      getDataCity();
      setSection(location.state.section);
    }
    if (params.type !== "prov") {
      getData();
    }
    window.history.replaceState({}, document.title);
  }, [params]);
  // const handleReset = () => {
  //     setSearch('');
  //     dispatch(resetShipmentsToInit());
  //     getData(Constant.defaultPageNumber, rData.pageSize, '');
  // }
  // const handleServerDelete = async () => {
  //     if (rowData) {
  //         const response = await deleteShipments(rowData.id);
  //         if (response) {
  //             dispatch(resetShipmentsToInit());
  //             dispatch(setShipmentsMessage("Deleted Successfully"));
  //             getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
  //             setShowDelete(false);
  //         } else {
  //             dispatch(setShipmentsMessage("Some error occured!"));
  //         }
  //     }
  // }

  // const handleRowSelection = (row) => {
  //     console.log(row); // Row Selection Functionality can be written here
  // }
  // const handleAddButtonClick = () => {
  //     dispatch(setShipmentsMessage(''));
  //     hideShowForm('add');
  // }
  const columns = [
    // { name: 'Kode', selector: row => row.code, sortable: true },
    { name: "Dokumen ID", selector: (row) => row.document_id, sortable: true },
    {
      name: "Jumlah Alokasi",
      selector: (row) => row.total_alokasi,
      sortable: true,
    },
    {
      name: "Jumlah Realisasi",
      selector: (row) => row.completed,
      sortable: true,
    },
    { name: "Sisa", selector: (row) => row.remaining, sortable: true },
    {
      name: "Pencapaian (%)",
      selector: (row) => row.percentage_total,
      sortable: true,
    },

    {
      name: "Lihat",
      button: true,
      cell: (row) => (
        <Button
          variant="link"
          className="btn-sm"
          onClick={() => handleShowProvince(row.document_id)}>
          <i className="table-icon fas fa-eye"></i>
        </Button>
      ),
    },
  ];
  const columnsProvince = [
    { name: "Kode", selector: (row) => row.province_id, sortable: true },
    { name: "Provinsi", selector: (row) => row.province_name, sortable: true },
    {
      name: "Jumlah Alokasi",
      selector: (row) => row.total_alokasi,
      sortable: true,
    },
    {
      name: "Jumlah Realisasi",
      selector: (row) => row.realisasi,
      sortable: true,
    },

    //   name: "Jumlah Reversal",
    //   selector: (row) => row.reversal,
    //   sortable: true,
    // },
    { name: "Sisa", selector: (row) => row.sisa, sortable: true },
    {
      name: "Pencapaian (%)",
      selector: (row) => row.percentage_selesai,
      sortable: true,
    },

    {
      name: "Lihat",
      button: true,
      cell: (row) => (
        <Button
          variant="link"
          className="btn-sm"
          onClick={() => handleShowCity(row)}>
          <i className="table-icon fas fa-eye"></i>
        </Button>
      ),
    },
  ];
  const columnsCity = [
    { name: "Kode", selector: (row) => row.city_id, sortable: true },
    { name: "Kota", selector: (row) => row.city_name, sortable: true },
    {
      name: "Jumlah Alokasi",
      selector: (row) => row.total_alokasi,
      sortable: true,
    },
    {
      name: "Jumlah Realisasi",
      selector: (row) => row.realisasi,
      sortable: true,
    },
    // {
    //   name: "Jumlah Reversal",
    //   selector: (row) => row.reversal,
    //   sortable: true,
    // },
    { name: "Sisa", selector: (row) => row.sisa, sortable: true },
    {
      name: "Pencapaian (%)",
      selector: (row) => row.percentage_selesai,
      sortable: true,
    },

    {
      name: "Lihat",
      button: true,
      cell: (row) => (
        <Button
          variant="link"
          className="btn-sm"
          onClick={() => handleShowDistrict(row)}>
          <i className="table-icon fas fa-eye"></i>
        </Button>
      ),
    },
  ];
  const columnsDistrict = [
    { name: "Kode", selector: (row) => row.district_id, sortable: true },
    { name: "Kecamatan", selector: (row) => row.district_name, sortable: true },
    {
      name: "Jumlah Alokasi",
      selector: (row) => row.total_alokasi,
      sortable: true,
    },
    {
      name: "Jumlah Realisasi",
      selector: (row) => row.realisasi,
      sortable: true,
    },
    // {
    //   name: "Jumlah Reversal",
    //   selector: (row) => row.reversal,
    //   sortable: true,
    // },
    { name: "Sisa", selector: (row) => row.sisa, sortable: true },
    {
      name: "Pencapaian (%)",
      selector: (row) => row.percentage_selesai,
      sortable: true,
    },

    {
      name: "Lihat",
      button: true,
      cell: (row) => (
        <Button
          variant="link"
          className="btn-sm"
          onClick={() => handleShowSubDistrict(row)}>
          <i className="table-icon fas fa-eye"></i>
        </Button>
      ),
    },
  ];
  const columnsSubDistrict = [
    { name: "Kode", selector: (row) => row.subdistrict_id, sortable: true },
    {
      name: "Kelurahan",
      selector: (row) => row.subdistrict_name,
      sortable: true,
    },
    {
      name: "Jumlah Alokasi",
      selector: (row) => row.total_alokasi,
      sortable: true,
    },
    {
      name: "Jumlah Realisasi",
      selector: (row) => row.realisasi,
      sortable: true,
    },
    // {
    //   name: "Jumlah Reversal",
    //   selector: (row) => row.reversal,
    //   sortable: true,
    // },
    { name: "Sisa", selector: (row) => row.sisa, sortable: true },
    {
      name: "Pencapaian (%)",
      selector: (row) => row.percentage_selesai,
      sortable: true,
    },

    // {
    //   name: "",
    //   button: true,
    //   cell: (row) => (
    //     <Button
    //       variant="link"
    //       className="btn-sm"
    //       onClick={() => handleShowDistrict(row.city_id)}
    //     >
    //       Lihat
    //     </Button>
    //   ),
    // },
  ];
  const handleBack = () => {
    if (section === "province") {
      // setSection("default");
      setParams({
        type: "",
        id: "",
        period: 0,
        code: 0,
        doc_id: 0,
        periode_id: periodsId,
      });
      window.location.reload();
    }
    if (section === "city") {
      setSection("province");
      setParams({
        type: "prov",
        id: "",
        period: 0,
        code: 0,
        doc_id: documentId,
        periode_id: periodsId,
      });
      setCityCrumb("");
    }
    if (section === "district") {
      setSection("city");
      setParams({
        type: "city",
        id: cityId,
        period: 0,
        code: 0,
        doc_id: documentId,
        periode_id: periodsId,
      });
      setDistrictCrumb("");
    }
    if (section === "subdistrict") {
      setSection("district");
      setParams({
        type: "district",
        id: districtId,
        period: 0,
        code: 0,
        doc_id: documentId,
        periode_id: periodsId,
      });
      setSubDistrictCrumb("");
    }
  };
  const tableCustomStyles = {
    headRow: {
      style: {
        color: "white",
        backgroundColor: "#1D3557",
        borderRadius: "8px",
      },
    },
    rows: {
      stripedStyle: {
        backgroundColor: "#E1F5FE",
        borderRadius: "8px",
      },
    },
  };
  return (
    <>
      <Card className="mb-4" style={{ border: "none" }}>
        <Card.Header
          style={{ backgroundColor: "white", border: "none" }}
          className="p-0 px-3">
          <h5 className="font-weight-bold">Laporan</h5>
          <Form className="d-flex align-items-center">
            <Form.Group controlId="exampleForm.SelectCustom">
              <Form.Control
                style={{ borderRadius: "20px" }}
                as="select"
                custom
                name="provinces"
                onChange={(e: any) => handleShowCity(e.currentTarget.value)}>
                <option disabled={reportsLoading} value={0}>
                  {provincesLoading ? "Loading..." : "Pilih Provinsi"}
                </option>
                {provincesData.list.map((item, i) => {
                  return (
                    <option
                      disabled={provincesLoading || reportsLoading}
                      value={JSON.stringify(item)}
                      key={`provinces-${i}`}>
                      {item.province_name}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
          </Form>
          <h6 className="m-0 font-weight-bold text-primary">
            Daftar Laporan{" "}
            {cityLoadedCrumb !== "" &&
              rData.list.length > 0 &&
              `/ PROVINSI ${cityLoadedCrumb?.toUpperCase() || ""}`}
            {districtCrumb !== "" && ` / KOTA ${districtCrumb?.toUpperCase()}`}
            {subDistrictCrumb !== "" &&
              ` / KECAMATAN ${subDistrictCrumb?.toUpperCase()}`}
          </h6>
        </Card.Header>
        <Card.Body>
          <div className="table-responsive">
            <div className="mb-4" style={{ display: "flex" }}>
              {/* <Button variant="primary">Show</Button> */}
              {section !== "province" &&
                section !== "city" &&
                !loading &&
                location.state === null && (
                  <Button
                    onClick={() => handleBack()}
                    variant="primary"
                    style={{ marginRight: "10px" }}>
                    <i className="fas fa-arrow-left"></i> Kembali
                  </Button>
                )}
              {/* <label className="form -control-label">Tahap Pencairan</label> */}
              {/* {!loading && (
              <Form.Control as="select" style={{ width: "10%" }}>
                {periods.list.map((item, i) => {
                  return (
                    <option
                      value={item.id}
                      key={`periods-${i}`}
                      onClick={() => handlePeriods(item.id)}
                    >
                      {item.tahap_name}
                    </option>
                  );
                })}
              </Form.Control>
            )} */}
            </div>
            {section === "fromDashboard" && (
              <DataTable
                striped
                customStyles={tableCustomStyles}
                // selectableRows={true}
                // onSelectedRowsChange={handleRowSelection}
                // paginationPerPage={Constant.defaultPageNumber}
                // paginationRowsPerPageOptions={Constant.paginationRowsPerPageOptions}
                columns={columnsCity}
                data={cityData}
                progressPending={loading}
                // onChangeRowsPerPage={handlePerRowsChange}
                // paginationTotalRows={rData.totalCount}
                className="table table-bordered"
                // pagination
                // paginationServer
                // onChangePage={handlePageChange}
              />
            )}
            {section === "default" && location.state !== null && (
              <DataTable
                striped
                customStyles={tableCustomStyles}
                // selectableRows={true}
                // onSelectedRowsChange={handleRowSelection}
                paginationPerPage={Constant.defaultPageNumber}
                // paginationRowsPerPageOptions={Constant.paginationRowsPerPageOptions}
                columns={columns}
                data={rData.list}
                progressPending={loading}
                // onChangeRowsPerPage={handlePerRowsChange}
                // paginationTotalRows={rData.totalCount}
                className="table table-bordered"
                pagination
                paginationServer
                // onChangePage={handlePageChange}
              />
            )}
            {section === "province" && (
              <DataTable
                striped
                customStyles={tableCustomStyles}
                // selectableRows={true}
                // onSelectedRowsChange={handleRowSelection}
                // paginationPerPage={Constant.defaultPageNumber}
                // paginationRowsPerPageOptions={Constant.paginationRowsPerPageOptions}
                columns={columnsProvince}
                data={rData.list}
                progressPending={loading}
                // onChangeRowsPerPage={handlePerRowsChange}
                // paginationTotalRows={rData.totalCount}
                className="table table-bordered"
                // pagination
                // paginationServer
                // onChangePage={handlePageChange}
              />
            )}
            {section === "city" && (
              <DataTable
                striped
                customStyles={tableCustomStyles}
                // selectableRows={true}
                // onSelectedRowsChange={handleRowSelection}
                // paginationPerPage={Constant.defaultPageNumber}
                // paginationRowsPerPageOptions={Constant.paginationRowsPerPageOptions}
                columns={columnsCity}
                data={location.state ? cityData : rData.list}
                progressPending={loading}
                // onChangeRowsPerPage={handlePerRowsChange}
                // paginationTotalRows={rData.totalCount}
                className="table table-bordered"
                // pagination
                // paginationServer
                // onChangePage={handlePageChange}
              />
            )}
            {section === "district" && (
              <DataTable
                striped
                customStyles={tableCustomStyles}
                // selectableRows={true}
                // onSelectedRowsChange={handleRowSelection}
                // paginationPerPage={Constant.defaultPageNumber}
                // paginationRowsPerPageOptions={Constant.paginationRowsPerPageOptions}
                columns={columnsDistrict}
                data={rData.list}
                progressPending={loading}
                // onChangeRowsPerPage={handlePerRowsChange}
                // paginationTotalRows={rData.totalCount}
                className="table table-bordered"
                // pagination
                // paginationServer
                // onChangePage={handlePageChange}
              />
            )}
            {section === "subdistrict" && (
              <DataTable
                striped
                customStyles={tableCustomStyles}
                // selectableRows={true}
                // onSelectedRowsChange={handleRowSelection}
                // paginationPerPage={Constant.defaultPageNumber}
                // paginationRowsPerPageOptions={Constant.paginationRowsPerPageOptions}
                columns={columnsSubDistrict}
                data={rData.list}
                progressPending={loading}
                // onChangeRowsPerPage={handlePerRowsChange}
                // paginationTotalRows={rData.totalCount}
                className="table table-bordered"
                // pagination
                // paginationServer
                // onChangePage={handlePageChange}
              />
            )}
          </div>
        </Card.Body>
        {/* <ConfirmationModal buttonNegative="Cancel" buttonPositive="Delete" title="Delete Confirmation" show={showDelete} body={"Are you sure?"} onNegative={() => setShowDelete(false)} onPositive={handleServerDelete} /> */}
      </Card>
    </>
  );
};
