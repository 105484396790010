import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Alert, Button, Card, Form, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { resetCitiesToInit, resetDistrictsToInit, resetProvincesToInit, resetRolesToInit, resetSubdistrictsToInit, setCitiesList, setCitiesMessage, setDistrictsList, setDistrictsMessage, setProvincesList, setProvincesMessage, setRolesList, setRolesMessage, setSubdistrictsList, setSubdistrictsMessage, setUsersMessage, startLoadingCities, startLoadingDistricts, startLoadingProvinces, startLoadingRoles, startLoadingSubDistricts, stopLoadingCities, stopLoadingDistricts, stopLoadingProvinces, stopLoadingRoles, stopLoadingSubDistricts } from "redux/actions";


import { useAppDispatch } from "redux/store";
import { addUsers, updateUsers } from "services/usersService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
import { useNavigate } from "react-router";
import { getProvinces } from "services/provincesService";
import { getCities } from "services/citiesService";
import { getDistricts } from "services/districtsService";
import { getSubdistricts } from "services/subdistrictsService";
import { getRoles } from "services/rolesService";
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey, periode_id, date, province_id, city_id, district_id, subdistrict_id) => void;
    action?: string
};
export const UsersForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue = { id: 0, warehouse_name: '', email: '', password: '', levels: '', remember_token: '', created_at: '', updated_at: '', province_id: '', city_id: '', district_id: '', subdistrict_id: '' };
    const initialValue = action === 'edit' ? row : iValue;
    const warehousesData = useSelector((state: RootState) => state.warehouses);
    const provincesData = useSelector((state: RootState) => state.provinces);
    const citiesData = useSelector((state: RootState) => state.cities);
    const districtsData = useSelector((state: RootState) => state.districts);
    const subdistrictsData = useSelector((state: RootState) => state.subdistricts);
    const rolesData = useSelector((state: RootState) => state.roles)
    const warehouseLoading = useSelector((state: RootState) => state.warehouses.loading)
    const rolesLoading = useSelector((state: RootState) => state.roles.loading)
    const provincesLoading = useSelector((state: RootState) => state.provinces.loading)
    const citiesLoading = useSelector((state: RootState) => state.cities.loading)
    const districtsLoading = useSelector((state: RootState) => state.districts.loading)
    const subdistrictsLoading = useSelector((state: RootState) => state.subdistricts.loading)
    const rData = useSelector((state: RootState) => state.users);
    const periodsId = localStorage.getItem('periode_id')
    const provinceId = localStorage.getItem('province_id')
    const cityId = localStorage.getItem('city_id')
    const districtId = localStorage.getItem('district_id')
    const subdistrictId = localStorage.getItem('subdistrict_id')
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        // if (warehousesData && warehousesData.list && warehousesData.list.length === 0) {
        //     dispatch(resetWarehousesToInit());
        //     dispatch(startLoadingWarehouses())
        //     getWarehouses(Constant.defaultPageNumber, 1000, '').then((response) => {
        //         if (response && response.records) {
        //             dispatch(setWarehousesList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
        //             dispatch(stopLoadingWarehouses())
        //         } else {
        //             dispatch(setWarehousesMessage("Data kosong"));
        //             dispatch(stopLoadingWarehouses())
        //         }
        //     })
        // }
        if (provincesData && provincesData.list && provincesData.list.length === 0) {
            dispatch(resetProvincesToInit());
            dispatch(startLoadingProvinces())
            getProvinces(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '').then((response) => {
                if (response && response.records) {
                    dispatch(setProvincesList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
                    dispatch(stopLoadingProvinces())
                } else {
                    dispatch(setProvincesMessage("Data kosong"));
                    dispatch(stopLoadingProvinces())
                }
            })
        }
        // if (citiesData && citiesData.list && citiesData.list.length === 0) {
        //     dispatch(resetCitiesToInit());
        //     dispatch(startLoadingCities())
        //     getCities(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '', provinceId).then((response) => {
        //         if (response && response.records) {
        //             dispatch(setCitiesList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
        //             dispatch(stopLoadingCities())
        //         } else {
        //             dispatch(setKpmsMessage("Data kosong"));
        //             dispatch(stopLoadingCities())
        //         }
        //     })
        // }
        // if (districtsData && districtsData.list && districtsData.list.length === 0) {
        //     dispatch(resetDistrictsToInit());
        //     dispatch(startLoadingDistricts())
        //     getDistricts(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '', cityId).then((response) => {
        //         if (response && response.records) {
        //             dispatch(setDistrictsList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
        //             dispatch(stopLoadingDistricts())
        //         } else {
        //             dispatch(setKpmsMessage("Data kosong"));
        //             dispatch(stopLoadingDistricts())
        //         }
        //     })
        // }
        // if (subdistrictsData && subdistrictsData.list && subdistrictsData.list.length === 0) {
        //     dispatch(resetSubdistrictsToInit());
        //     dispatch(startLoadingSubDistricts())
        //     getSubdistricts(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '', districtId).then((response) => {
        //         if (response && response.records) {
        //             dispatch(setSubdistrictsList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
        //             dispatch(stopLoadingSubDistricts())
        //         } else {
        //             dispatch(setKpmsMessage("Data kosong"));
        //             dispatch(stopLoadingSubDistricts())
        //         }
        //     })
        // }
        if (rolesData && rolesData.list && rolesData.list.length === 0) {
            dispatch(resetRolesToInit())
            dispatch(startLoadingRoles())
            getRoles().then((response) => {
                if (response) {
                    dispatch(setRolesList({ list: response.list }))
                    dispatch(stopLoadingRoles())
                } else {
                    dispatch(setRolesMessage('Data kosong'))
                    dispatch(stopLoadingRoles())
                }
            })
        }
    }, [])
    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            setLoading(true)
            if (action === 'edit') {
                updateUsers(values).then((response: any) => {
                    if (response && response.data && response.data.code === 1) {
                        setLoading(false)
                        dispatch(setUsersMessage("Berhasil mengubah data"));
                        getData(Constant.defaultPageNumber, Constant.defaultPageSize, '', periodsId, '', provinceId, cityId, districtId, subdistrictId);
                        hideShowForm('');
                    }
                }).catch(error => {
                    setLoading(false)
                    formik.resetForm()
                    dispatch(setUsersMessage("Terjadi kesalahan"));
                })
            } else if (action === 'add') {
                addUsers(values).then((response: any) => {
                    if (response && response.data && response.data.code === 1) {
                        setLoading(false)
                        dispatch(setUsersMessage("Berhasil menambah data"));
                        getData(Constant.defaultPageNumber, Constant.defaultPageSize, '', periodsId, '', provinceId, cityId, districtId, subdistrictId);
                        navigate('/users')
                    } else {
                        setLoading(false)
                        formik.resetForm()
                        dispatch(setUsersMessage(response.data.message === 'email already exists' && 'Email sudah digunakan' || 'Terjadi kesalahan'));
                    }
                }).catch(error => {
                    setLoading(false)
                    formik.resetForm()
                    dispatch(setUsersMessage("Terjadi kesalahan"));
                })
                // if (response && response.data && response.data.code === 1) {
                //     setLoading(false)
                //     dispatch(setUsersMessage("Berhasil menambah data"));
                //     getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                //     // hideShowForm('');
                //     navigate('/users')
                // } else {
                //     dispatch(setUsersMessage("Terjadi kesalahan"));
                //     setLoading(false)
                // }
            }
        },
        validationSchema: yup.object({
            email: yup.string().required('Email wajib diisi'),
            // password: yup.string().required('password is required'),
            levels: yup.string().nullable().required('Roles wajib diisi'),
            remember_token: yup.string().nullable(),
            created_at: yup.date().nullable(),
            updated_at: yup.date().nullable(),
            // warehouse_id: yup.string().required('ID Gudang wajib diisi'),
            province_id: yup.string().required('ID Provinsi wajib diisi'),
            city_id: yup.string().required('ID Kota wajib diisi'),
            // district_id: yup.string().required('ID Kecamatan wajib diisi'),
            // subdistrict_id: yup.string().required('ID Kelurahan wajib diisi'),
        }),
    });
    const formikProvinceId = formik.values.province_id
    const formikCityId = formik.values.city_id
    const formikDistrictId = formik.values.district_id
    useEffect(() => {
        if (formikProvinceId) {
            dispatch(resetCitiesToInit());
            dispatch(startLoadingCities())
            getCities(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '', formikProvinceId).then((response) => {
                if (response && response.records) {
                    dispatch(setCitiesList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
                    dispatch(stopLoadingCities())
                } else {
                    dispatch(setCitiesMessage("Data kosong"));
                    dispatch(stopLoadingCities())
                }
            })
        }
    }, [formikProvinceId]);

    useEffect(() => {
        if (formikCityId) {
            dispatch(resetDistrictsToInit());
            dispatch(startLoadingDistricts())
            getDistricts(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '', formikCityId).then((response) => {
                if (response && response.records) {
                    dispatch(setDistrictsList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
                    dispatch(stopLoadingDistricts())
                } else {
                    dispatch(setDistrictsMessage("Data kosong"));
                    dispatch(stopLoadingDistricts())
                }
            })
        }
    }, [formikCityId]);

    useEffect(() => {
        if (formikDistrictId) {
            dispatch(resetSubdistrictsToInit());
            dispatch(startLoadingSubDistricts())
            getSubdistricts(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '', formikDistrictId).then((response) => {
                if (response && response.records) {
                    dispatch(setSubdistrictsList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
                    dispatch(stopLoadingSubDistricts())
                } else {
                    dispatch(setSubdistrictsMessage("Data kosong"));
                    dispatch(stopLoadingSubDistricts())
                }
            })
        }
    }, [formikDistrictId]);

    const resetForm = () => {
        formik.resetForm()
    }
    const [searchWarehouses, setSearchWarehouses] = useState('');
    const [filteredWarehouses, setFilteredWarehouses] = useState(warehousesData.list);
    const handleSearchWarehouses = (e) => {
        const searchValue = e.target.value;
        setSearchWarehouses(searchValue);
        const filtered = warehousesData.list.filter(item => item?.name?.toLowerCase().includes(searchValue.toLowerCase()) || item?.code?.includes(searchValue));
        setFilteredWarehouses(filtered);
    };
    return (
        <>
            {rData.message && action === 'add' ?
                <Alert variant='danger' className="alert-dismissible fade">{rData.message}
                    <Button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => dispatch(setUsersMessage(''))}>
                        <span aria-hidden="true">&times;</span>
                    </Button>
                </Alert> : null}
            <Card className="shadow mb-4">
                <Card.Header className="py-3">
                    <h6 className="m-0 font-weight-bold text-primary text-capitalize">{action === 'add' ? 'Tambah' : 'Ubah'} Pengguna
                        {/* <Button className="btn-icon-split float-right" onClick={() => hideShowForm(false)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-list"></i>
                        </span>
                        <span className="text">View Users</span>
                    </Button> */}
                    </h6>

                </Card.Header>
                <Card.Body>
                    <Form onSubmit={formik.handleSubmit} className="w-100 d-flex flex-wrap">
                        <Form.Group className="w-100 px-2 split-form">
                            <label className="form -control-label">Email</label>
                            <Form.Control type="text" name="email" className="form-control" value={formik.values.email} disabled={action === 'edit'}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={!!formik.touched.email && !!formik.errors.email}
                                isValid={!!formik.touched.email && !formik.errors.email}
                            ></Form.Control>
                            {
                                formik.errors.email && (
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.email}
                                    </Form.Control.Feedback>
                                )}
                        </Form.Group>
                        <Form.Group hidden={action === 'edit'} className="w-100 px-2 split-form">
                            <label className="form -control-label">Password</label>
                            <Form.Control type="password" name="password" className="form-control" value={formik.values.password} disabled={action === 'edit'}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={!!formik.touched.password && !!formik.errors.password}
                                isValid={!!formik.touched.password && !formik.errors.password}
                            ></Form.Control>
                            {
                                formik.errors.password && (
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.password}
                                    </Form.Control.Feedback>
                                )}
                        </Form.Group>
                        {/* <Form.Group>
                        <label className="form -control-label">Levels</label>
                        <Form.Control type="text" name="levels" className="form-control" value={formik.values.levels}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.levels && !!formik.errors.levels}
                            isValid={!!formik.touched.levels && !formik.errors.levels}
                        ></Form.Control>
                        {
                            formik.errors.levels && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.levels}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group> */}
                        {/* <Form.Group className="w-100 px-2 split-form">
                            <label className="form -control-label">Nama Gudang</label>
                            <Form.Control type="text" name="warehouse_name" className="form-control" value={formik.values.warehouse_name} disabled={action === 'edit'}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={!!formik.touched.warehouse_name && !!formik.errors.warehouse_name}
                                isValid={!!formik.touched.warehouse_name && !formik.errors.warehouse_name}
                            ></Form.Control>
                            {
                                formik.errors.warehouse_name && (
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.warehouse_name}
                                    </Form.Control.Feedback>
                                )}
                        </Form.Group> */}
                        <Form.Group className="w-100 px-2 split-form">
                            <label className="form -control-label">Roles</label>
                            <Form.Control as="select" name="levels" className="form-control" value={formik.values.levels}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={!!formik.touched.levels && !!formik.errors.levels}
                                isValid={!!formik.touched.levels && !formik.errors.levels}
                                disabled={rolesLoading || action === 'edit'}
                            >
                                <option value={0}>{warehouseLoading ? 'Loading...' : 'Pilih Roles'}</option>
                                {
                                    rolesData.list.map((item, i) => {
                                        return <option value={item.id} key={`roles-${i}`}>{item.role_name}</option>
                                    })}
                            </Form.Control>
                            {
                                formik.errors.levels && (
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.levels}
                                    </Form.Control.Feedback>
                                )}
                        </Form.Group>
                        {/* <Form.Group>
                        <label className="form -control-label">remember_token</label>
                        <Form.Control type="text" name="remember_token" className="form-control" value={formik.values.remember_token}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.remember_token && !!formik.errors.remember_token}
                            isValid={!!formik.touched.remember_token && !formik.errors.remember_token}
                        ></Form.Control>
                        {
                            formik.errors.remember_token && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.remember_token}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group>
                        <label className="form -control-label">created_at</label>
                        <Form.Control type="text" name="created_at" className="form-control" value={formik.values.created_at}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.created_at && !!formik.errors.created_at}
                            isValid={!!formik.touched.created_at && !formik.errors.created_at}
                        ></Form.Control>
                        {
                            formik.errors.created_at && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.created_at}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group>
                        <label className="form -control-label">updated_at</label>
                        <Form.Control type="text" name="updated_at" className="form-control" value={formik.values.updated_at}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.updated_at && !!formik.errors.updated_at}
                            isValid={!!formik.touched.updated_at && !formik.errors.updated_at}
                        ></Form.Control>
                        {
                            formik.errors.updated_at && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.updated_at}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group> */}
                        {/* <Form.Group className="w-100 px-2 split-form">
                            <label className="form -control-label">ID Gudang</label>
                            <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text id="basic-addon1"><i className="fas fa-search"></i></InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                placeholder="Cari Gudang"
                                value={searchWarehouses}
                                onChange={handleSearchWarehouses}
                                disabled={warehouseLoading}
                            />
                        </InputGroup>
                            <Form.Control as="select" name="warehouse_id" className="form-control" value={formik.values.warehouse_id}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={!!formik.touched.warehouse_id && !!formik.errors.warehouse_id}
                                isValid={!!formik.touched.warehouse_id && !formik.errors.warehouse_id}
                                disabled={warehouseLoading}
                            >
                                <option value={0}>{warehouseLoading ? 'Loading...' : 'Pilih Gudang'}</option>
                                {searchWarehouses ? filteredWarehouses.map((item, i) => {
                                return <option value={item.id} key={`warehouses-${i}`}>{item.code} - {item.name} </option>
                            }) : warehousesData.list.map((item, i) => {
                                return <option value={item.id} key={`warehouses-${i}`}>{item.code} - {item.name}</option>
                            })}
                            </Form.Control>
                            {
                                formik.errors.warehouse_id && (
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.warehouse_id}
                                    </Form.Control.Feedback>
                                )}
                        </Form.Group> */}
                        <Form.Group className="w-100 px-2 split-form">
                            <label className="form -control-label">ID Provinsi</label>
                            <Form.Control as="select" name="province_id" className="form-control" value={formik.values.province_id}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={!!formik.touched.province_id && !!formik.errors.province_id}
                                isValid={!!formik.touched.province_id && !formik.errors.province_id}
                                disabled={provincesLoading || action === 'edit'}
                            >
                                <option value={0}>{provincesLoading ? 'Loading...' : 'Pilih Provinsi'}</option>
                                {
                                    provincesData.list.map((item, i) => {
                                        return <option value={item.province_id} key={`provinces-${i}`}>{item.province_name}</option>
                                    })}
                            </Form.Control>
                            {
                                formik.errors.province_id && (
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.province_id}
                                    </Form.Control.Feedback>
                                )}
                        </Form.Group>
                        <Form.Group className="w-100 px-2 split-form">
                            <label className="form -control-label">ID Kota</label>
                            <Form.Control as="select" name="city_id" className="form-control" value={formik.values.city_id}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={!!formik.touched.city_id && !!formik.errors.city_id}
                                isValid={!!formik.touched.city_id && !formik.errors.city_id}
                                disabled={citiesLoading || action === 'edit'}
                            >
                                <option value={0}>{citiesLoading ? 'Loading...' : 'Pilih Kota'}</option>
                                {
                                    citiesData.list.map((item, i) => {
                                        return <option value={item.city_id} key={`cities-${i}`}>{item.city_name}</option>
                                    })}
                            </Form.Control>
                            {
                                formik.errors.city_id && (
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.city_id}
                                    </Form.Control.Feedback>
                                )}
                        </Form.Group>
                        {/* <Form.Group className="w-100 px-2 split-form">
                            <label className="form -control-label">ID Kecamatan</label>
                            <Form.Control as="select" name="district_id" className="form-control" value={formik.values.district_id}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={!!formik.touched.district_id && !!formik.errors.district_id}
                                isValid={!!formik.touched.district_id && !formik.errors.district_id}
                                disabled={districtsLoading || action === 'edit'}
                            >
                                <option value={0}>{districtsLoading ? 'Loading...' : 'Pilih Kecamatan'}</option>
                                {
                                    districtsData.list.map((item, i) => {
                                        return <option value={item.district_id} key={`districts-${i}`}>{item.district_name}</option>
                                    })}
                            </Form.Control>
                            {
                                formik.errors.district_id && (
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.district_id}
                                    </Form.Control.Feedback>
                                )}
                        </Form.Group>
                        <Form.Group className="w-100 px-2 split-form">
                            <label className="form -control-label">ID Kelurahan</label>
                            <Form.Control as="select" name="subdistrict_id" className="form-control" value={formik.values.subdistrict_id}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={!!formik.touched.subdistrict_id && !!formik.errors.subdistrict_id}
                                isValid={!!formik.touched.subdistrict_id && !formik.errors.subdistrict_id}
                                disabled={subdistrictsLoading || action === 'edit'}
                            >
                                <option value={0}>{subdistrictsLoading ? 'Loading...' : 'Pilih Kelurahan'}</option>
                                {
                                    subdistrictsData.list.map((item, i) => {
                                        return <option value={item.subdistrict_id} key={`subdistricts-${i}`}>{item.subdistrict_name}</option>
                                    })}
                            </Form.Control>
                            {
                                formik.errors.subdistrict_id && (
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.subdistrict_id}
                                    </Form.Control.Feedback>
                                )}
                        </Form.Group> */}

                        <Form.Group className="w-100 px-2" style={{ display: action === 'edit' && 'none' }}>
                            <Button type="submit" className="float-left mr-3" variant="primary" disabled={loading}>{loading && <Spinner variant="light" animation="border" size="sm" className="mr-1"></Spinner>}Simpan</Button>
                            <Button className="float-left" variant="secondary" onClick={() => resetForm()}>Reset</Button>
                        </Form.Group>
                    </Form>
                </Card.Body>
            </Card>
        </>
    );
}

