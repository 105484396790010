import { APIService } from "services"

export const uploadImageService = (data) => {
    return APIService.api().post(`/upload/image`, data);
}
export const uploadMultipleKPMSImageService = (data) => {
    return APIService.api().post(`/pbps/multiple_image`, data);
}
export const uploadFileService = (data) => {
    return APIService.api().post(`/upload/file`, data);
}
export const uploadFileKPMS = (data) => {
    return APIService.api().post(`/pbps/import-bulog`, data);
}
export const uploadFileBNBA = (data) => {
    return APIService.api().post(`/bnba/upload`, data);
}
