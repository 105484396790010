import { APIService } from "services"

export const getRoles = async () => {
    let res
    res = await getAllRoles()
    if (res && res.data) {
        return { list: res.data.data }
    } else {
        return { list: [] }
    }
}

export const getAllRoles = () => {
    return APIService.api().get(`/roles`)
}


