import { useLayoutEffect, useState, useEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const ChartComponent = (props) => {
  const { loading, data } = props;
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (data && data.list && data.list.data) {
      let chartdata = [
        {
          province_name: data?.list?.data[0]?.province_name,
          total_alokasi: data?.list?.data[0]?.total_alokasi
            ? parseInt(data?.list?.data[0]?.total_alokasi)
            : 0,
          percentage_selesai: data?.list?.data[0]?.percentage_selesai
            ? parseFloat(data?.list?.data[0]?.percentage_selesai)
            : 0,
        },
        {
          province_name: data?.list?.data[1]?.province_name,
          total_alokasi: data?.list?.data[1]?.total_alokasi
            ? parseInt(data?.list?.data[1]?.total_alokasi)
            : 0,
          percentage_selesai: data?.list?.data[1]?.percentage_selesai
            ? parseFloat(data?.list?.data[1]?.percentage_selesai)
            : 0,
        },
        {
          province_name: data?.list?.data[2]?.province_name,
          total_alokasi: data?.list?.data[2]?.total_alokasi
            ? parseInt(data?.list?.data[2]?.total_alokasi)
            : 0,
          percentage_selesai: data?.list?.data[2]?.percentage_selesai
            ? parseFloat(data?.list?.data[2]?.percentage_selesai)
            : 0,
        },
        {
          province_name: data?.list?.data[3]?.province_name,
          total_alokasi: data?.list?.data[3]?.total_alokasi
            ? parseInt(data?.list?.data[3]?.total_alokasi)
            : 0,
          percentage_selesai: data?.list?.data[3]?.percentage_selesai
            ? parseFloat(data?.list?.data[3]?.percentage_selesai)
            : 0,
        },
        {
          province_name: data?.list?.data[4]?.province_name,
          total_alokasi: data?.list?.data[4]?.total_alokasi
            ? parseInt(data?.list?.data[4]?.total_alokasi)
            : 0,
          percentage_selesai: data?.list?.data[4]?.percentage_selesai
            ? parseFloat(data?.list?.data[4]?.percentage_selesai)
            : 0,
        },
        {
          province_name: data?.list?.data[5]?.province_name,
          total_alokasi: data?.list?.data[5]?.total_alokasi
            ? parseInt(data?.list?.data[5]?.total_alokasi)
            : 0,
          percentage_selesai: data?.list?.data[5]?.percentage_selesai
            ? parseFloat(data?.list?.data[5]?.percentage_selesai)
            : 0,
        },
        {
          province_name: data?.list?.data[6]?.province_name,
          total_alokasi: data?.list?.data[6]?.total_alokasi
            ? parseInt(data?.list?.data[6]?.total_alokasi)
            : 0,
          percentage_selesai: data?.list?.data[6]?.percentage_selesai
            ? parseFloat(data?.list?.data[6]?.percentage_selesai)
            : 0,
        },
      ];
      setChartData(chartdata);
    }
  }, [data]);

  useLayoutEffect(() => {
    let root = am5.Root.new("chartdiv");
    root.setThemes([am5themes_Animated.new(root)]);
    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panY: false,
        layout: root.verticalLayout,
      })
    );

    if (loading) {
      let indicator = root.container.children.push(
        am5.Container.new(root, {
          width: am5.p100,
          height: am5.p100,
          layer: 1000,
          background: am5.Rectangle.new(root, {
            fill: am5.color(0xffffff),
            fillOpacity: 0.7,
          }),
        })
      );
      let hourglass = indicator.children.push(
        am5.Graphics.new(root, {
          width: 32,
          height: 32,
          fill: am5.color(0x000000),
          x: am5.p50,
          y: am5.p50,
          centerX: am5.p50,
          centerY: am5.p50,
          dy: -45,
          svgPath:
            "M12 5v10l9 9-9 9v10h24V33l-9-9 9-9V5H12zm20 29v5H16v-5l8-8 8 8zm-8-12-8-8V9h16v5l-8 8z",
        })
      );
      var hourglassanimation = hourglass.animate({
        key: "rotation",
        to: 180,
        loops: Infinity,
        duration: 2000,
        easing: am5.ease.inOut(am5.ease.cubic),
      });
      indicator.children.push(
        am5.Label.new(root, {
          text: "Loading...",
          fontSize: 25,
          x: am5.p50,
          y: am5.p50,
          centerX: am5.p50,
          centerY: am5.p50,
        })
      );
      hourglassanimation.play();
    }

    // Create X-axis (previously Y-axis)
    let xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererX.new(root, {}),
      })
    );

    // Create Y-Axis (previously X-axis)
    let yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
        categoryField: "province_name",
      })
    );

    // Create series
    let series1 = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "PBP (%)",
        xAxis: xAxis, // Use X-axis for data values
        yAxis: yAxis, // Use Y-axis for categories
        valueXField: "percentage_selesai", // Map value to X-axis
        categoryYField: "province_name", // Map category to Y-axis
      })
    );
    if (data && data.list && data.list.data) {
      yAxis.data.setAll(chartData);
      series1.data.setAll(chartData);
    }

    series1.columns.template.set("fill", am5.color("#31cffc"));
    series1.columns.template.set(
      "tooltipText",
      "{categoryY}: {percentage_selesai}%"
    );
    series1.columns.template.set("hideOversizedLabels", false);

    // Add legend
    let legend = chart.children.push(am5.Legend.new(root, {}));
    legend.data.setAll(chart.series.values);

    // Add cursor
    chart.set("cursor", am5xy.XYCursor.new(root, {}));

    return () => {
      root.dispose();
    };
  }, [loading]);

  return (
    <div className="row">
      <div id="chartdiv" className="shadow-sm">
        <h6 className="font-weight-bold pt-4 pl-4" style={{ color: "black" }}>
          Realisasi Penyerahan
        </h6>
      </div>
    </div>
  );
};

export default ChartComponent;
