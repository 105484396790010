import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { setSubdistrictsMessage } from "redux/actions";
import { resetDistrictsToInit, setDistrictsList } from "redux/actions";

import { getDistricts } from "services/districtsService";

import { useAppDispatch } from "redux/store";
import { addSubdistricts, updateSubdistricts } from "services/subdistrictsService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const SubdistrictsForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue={subdistrict_id:0,district_id:0,subdistrict_name:''};
    const initialValue = action === 'edit' ? row : iValue;
    const districtsData = useSelector((state: RootState) => state.districts);

    useEffect(() => {
if (districtsData && districtsData.list && districtsData.list.length === 0) {
            dispatch(resetDistrictsToInit());
            getDistricts(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '','').then((response) => {
                if (response && response.records) {
                  dispatch(setDistrictsList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
                } else {
                  dispatch(setSubdistrictsMessage("No Record Found For Districts"));
                }
              })
        }
})
    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            if (action === 'edit') {
                const response = await updateSubdistricts(values);
                if (response && response.data && response.data.code===1) {
                    dispatch(setSubdistrictsMessage("Updated Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setSubdistrictsMessage("Some error occured!"));
                }
            } else if (action === 'add') {
                const response = await addSubdistricts(values);
                if (response && response.data && response.data.code===1) {
                    dispatch(setSubdistrictsMessage("Added Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setSubdistrictsMessage("Some error occured!"));
                }
            }
        },
        validationSchema: yup.object({
           subdistrict_id: yup.number().required('subdistrict_id is required'),
subdistrict_name: yup.string().nullable(),
district_id: yup.string().required('district_id is required'),

        }),
    });
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-primary text-capitalize">{action} Subdistricts
                    <Button className="btn-icon-split float-right" onClick={() => hideShowForm(false)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-list"></i>
                        </span>
                        <span className="text">View Subdistricts</span>
                    </Button>
                </h6>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Group>
<label className="form -control-label">subdistrict_id</label>
<Form.Control type="text" name="subdistrict_id" className="form-control" value={formik.values.subdistrict_id}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.subdistrict_id && !!formik.errors.subdistrict_id}
isValid ={!!formik.touched.subdistrict_id && !formik.errors.subdistrict_id}
></Form.Control>
{
    formik.errors.subdistrict_id && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.subdistrict_id}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">subdistrict_name</label>
<Form.Control type="text" name="subdistrict_name" className="form-control" value={formik.values.subdistrict_name}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.subdistrict_name && !!formik.errors.subdistrict_name}
isValid ={!!formik.touched.subdistrict_name && !formik.errors.subdistrict_name}
></Form.Control>
{
    formik.errors.subdistrict_name && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.subdistrict_name}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">district_id</label>
<Form.Control as="select"  name="district_id" className="form-control" value={formik.values.district_id}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.district_id && !!formik.errors.district_id}
isValid ={!!formik.touched.district_id && !formik.errors.district_id}
>
<option value={0}>Select Districts </option> 
{
districtsData.list.map((item, i) => {
return <option value={item.district_id} key={`districts-${i}`}>{item.district_name}</option>
})}
</Form.Control>
{
    formik.errors.district_id && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.district_id}
    </Form.Control.Feedback>
)}
</Form.Group>

                    <Form.Group>
                        <Button type="submit" className="float-right" variant="primary">Save</Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

