import { APIService } from "services";

export const getSubdistricts = async (pageNo, pageSize, search, id) => {
    let res;
    if (search.length === 0) {
        res = await getAllSubdistricts(pageNo, pageSize, id);
    }
    else {
        try {
            res = await searchSubdistricts(search, pageNo, pageSize);
        } catch (err) {
            return { records: [], total_count: 0 }
        }
    }
    if (res && res.data) {
        return { records: res.data.data, total_count: 0 }
    } else {
        return { records: [], totalCount: 0 }
    }

}


export const addSubdistricts = (data) => {
    return APIService.api().post(`/list-subdistricts`, data)
}
export const updateSubdistricts = (data) => {
    return APIService.api().put(`/list-subdistricts/${data.subdistrict_id}`, data)
}
export const getAllSubdistricts = (page, paginator, id) => {
    return APIService.api().get(`/subdistricts?district_id=${id}`)
}
export const getOneSubdistricts = (subdistrict_id) => {
    return APIService.api().get(`/list-subdistricts/${subdistrict_id}`)
}
export const searchSubdistricts = (searchKey, page, paginator) => {
    return APIService.api().get(`/list-subdistricts/search/${searchKey}`)
}
export const deleteSubdistricts = (subdistrict_id) => {
    return APIService.api().delete(`/list-subdistricts/${subdistrict_id}`)
}
