import { APIService } from "services";

export const getCities = async (pageNo, pageSize, search, province_id) => {
    let res;
    if (search.length === 0) {
        res = await getAllCities(pageNo, pageSize, province_id);
    }
    else {
        try {
            res = await searchCities(search, pageNo, pageSize);
        } catch (err) {
            return { records: [], total_count: 0 }
        }
    }
    if (res && res.data) {
        return { records: res.data.data, total_count: 0 }
    } else {
        return { records: [], totalCount: 0 }
    }

}


export const addCities = (data) => {
    return APIService.api().post(`/list-cities`, data)
}
export const updateCities = (data) => {
    return APIService.api().put(`/list-cities/${data.city_id}`, data)
}
export const getAllCities = (page, paginator, province_id) => {
    return APIService.api().get(`/cities?province_id=${province_id}`)
}
export const getOneCities = (city_id) => {
    return APIService.api().get(`/list-cities/${city_id}`)
}
export const searchCities = (searchKey, page, paginator) => {
    return APIService.api().get(`/list-cities/search/${searchKey}`)
}
export const deleteCities = (city_id) => {
    return APIService.api().delete(`/list-cities/${city_id}`)
}
