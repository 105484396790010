import { APIService } from "services";

export const getPengiriman = async (
  pageNo,
  pageSize,
  search,
  periode_id,
  date,
  province_id,
  city_id,
  district_id,
  subdistrict_id
) => {
  let res;
  if (search?.length === 0) {
    res = await getAllPengiriman(
      pageNo,
      pageSize,
      search,
      periode_id,
      date,
      province_id,
      city_id,
      district_id,
      subdistrict_id
    );
  } else {
    try {
      res = await searchPengiriman(
        pageNo,
        pageSize,
        search,
        periode_id,
        date,
        province_id,
        city_id,
        district_id,
        subdistrict_id
      );
    } catch (err) {
      return { records: [], total_count: 0 };
    }
  }
  if (res && res.data && res.data.data) {
    return {
      records: res.data.data.data,
      total_count: res.data.data.total,
      lastPage: res.data.data.last_page,
    };
  } else {
    return { records: [], totalCount: 0 };
  }
};

export const addPengiriman = (data) => {
  return APIService.api().post(`/bnba`, data);
};
export const updatePengiriman = (data) => {
  return APIService.api().post(`/bnba/${data.id}`, data);
};
export const getAllPengiriman = (
  page,
  paginator,
  search,
  periode_id,
  date,
  province_id,
  city_id,
  district_id,
  subdistrict_id
) => {
  return APIService.api().get(
    `/bnba?page=${page}&paginator=${paginator}&periode_id=${periode_id}&date=${date}&province_id=${province_id}&city_id=${city_id}&district_id=${district_id}&subdistrict_id=${subdistrict_id}`
  );
};
export const getOnePengiriman = (id) => {
  return APIService.api().get(`/bnba/${id}`);
};
export const searchPengiriman = (
  page,
  paginator,
  search,
  periode_id,
  date,
  province_id,
  city_id,
  district_id,
  subdistrict_id
) => {
  return APIService.api().get(
    `/bnba/search/${search}/?page=${page}&paginator=${paginator}&periode_id=${periode_id}&date=${date}&province_id=${province_id}&city_id=${city_id}&district_id=${district_id}&subdistrict_id=${subdistrict_id}`
  );
};
export const deletePengiriman = (id) => {
  return APIService.api().delete(`/bnba/${id}`);
};

export const getBastPengirimanById = (id, data) => {
  return APIService.api().post(`/bnba/get-bast/${id}`, data);
};

export const getCreatedShipments = () => {
  return APIService.api().get("shipments-created");
};
