import { useFormik } from "formik";
import React, { useState } from "react";
import { Button, Card, Form, Spinner } from "react-bootstrap";
import { setKpmsMessage } from "redux/actions";

import { useAppDispatch } from "redux/store";
import { Constant } from "template/Constant";
import * as yup from 'yup';
import { useNavigate } from "react-router";
import { addReplacePbps } from "services/sptjmService";
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey, periode_id, date, province_id, city_id, district_id, subdistrict_id) => void;
    action?: string
};

export const SptjmForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue = { kpm_id: 0, kpm_name: '', kpm_age: '', address: '', rt: '', rw: '' };
    const initialValue = action === 'edit' ? row : iValue;
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const periodsId = localStorage.getItem('periode_id')
    const provinceId = localStorage.getItem('province_id')
    const cityId = localStorage.getItem('city_id')
    const districtId = localStorage.getItem('district_id')
    const subdistrictId = localStorage.getItem('subdistrict_id')
    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            setLoading(true)
            const result = {
                kpm_name: values.kpm_name,
                address: values.address,
                rt: values.rt,
                rw: values.rw
            }
            if (action === 'edit') {
                addReplacePbps(result,row.id).then((response: any) => {
                    if (response && response.data.status === 'success') {
                        setLoading(false)
                        dispatch(setKpmsMessage("Berhasil mengubah data"));
                        getData(Constant.defaultPageNumber, Constant.defaultPageSize, '', periodsId,  '', provinceId, cityId, districtId, subdistrictId);
                        hideShowForm('');
                    }
                }).catch(error => {
                    setLoading(false)
                    formik.resetForm()
                    dispatch(setKpmsMessage("Terjadi kesalahan"));
                })
            } else if (action === 'add') {
                addReplacePbps(result,row.id).then((response: any) => {
                    if (response && response.data.status === 'success') {
                        setLoading(false)
                        dispatch(setKpmsMessage("Berhasil menambah data"));
                        getData(Constant.defaultPageNumber, Constant.defaultPageSize, '', periodsId,  '', provinceId, cityId, districtId, subdistrictId);
                        navigate('/sptjm')
                    }
                }).catch(error => {
                    setLoading(false)
                    formik.resetForm()
                    dispatch(setKpmsMessage("Terjadi kesalahan"));
                })
            }
        },
        validationSchema: yup.object({
            kpm_id: yup.string().nullable(),
            kpm_name: yup.string().nullable(),
        }),
    });

    const resetForm = () => {
        formik.resetForm()
    }
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-primary text-capitalize">{action === 'add' ? 'Tambah' : 'Ubah'} SPTJM
                </h6>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit} className="w-100 d-flex flex-wrap">
                    <Form.Group className="w-100 px-2 split-form">
                        <label className="form -control-label">ID KPM</label>
                        <Form.Control type="text" name="kpm_id" className="form-control" value={formik.values.id} disabled
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.kpm_id && !!formik.errors.kpm_id}
                            isValid={!!formik.touched.kpm_id && !formik.errors.kpm_id}
                        ></Form.Control>
                        {
                            formik.errors.kpm_id && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.kpm_id}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group className="w-100 px-2 split-form">
                        <label className="form -control-label">Nama KPM</label>
                        <Form.Control type="text" name="kpm_name" className="form-control" value={formik.values.kpm_name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.kpm_name && !!formik.errors.kpm_name}
                            isValid={!!formik.touched.kpm_name && !formik.errors.kpm_name}
                        ></Form.Control>
                        {
                            formik.errors.kpm_name && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.kpm_name}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group className="w-100 px-2 split-form">
                        <label className="form -control-label">Umur KPM</label>
                        <Form.Control type="text" name="kpm_age" className="form-control" value={formik.values.kpm_age}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.kpm_age && !!formik.errors.kpm_age}
                            isValid={!!formik.touched.kpm_age && !formik.errors.kpm_age}
                        ></Form.Control>
                        {
                            formik.errors.kpm_age && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.kpm_age}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group className="w-100 px-2 split-form">
                        <label className="form -control-label">Alamat</label>
                        <Form.Control type="text" name="address" className="form-control" value={formik.values.address}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.address && !!formik.errors.address}
                            isValid={!!formik.touched.address && !formik.errors.address}
                        ></Form.Control>
                        {
                            formik.errors.address && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.address}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group className="w-100 px-2 split-form">
                        <label className="form -control-label">RT</label>
                        <Form.Control type="text" name="rt" className="form-control" value={formik.values.rt}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.rt && !!formik.errors.rt}
                            isValid={!!formik.touched.rt && !formik.errors.rt}
                        ></Form.Control>
                        {
                            formik.errors.rt && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.rt}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group className="w-100 px-2 split-form">
                        <label className="form -control-label">RW</label>
                        <Form.Control type="text" name="rw" className="form-control" value={formik.values.rw}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.rw && !!formik.errors.rw}
                            isValid={!!formik.touched.rw && !formik.errors.rw}
                        ></Form.Control>
                        {
                            formik.errors.rw && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.rw}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>

                    <Form.Group className="px-2 w-100">
                        <Button type="submit" className="float-left mr-3" variant="primary" disabled={loading}>{loading && <Spinner variant="light" animation="border" size="sm" className="mr-1"></Spinner>}Simpan</Button>
                        <Button className="float-left" variant="secondary" onClick={() => resetForm()}>Reset</Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

