import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IBast {
    document_id?: string,
    subdistrict_name?: string,
    city_name?: string,
    district_name?: string
}

interface IBastData {
    list?: Array<IBast>;
    message?: string;
    loading?: boolean;
}

const initialState: IBastData = {
    list: [],
    message: '',
    loading: false
};

const bastSlice = createSlice({
    name: "bast",
    initialState,
    reducers: {
        setBastList: (state, _action: PayloadAction<IBastData>) => {
            state.list = _action.payload.list;
        },
        resetBastToInit: (state) => {
            state = initialState;
        },
        setBastMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
        startLoadingBast: (state) => {
            state.loading = true
        },
        stopLoadingBast: (state) => {
            state.loading = false
        }
    },
});

export const { setBastList, resetBastToInit, setBastMessage, startLoadingBast, stopLoadingBast } = bastSlice.actions

export default bastSlice.reducer;

