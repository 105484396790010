import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Card, Form, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { setQueuingsMessage, startLoadingShipments, startLoadingVehicles, stopLoadingShipments, stopLoadingVehicles } from "redux/actions";
import { resetVehiclesToInit, setVehiclesList } from "redux/actions";
import { resetShipmentsToInit, setShipmentsList } from "redux/actions";

import { getVehicles } from "services/vehiclesService";
import { getShipments } from "services/shipmentsService";

import { useAppDispatch } from "redux/store";
import { addQueuings, updateQueuings } from "services/queuingsService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
import { useNavigate } from "react-router";
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const QueuingsForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue = { id: 0, vehicle_id: 0, queue_position: 0, status: '', created_at: '', updated_at: '', shipment_id: 0 };
    const initialValue = action === 'edit' ? row : iValue;
    const vehiclesData = useSelector((state: RootState) => state.vehicles);
    const shipmentsData = useSelector((state: RootState) => state.shipments);
    const vehiclesLoading = useSelector((state: RootState) => state.vehicles.loading);
    const shipmentsLoading = useSelector((state: RootState) => state.shipments.loading);
    const [loading,setLoading] = useState(false)
    const navigate = useNavigate();
    useEffect(() => {
        if (vehiclesData && vehiclesData.list && vehiclesData.list.length === 0) {
            dispatch(resetVehiclesToInit());
            dispatch(startLoadingVehicles())
            getVehicles(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '').then((response) => {
                if (response && response.records) {
                    dispatch(setVehiclesList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
                    dispatch(stopLoadingVehicles())
                } else {
                    dispatch(setQueuingsMessage("Data kosong"));
                    dispatch(stopLoadingVehicles())
                }
            })
        }
        if (shipmentsData && shipmentsData.list && shipmentsData.list.length === 0) {
            dispatch(resetShipmentsToInit());
            dispatch(startLoadingShipments())
            getShipments(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '','','','','','','').then((response) => {
                if (response && response.records) {
                    dispatch(setShipmentsList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
                    dispatch(stopLoadingShipments())
                } else {
                    dispatch(setQueuingsMessage("Data kosong"));
                    dispatch(stopLoadingShipments())
                }
            })
        }
    },[])
    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            setLoading(true)
            if (action === 'edit') {
                const response = await updateQueuings(values);
                if (response && response.data && response.data.code === 1) {
                    setLoading(false)
                    dispatch(setQueuingsMessage("Berhasil mengubah data"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setQueuingsMessage("Some error occured!"));
                    setLoading(false)
                }
            } else if (action === 'add') {
                const response = await addQueuings(values);
                if (response && response.data && response.data.code === 1) {
                    setLoading(false)
                    dispatch(setQueuingsMessage("Berhasil menambah data"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    // hideShowForm('');
                    navigate('/queuings')
                } else {
                    dispatch(setQueuingsMessage("Terjadi kesalahan"));
                    setLoading(false)
                }
            }
        },
        validationSchema: yup.object({
            queue_position: yup.number().nullable(),
            status: yup.string().nullable(),
            // created_at: yup.date().nullable(),
            // updated_at: yup.date().nullable(),
            vehicle_id: yup.string().required('Kendaraan wajib diisi'),
            shipment_id: yup.string().required('ID Pengiriman wajib diisi'),

        }),
    });
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-primary text-capitalize">{action === 'add' ? 'Tambah' : 'Ubah'} Antrian
                    {/* <Button className="btn-icon-split float-right" onClick={() => hideShowForm(false)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-list"></i>
                        </span>
                        <span className="text">View Queuings</span>
                    </Button> */}
                </h6>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                    <Form.Group>
                        <label className="form -control-label">Posisi Antrian</label>
                        <Form.Control type="text" name="queue_position" className="form-control" value={formik.values.queue_position}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.queue_position && !!formik.errors.queue_position}
                            isValid={!!formik.touched.queue_position && !formik.errors.queue_position}
                        ></Form.Control>
                        {
                            formik.errors.queue_position && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.queue_position}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group>
                        <label className="form -control-label">Status</label>
                        <Form.Control type="text" name="status" className="form-control" value={formik.values.status}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.status && !!formik.errors.status}
                            isValid={!!formik.touched.status && !formik.errors.status}
                        ></Form.Control>
                        {
                            formik.errors.status && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.status}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    {/* <Form.Group>
                        <label className="form -control-label">created_at</label>
                        <Form.Control type="text" name="created_at" className="form-control" value={formik.values.created_at}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.created_at && !!formik.errors.created_at}
                            isValid={!!formik.touched.created_at && !formik.errors.created_at}
                        ></Form.Control>
                        {
                            formik.errors.created_at && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.created_at}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group>
                        <label className="form -control-label">updated_at</label>
                        <Form.Control type="text" name="updated_at" className="form-control" value={formik.values.updated_at}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.updated_at && !!formik.errors.updated_at}
                            isValid={!!formik.touched.updated_at && !formik.errors.updated_at}
                        ></Form.Control>
                        {
                            formik.errors.updated_at && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.updated_at}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group> */}
                    <Form.Group>
                        <label className="form -control-label">Kendaraan</label>
                        <Form.Control as="select" name="vehicle_id" className="form-control" value={formik.values.vehicle_id}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.vehicle_id && !!formik.errors.vehicle_id}
                            isValid={!!formik.touched.vehicle_id && !formik.errors.vehicle_id}
                            disabled={vehiclesLoading}
                        >
                            <option value={0}>{vehiclesLoading ? 'Loading...' : 'Pilih Kendaraan '}</option>
                            {
                                vehiclesData.list.map((item, i) => {
                                    return <option value={item.id} key={`vehicles-${i}`}>{item.make}</option>
                                })}
                        </Form.Control>
                        {
                            formik.errors.vehicle_id && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.vehicle_id}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group>
                        <label className="form -control-label">ID Pengiriman</label>
                        <Form.Control as="select" name="shipment_id" className="form-control" value={formik.values.shipment_id}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.shipment_id && !!formik.errors.shipment_id}
                            isValid={!!formik.touched.shipment_id && !formik.errors.shipment_id}
                            disabled={shipmentsLoading}
                        >
                            <option value={0}>{shipmentsLoading ? 'Loading...': 'Pilih Pengiriman'}</option>
                            {
                                shipmentsData.list.map((item, i) => {
                                    return <option value={item.id} key={`shipments-${i}`}>{item.document_id}</option>
                                })}
                        </Form.Control>
                        {
                            formik.errors.shipment_id && (
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.shipment_id}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>

                    <Form.Group>
                        <Button type="submit" className="float-right" variant="primary"disabled={loading}>{loading && <Spinner variant="light" animation="border" size="sm" className="mr-1"></Spinner>}Simpan</Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

