import React, { useState } from "react";
import Layout from "template";
import { InvitationsPrint } from "./print";
import { Alert } from "react-bootstrap";

type Props = {
  section?: any
}

export const Invitations: React.FC<Props> = ({ section }) => {
  const [alertMessage, setAlertMessage] = useState('')
  const setMessage = (message) =>{
    setAlertMessage(message)
  }
  return (
    <Layout>
      <div className="container-fluid">
        {/* <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Undangan</h1>
        </div> */}
        {/* {section === '' && <div className="d-card d-flex justify-content-between mb-4 flex-column flex-md-row flex-wrap" style={{ gap: '12px' }}>
          <Card style={{ borderRadius: 20, border: 'none', padding: 10 }} className="card-counter">
            <Card.Body>
              <Card.Title>Total</Card.Title>
              <Card.Text style={{ fontSize: 32, color: '#040D12', fontWeight: "bold" }}>
                3,999,999
              </Card.Text>
            </Card.Body>
          </Card>
          <Card style={{ borderRadius: 20, border: 'none', padding: 10 }} className="card-counter">
            <Card.Body>
              <Card.Title style={{ color: 'blue' }}>Dikirim</Card.Title>
              <Card.Text style={{ fontSize: 32, color: '#040D12', fontWeight: "bold" }}>
                23.543
              </Card.Text>
            </Card.Body>
          </Card>
          <Card style={{ borderRadius: 20, border: 'none', padding: 10 }} className="card-counter">
            <Card.Body>
              <Card.Title className="text-primary">Dikirim</Card.Title>
              <Card.Text style={{ fontSize: 32, color: '#040D12', fontWeight: "bold" }}>
                23.543
              </Card.Text>
            </Card.Body>
          </Card>
          <Card style={{ borderRadius: 20, border: 'none', padding: 10 }} className="card-counter">
            <Card.Body>
              <Card.Title className="text-success">Diterima</Card.Title>
              <Card.Text style={{ fontSize: 32, color: '#040D12', fontWeight: "bold" }}>
                23.543
              </Card.Text>
            </Card.Body>
          </Card>
        </div>} */}

        <div className="d-flex flex-column min-vh-100">
          {alertMessage && <Alert variant="warning">{alertMessage}</Alert> }
          <InvitationsPrint setMessage={setMessage}/>
        </div>

      </div>
    </Layout >
  );
};

